import React from 'react'
import styled from 'styled-components'
import { MediumText, PRIMARY, media, BigText, BLUE } from '../Assets/Styles'
import mountains from "../Assets/Images/mountains.jpg"
import TeamMemberCard from '../Components/GeneralUse/TeamMemberCard'
import PDF from '../Components/GeneralUse/PDF'
import translate from '../i18n/messages/translate'
import niklausLundsgaardHansen from "../Assets/Images/thmbs/Niklaus_Lundsgaard-Hansen.png"
import estherHulliger from "../Assets/Images/thmbs/Esther_Hulliger.jpg"
import moriaZuerrer from "../Assets/Images/thmbs/Moria_Zuerrer.jpg"
import rainerLuescher from "../Assets/Images/thmbs/Rainer_Luescher.png"
import blank from "../Assets/Images/thmbs/blank.jpg"

const AboutUs = () => {
    return (
        <div>
            <Herkunft></Herkunft>
            <Ziele></Ziele>
            <Vorstand></Vorstand>
            <Jahresbericht></Jahresbericht>
        </div>
    )
}

export default AboutUs

const Ziele = () => {

    return (
        <Card>
            <BigText>{translate("goals of nhg")}</BigText>
            <p>{translate("goals of nhg subheading")}</p><br></br>
            <ul>
                <li>{translate("first goal")}</li>
                <li>{translate("second goal")}</li>
                <li>{translate("third goal")}</li>
                <li>{translate("fourth goal")}</li>

            </ul>
            <br></br>
            <p>Statuten NHG - Deutsch (2021) (<a href="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FNHG_Statuten_unterschrieben_2021.pdf?alt=media&token=efef8721-3e35-41ea-8b8b-b30b86dd2da3" target="_blank" rel="noopener noreferrer">PDF</a>).<br></br></p>
            <br></br>
            <p>Statuts NSH - Français (2021) (<a href="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24perm%2FStatuts%20NSH%2020210529f.pdf?alt=media&token=3b42398b-5aa3-4c63-9a4d-a087e6483361" target="_blank" rel="noopener noreferrer">PDF</a>).<br></br></p>
        </Card>
    )
}

//Herkunft
const ElementContainer = styled.div`
    width: 100%;
    height: fit-content;
    padding: 5%;
    background: ${props => props.color};
    display: flex;
    flex-direction: column;
    >h2{
        color: white;
        text-align: left;
        margin-bottom: 20px;
    }
    >p,a{
        color: white;
        text-align: left;
        line-height: 1.3rem;
    }
    >a{
        text-align: left;

    }
    `
const Herkunft = () => {

    return (
        <ElementContainer color={PRIMARY}>
            <BigText>{translate("herkunft")}</BigText>
            <p>{translate("herkunft text")}</p>
            <a style={{ color: "white", textDecoration: "underline", textAlign: "left", marginTop: "20px" }} href="https://hls-dhs-dss.ch/de/articles/016429/2007-12-05/">{translate("link herkunft")}</a>
        </ElementContainer>
    )
}

//Vorstand
const EmployeeContainer = styled.div`
    width:100%;
    padding: 5%;
    >div{
    display: grid;
    grid-gap: 5vh;
    margin-bottom: 5vh;

    }
    >h2{
        text-align: left;
        margin-bottom: 5vh;
    }
    >h3{
        text-align: left;
        margin-top: 10vh;
        margin-bottom: 5vh;
    }

    @media (orientation: landscape) and (min-width: 768px){
        >div{
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    grid-gap: 5vh;
    }
    }

    @media (orientation: portrait) and (min-height: 900px){
        >div{
    display: grid;
    grid-template-columns: 0.5fr 0.5fr ;
    grid-gap: 5vh;
    }
    }
    `
const Vorstand = () => {

    return (
        <EmployeeContainer>
            <BigText>{translate("vorstand")}</BigText>
            <div>
                <TeamMemberCard
                    memberImage={rainerLuescher}
                    name="Rainer"
                    surname="Lüscher"
                    position="Präsident"
                    location="Buchserstrasse 61, 5000 Aarau"
                    phone="+41 79 648 34 84"
                    email="praesidium@nhg.ch"></TeamMemberCard>
                <TeamMemberCard memberImage={estherHulliger}
                    name="Esther"
                    surname="Hulliger"
                    position="Ressort Finanzen"
                    location="Mühlestrasse 24, 3123 Belp"
                    phone="+41 31 819 26 65"
                    email="info@nhg.ch"></TeamMemberCard>
                <TeamMemberCard
                    phone="+41 21 692 20 93"
                    name="Gilles"
                    surname="Grin"
                    location="FJME, Ferme de Dorigny
                1015 Lausanne"
                    email="gilles.grin@nhg.ch"
                    position="Ressort Schweiz in der Welt"></TeamMemberCard>
                {/*                 <TeamMemberCard
                    memberImage={carlaKaufmann}
                    name="Carla"
                    surname="Kaufmann"
                    position="Ressort Kommunikation"></TeamMemberCard> */}
                <TeamMemberCard
                    memberImage={moriaZuerrer}
                    name="Moria"
                    surname="Zürrer"
                    position="Ressort Demokratiebildung"
                    email="moria.zuerrer@nhg.ch"
                    phone="+41 79 477 31 44"
                    link="http://linkedin.com/in/moria-zürrer"></TeamMemberCard>

            </div>
            {/* <MediumText>{translate("sekretariat")}</MediumText>
            <div><TeamMemberCard 
            location="Netzwerk Müllerhaus
            Bleicherrain 7
            5600 Lenzburg 1"
            phone="P 062 888 01 15"
            name="Ursula Furrer" 
            position="Sekretariat, Buchhaltung"></TeamMemberCard></div> */}
            <MediumText>{translate("president of groups")}</MediumText>
            <div>
                <TeamMemberCard name="Sebastian" surname="Aeschbach" position="Président du Groupe Genève" groupLink="/region" email="presidence@nsh-geneve.ch"></TeamMemberCard>
                <TeamMemberCard name="Régis" surname="Ducrey" position="Président du Groupe Vaud" groupLink="/region"></TeamMemberCard>
                <TeamMemberCard name="Alec" surname="von Graffenried" position="Präsident Gruppe Bern" groupLink="/region"></TeamMemberCard>
                {/*                 <TeamMemberCard name="Tabea R. Weber" position="Präsidentin Gruppe Region Basel"></TeamMemberCard> */}
                <TeamMemberCard name="Norbert" surname="Stichert" position="Präsident Gruppe Aargau" groupLink="/region"></TeamMemberCard>
                <TeamMemberCard name="Hans-Jakob" surname="Mosimann" position="Präsident Gruppe Winterthur" groupLink="/region"></TeamMemberCard>
                <TeamMemberCard name="Daniel" surname="Landolt" position="Präsident Gruppe Schaffhausen" groupLink="/region"></TeamMemberCard>
                <TeamMemberCard name="Christian" surname="Lüthi" position="Präsident Gruppe Zürich" groupLink="/region"></TeamMemberCard>
            </div>

        </EmployeeContainer>

    )
}

//Reusable Card Component
const CardContainer = styled.div`
height: fit-content;
width: 100%;
margin-bottom: 40px;
height: fit-content;

>img{
    width: 100%;
    height:45%;
    object-fit: cover;
    margin: 0;
}

@media (orientation: landscape) and (min-width: 768px){
    width: 100vw;
    height: fit-content;
    margin: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    margin-bottom: 0px;
    >img{
    width: 50%;
    height: auto;


    }
}

@media (orientation: portrait) and (min-width: 768px){
    width: 100vw;
    height: fit-content;
    margin: auto;
    margin-bottom: 72px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    >img{
    width: 100%;
    height: 100%;
    }
    >div{
    width: 100%;
    height: fit-content;
    }
}

@media (orientation: landscape) and (max-height: 500px){
display: flex;
margin-bottom: 0px;
height: fit-content;
    >img{
    display: none;
    height: 100%;
    }
    >div{
    width: 100%;
    margin-top: 0px;
    height: 100%;
    background-color:white;
    position: relative;
    flex-direction: column;
    padding: 3%;
    }
}
`
const CardContentContainer = styled.div`
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color:white;
    position: relative;
    padding: 30px 0;
    position: relative;
    padding: 10% 5%;
    background-color: white;


    >div{
        margin-bottom: 30px;
    }
    >div>div{
        margin-bottom: 30px;
    }
    >p{
        text-align: ${props => props.reverse ? "left" : "right"};
        margin-top: 20px;
        color: ${PRIMARY};
        font-size: 1rem;
        
        text-overflow: ellipsis;
        max-height: 6rem; /* (Number of lines you want visible) * (line-height) */
        line-height: 1.5rem;
    }
    >ul{
        margin-left: 30px;
    }
    >ul>li{
        text-align: ${props => props.reverse ? "left" : "right"};
        margin-top: 20px;
        color: ${PRIMARY};
        font-size: 1rem;
        
        text-overflow: ellipsis;
        max-height: 6rem; /* (Number of lines you want visible) * (line-height) */
        line-height: 1.5rem;
        
    }
    >h2{
        text-align: ${props => props.reverse ? "left" : "right"};
        color: ${PRIMARY};
    }
  

//for desktop layout
@media (orientation: landscape) and (min-width: 768px){
    padding: 4%;
    margin-top: 0px;
    height: 100%;
    background-color: white;
    position: relative;
    flex-direction: column;
    width: 50%;


    >p{
        margin: 0;
        text-align: ${props => props.reverse ? "left" : "right"} !important;
        margin-top: 0px;
        color: ${PRIMARY};
        font-size: 1.2rem;
        max-height: calc(100% - 80px);
    }
    >h2{
        text-align: ${props => props.reverse ? "left" : "right"};
        color: ${PRIMARY};
        margin-bottom:40px


    }
    
    >.name-email{
        height: fit-content;
        padding: 0 5%;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
    }
    
}

@media (orientation: portrait) and (min-width: 768px){
    margin-top: 0px;
    height: fit-content;
    background-color: white;
    position: relative;
    flex-direction: column;
    padding: 5%;
    min-height: 0;

    
    >.name-email{
        height: fit-content;
        padding: 0 5%;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
    }
    
}
`
function Card(props) {

    return (
        <CardContainer reverse={true}>
            <img src={mountains}></img>

            <CardContentContainer reverse={true}>
                {props.children}
            </CardContentContainer>

        </CardContainer>


    )
}



//Jahresbericht
const GridJahresbericht = styled.div`
    display: grid;
    margin: auto;
    @media (orientation: landscape) and (min-width: 768px){
        width: 90%;
        grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    }
    @media (orientation: portrait) and (min-width: 768px){
        margin: auto;
        width: 90%;
        grid-template-columns: 0.33fr 0.33fr 0.33fr;
    }
    @media (orientation: landscape) and (max-height: 500px){
        width: 90%;
        grid-template-columns: 0.5fr 0.5fr ;
    }
`

const JahresberichtContainer = styled.div`
background-color: ${BLUE};
padding: 5% 0;
>h2{
    color: white;
    padding-left: 5%;
    margin-bottom: 5vh;
    text-align: left;
}
`
const Jahresbericht = () => {

    return (
        <JahresberichtContainer>
            <BigText>{translate("annual raport")}</BigText>
            <GridJahresbericht>
                <PDF name="Jahresbericht 2023" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FNHG%20Schweiz%20-%20Jahresbericht%202023%20des%20Pra%CC%88sidenten.pdf?alt=media&token=14e690b3-2776-4ece-9b6c-c76f50c0f214"></PDF>
                <PDF name="Jahresbericht 2022" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FJahresbericht%202022%20NHG%20Schweiz%20def%20020423.pdf?alt=media&token=7b0266e1-c9f7-4278-a2aa-f7ac2dd08504"></PDF>
                <PDF name="Rapport annuel 2021" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FRapport%20annuel%202021.pdf?alt=media&token=5cd65f9b-1886-49e1-8e0c-d7c26d3fb352"></PDF>
                <PDF name="Jahresbericht 2021" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FJahresbericht%202021%20NHG%20Schweiz%20V0.4%20def.pdf?alt=media&token=76367648-73e7-42d3-b229-d513f32a230a"></PDF>
                <PDF name="Jahresbericht 2020" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2020.pdf?alt=media&token=d130f461-0405-4fad-97cc-2c65585a72b5"></PDF>
                <PDF name="Jahresbericht 2019" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2FNHG%20Jahresbericht%202019.pdf?alt=media&token=dffc79fc-caf4-4caa-9a65-4f60033cec21"></PDF>
                <PDF name="Jahresbericht 2018" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2018.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF>
                <PDF name="Jahresbericht 2017" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2017.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF>
                {/* <PDF name="Jahresbericht 2016" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2016.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF>
                <PDF name="Jahresbericht 2015" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2015.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF>
                <PDF name="Jahresbericht 2014" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2014.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF>
                <PDF name="Jahresbericht 2013" link="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24manif%2Fch-jahresbericht-2013.pdf?alt=media&token=458ad16b-7112-4d73-9521-080cc894fd5d"></PDF> */}
            </GridJahresbericht>
        </JahresberichtContainer>
    )
}









