import { LOCALES } from "../locales"

export default {
        [LOCALES.GERMAN]: {

                //general - not done 
                "share": "Teilen",
                "coming soon": "Demnächst verfügbar",
                "no email": "Keine Email",
                "language": "Sprache",
                "preview": "Vorschau",
                "already logged in": "Bereits eingeloggt",
                "log in failed": "Login fehlgeschlagen",
                "try again": "Versuchen Sie es erneut",
                "URL kopiert": "URL kopiert",

                //orgaisation name
                "logo": "Neue Helvetische Gesellschaft",
                "switzerland": "Schweiz",
                "nhg switzerland": "NHG Schweiz",

                //buttons
                "read more": "Mehr lesen",
                "click here": "Hier klicken",
                "file selected": "Datei ausgewählt",
                "no file selected": "Keine Datei ausgewählt",

                //menu
                "regions": "Regionen",
                "events": "Veranstaltungen",
                "democracy": "Demokratie",
                "archive": "Aktuell und Archiv",
                "Who are we": "Wer wir sind",
                "contact": "Kontakt",
                "upload": "Upload",
                "post": "Beitrag",
                "my uploads": "Meine Uploads",
                "upload organisation's profile": "Organisations-Profil bearbeiten",
                "log out": "Abmelden",
                "successfully logged out": "Erfolgreich abgemeldet.",

                //footer - not done 
                "url copied": "URL kopiert",
                /* "phone number": "Rufnummer", */
                "phone number": "Telefon",
                "impressum": "Impressum",
                "datenschutz": "Datenschutz",
                "AGB": "AGB",
                "terms and services": "Infos",

                //log in
                "log in": "Einloggen",
                "password": "Passwort",

                //home
                "partner organisations": "Partner Organisationen",
                "support NHG": "NHG unterstützen",
                "support NHG text": "Sie möchten die NHG unterstützen, zum Beispiel mit einem finanziellen Beitrag oder mit der Mitwirkung in einem möglichen Projekt? Oder möchten Sie Mitglied werden? Bitte kontaktieren Sie unseren Verantwortlichen für Mitglieder und Finanzen, Franz Bieri, unter ",
                "all partners": "Alle Partner",
                //slide component
                "events in your region": "Veranstaltungen in Ihrer Region",
                //card component
                "losung": "Wir sind die älteste staatsbürgerliche Organisation der Schweiz. Wir setzen uns ein für Demokratie und Zusammenhalt.",

                //logo container
                "logo container header": "Alles, was es zur Demokratie zu wissen gibt",
                "logo container subHeader": "Seit 1914 engagieren wir uns für die Demokratie",

                //elements headings - not done 
                "price": "Preis",
                "our": "Unser",

                //subheadings - not done 
                "democracy podcast subheading": "Wie können wir die Demokratie heute verbessern? Ein Podcast über praktische Veränderungen, mit Gästen aus öffentlichen Einrichtungen und der Zivilgesellschaft.",
                "democracy wiki subheading": "Alle Begriffe rund um die Demokratie. Was Sie kennen, wissen und verstehen sollten.",
                "our archive subheading": "Finden Sie hier alle Unterlagen, die von der NHG in ihrer Geschichte erarbeitet wurden.",
                "democracy price subheading": "Die NHG ist Trägerin des Demokratie-Preises Schweiz und vergibt ihn seit 2013. Teilnahmebedingungen und Eingabefristen finden sie hier: demokratiepreis.ch",
                "democracy blog subheading": "Der Demokratie Blog ist der News-Feed der NHG. Lesen Sie sich in die aktuellsten Themen ein.",
                "campus democracy": "Der Demokratie Campus ist die nationale Plattform für politische Bildung und Partizipation. Informieren Sie sich hier: campusdemokratie.ch",

                //upload - not done 
                "add team member": "Team Mitglied hinzufügen",
                "team": "Team",
                "address": "Adresse",
                "header": "Titel",
                "author's Name": "Name Verfasser*in",
                "organisation's Name": "Name Organisation",
                "type your text": "Text eingeben",
                "select file": "File auswählen",
                "select image": "Foto auswählen",
                "choose Date": "Datum wählen",
                "start Time": "Startzeit",
                "end Time": "Endzeit",
                "location": "Ort",
                "team members": "Team Mitglieder",
                "surname": "Nachname",
                "position": "Position",
                "name": "Vorname",
                "upload event": "Event erstellen",
                "please fill out the input": "Bitte füllen Sie das Formular aus",
//organisation upload - not done
/* "organisation upload" : "Organisations-Profil bearbeiten",
 */"If you click on this button the team member will be deleted permanently": "Wenn Sie auf diese Schaltfläche klicken, wird das Teammitglied endgültig gelöscht.",
                "description": "Beschreibung",
                "add archive item": "Archivartikel hinzufügen",
                "add team member": "Teammitglied hinzufügen",
                "add": "Hinzufügen",
                "You do not have any Team Members added yet": "Sie haben noch keine Teammitglieder hinzugefügt.",
                "You do not have any archive articles  added yet": "Sie haben noch keine Archivartikel hinzugefügt",
                "edit": "Bearbeiten",
                "delete": "Löschen",

                //news upload
                "If you click on this you will delete the news permannetly so be careful.": "Wenn Sie darauf klicken, werden die Nachrichten endgültig gelöscht, seien Sie also vorsichtig.",
                //blog upload
                "If you click this button you will be redirected to a page where you will be able to edit your article and repost it.": "Wenn Sie auf diese Schaltfläche klicken, werden Sie zu einer Seite weitergeleitet, auf der Sie Ihren Artikel bearbeiten und neu einstellen können.",
                "blog upload": "Blog Beitrag hochladen",
                "edit blog": "Blog bearbeiten",

                //democracy wiki dictionary
                "wiki-democracy": "Der Glaube an die Freiheit und Gleichheit der Menschen oder ein auf diesem Glauben beruhendes Regierungssystem, in dem die Macht entweder von gewählten Vertretern oder direkt vom Volk selbst ausgeübt wird.",
                "hello": "Hallo",
                "edit": "Bearbeiten",

                //cantons - not done 
                "all regions": "Alle Regionen",
                "president": "Präsident*in",
                "presidentFemale": "Präsidentin",
                "presidentMale": "Präsident",
                "no website": "Keine Website",
                "no location": "Kein Standort",
                "visit website": "Website",
                "Position": "Position",
                "Reihenfolge": "Reihenfolge",

                //archiv - not done 
                "publications NHG": "Publikationen NHG",
                "nhg in the media": "NHG in den Medien",
                "grundsatzthemen": "Grundsatzthemen",
                "fotogalerie": "Fotogalerie",
                "100 years nhg": "100 Jahre NHG",
                "250 years nhg": "Jubiläum 250 Jahre Helvetische Gesellschaft",
                "publications from 2007": "Publikationen vor 2007",
                "delegiertenversammlung": "Delegiertenversammlung",
                "go to pdf": "Verlagsprospekt",

                //my account - not done
                "save changes": "Änderungen speichern",


                //kontakt - not done
                "subject": "Betreff",
                "send message": "Nachricht senden",
                "content": "Inhalt",
                "Kontakt": "Kontakt",

                //about us
                "goals of nhg": "Ziele der NHG",
                "goals of nhg subheading": "Die NHG will die demokratischen Institutionen und den Zusammenhalt im Lande stärken, indem sie wachsam aktuelle Fragen aufgreift und zu deren Beantwortung beiträgt.",
                "first goal": "Sie tritt für die Funktionsfähigkeit der demokratischen Institutionen ein.",
                "second goal": "Sie fördert die Verständigung zwischen verschiedenen Bevölkerungsgruppen.",
                "third goal": "Sie fördert die kulturelle Identität der Schweiz.",
                "fourth goal": "Sie setzt sich ein für Solidarität und Offenheit zur Welt und unterstützt den Bund in der Erfüllung seiner von der Verfassung auferlegten Pflichten gegenüber dem Ausland.",
                "herkunft": "Herkunft",
                "herkunft text": `Die Neue Helvetische Gesellschaft (NHG) wurde 1914, kurz vor Ausbruch des Ersten Weltkrieges, zu einem Zeitpunkt gegründet, in dem die Meinungsverschiedenheiten zwischen Deutsch- und Westschweiz den inneren Frieden des Landes gefährdeten. 
Sie sah sich als Nachfolgerin der Helvetischen Gesellschaft, welche von 1761 bis 1858 zur Stärkung der Eidgenossenschaft und zur Bildung des Bundesstaates von 1848 beigetragen hatte.
Sie fusionierte mit Rencontres Suisses (RS) zur gemeinsamen Gesellschaft «Neue Helvetische Gesellschaft – Treffpunkt Schweiz» am 1. Januar 2007. RS selbst wurde 1945 gegründet, mit dem Ziel, den Zusammenhalt des Landes in einer für Europa unruhigen Zeit zu stärken. Ein Hauptanliegen von RS war die Verständigung zwischen den Sozialpartnern.
Die ordentliche Delegiertenversammlung 2018 beschloss die Verkürzung des Namens auf Neue Helvetische Gesellschaft.`,
                "vorstand": "Vorstand",
                "president of groups": "Präsidentinnen und Präsidenten der Gruppen",
                "annual raport": "Jahresberichte",
                "sekretariat": "Sekretariat, Buchhaltung",
                "link herkunft": "'Helvetischen Gesellschaft' im Historischen Lexikon der Schweiz"
        }
}