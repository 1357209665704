import React from 'react'
import styled from 'styled-components'
import { bigShadow } from '../../Assets/Styles'
import PDF from './PDF'


const PdfContainer = styled.div`
position: relative;
width: 100%;
overflow: hidden;
padding-top: 56.25%; /* 16:9 Aspect Ratio */
`

const PdfIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    `

const Container = styled.div`
cursor: pointer;
height: fit-content;
width: 90%;
box-shadow: ${bigShadow};
border: 5px solid white;
margin: auto;
margin-bottom: 20px;
margin-right: 20px;

>img{
    width: 100%;
    height: auto;
}

@media (orientation: landscape) and (min-width: 1100px), (orientation: landscape) and (min-width: 768px){
height: 20vh;
width: fit-content;
box-shadow: ${bigShadow};
border: 5px solid white;
margin:0;
margin-bottom: 20px;
margin-right: 20px;
>img{
    width: auto;
    height: 100%;
}
}

@media (orientation: portrait) and (min-height: 1000px){
height: 10vh;
width: fit-content;
box-shadow: ${bigShadow};
border: 5px solid white;
margin:0;
margin-bottom: 20px;
margin-right: 20px;
>img{
    width: auto;
    height: 100%;
}
}
`


const Image = ({ image, longDesc }) => {
    if (image.includes(".pdf")) {
        return (
            <PDF link={image}></PDF>
           /*  <PdfContainer>
                {                <embed src={image} type="application/pdf" className={longDesc} ></embed>
                    <PdfIframe id="iframepdf" src={image} className="responsive-iframe"  ></PdfIframe>

                }            </PdfContainer> */
                )
    }
    return (
        <Container>
            {/* <embed src={image} type="application/pdf" className={longDesc} ></embed> */}
            <img src={image} className={longDesc}></img>
        </Container>
    )
}

export default Image
