import React from 'react'
import styled from "styled-components"
import { BLUE, Button, PRIMARY } from '../Assets/Styles';
import mountains from "../Assets/Images/mountains.jpg"
import InputMUI from '../Components/GeneralUse/Input/InputMUI';
import { useState } from 'react';
import { postDatabase } from '../firebase/DatabaseFunctions';
import Banner from "../Components/GeneralUse/Banner"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLessThanEqual, faTimes } from '@fortawesome/free-solid-svg-icons';
import { trueFunc } from 'boolbase';
import translate from '../i18n/messages/translate';

const CardContainer = styled.div`
height: 75vh;
width: 100%;
margin-bottom: 40px;
height: fit-content;

>img{
    width: 100%;
    height: 45%;
    object-fit: cover;
    margin: 0;
}

@media (orientation: landscape) and (min-width: 768px){
    width: 100vw;
    height: 47.5vw;
    margin: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: ${props=>props.reverse?"row-reverse":"row"};
    margin-bottom: 0px;
    >img{
    width: 50%;
    height: 100%;
    }
}

@media (orientation: portrait) and (min-width: 768px){
    width: 100vw;
    height: fit-content;
    margin: auto;
    margin-bottom: 72px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    >img{
    width: 100%;
    height: 100%;
    }
    >div{
    width: 100%;
    height: 50vh;
    }
}

@media (orientation: landscape) and (max-height: 500px){
display: flex;
margin-bottom: 0px;
height: 90vh;
    >img{
    width: 50%;
    height: 100%;
    }
    >div{
        margin-top: 0px;
    height: 100%;
    background-color:white;
    position: relative;
    flex-direction: column;
    padding: 5%;
    }
}
`

const ContentContainer = styled.div`
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color:white;
    position: relative;
    padding: 30px 0;
    position: relative;
    >div{
        margin-bottom: 30px;
    }
    >div>div{
        margin-bottom: 30px;
    }
    
    >p{
        text-align: ${props=>props.reverse? "left":"right"};
        margin-top: 20px;
        color: ${PRIMARY};
        font-size: 1rem;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 6rem; /* (Number of lines you want visible) * (line-height) */
        line-height: 1.5rem;
    }
    >h3{
        text-align: ${props=>props.reverse? "left":"right"};
        color: ${PRIMARY};
    }
  

//for desktop layout
@media (orientation: landscape) and (min-width: 768px){
    margin-top: 0px;
    height: 100%;
    background-color: white;
    position: relative;
    flex-direction: column;
    padding: 4rem 0;
    width: 50%;

    >p{
        margin: 0;
        text-align: ${props=>props.reverse? "left":"right"} !important;
        margin-top: 0px;
        color: ${PRIMARY};
        font-size: 1.2rem;
        max-height: calc(100% - 80px);
    }
    >h3{
        margin-bottom: 10px;
        text-align: ${props=>props.reverse? "left":"right"};
        color: ${PRIMARY};

    }
    
    >.name-email{
        height: fit-content;
        padding: 0 5%;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
    }
    
}

@media (orientation: portrait) and (min-width: 768px){
    margin-top: 0px;
    height: 50vh;
    background-color: white;
    position: relative;
    flex-direction: column;
    padding: 5%;
    min-height: 0;

    
    >.name-email{
        height: fit-content;
        padding: 0 5%;
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
    }
    
}
`
const Container = styled.div`
`


function Card () {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [text, setText] = useState("")
    const [loading, setLoading] = useState(false)
    const [successful, setSuccesful] = useState(false)
    const [error, setError] = useState(false)
const article = {name, email, subject, text}


const clearAll =() => {
    setName("")
    setEmail("")
    setSubject("")
    setText("")
}
const handleSubmit = () => {
    setLoading(true)
    postDatabase("emails", article)
    .then(() => {
        setSuccesful(true)
        setLoading(false)
        clearAll()
 })
 .catch((error) => {
    setLoading(true)
     setError(true)
 })
 ;
}

    return (
        <>
        <Banner trigger={successful} setTrigger={setSuccesful}>
            <FontAwesomeIcon color={BLUE} icon={faCheck}></FontAwesomeIcon>
        </Banner>
        <Banner trigger={error} setTrigger={setError}>
            <FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon>
        </Banner>
        

        <Container>
        <CardContainer >  
            <img src={mountains}></img>
            <ContentContainer >
                <div className="name-email">
                    <InputMUI value={name} setValue={setName} label={translate("name")}></InputMUI>
                    <InputMUI value={email} setValue={setEmail} label="Email"></InputMUI>
                </div>
                <div className="subject-text">
                    <InputMUI value={subject} setValue={setSubject} label={translate("subject")}></InputMUI>
                    <InputMUI value={text} setValue={setText}
                        label={translate("content")}
                        multiline
                        rows={8}
                        rowsMax={5}
                    />
                </div>
                <Button onClick={()=>{handleSubmit()}} bigger={true}>{translate("send message")}</Button>
            </ContentContainer>
        </CardContainer>

        {/* <Map></Map> */}
        </Container>
        </>


    )
}

export default Card 
