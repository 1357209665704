import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import train from "../../Assets/Images/train.jpg"
import flag from "../../Assets/Images/flag.jpg"
import telecabin from "../../Assets/Images/telecabin.jpg"
import city from "../../Assets/Images/city.jpg"
import { Button, NewsInfoButton, BigText, BLUE, PRIMARY } from "../../Assets/Styles"
import CircleIcon from '../GeneralUse/CircleIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import translate from '../../i18n/messages/translate'
import { isTouchDevice } from '../../Assets/UtilityFunctions'
import { CSSTransition } from 'react-transition-group';
import { useBlogs } from '../../Contexts/BlogContext'
import NewSlideBannerPop from './NewSlideBannerPop.js'
import { BlogsProvider } from '../../Contexts/BlogContext';
import { EventsProvider } from '../../Contexts/EventsCotext'

const HeroSection = styled.div`
height: 90vh;
width: 100%;
display: flex;
overflow: hidden;
position: relative;

@media (orientation: landscape){
    height: 100vh;
}

#slide${props => props.slide}{
    flex-shrink: 0;
    >#header${props => props.slide}{
        transform: translateX(0%);
    }
}
`

const Slide = styled.div`
height: 100%;
width: 100%;
transition: all 0.4s;
resize: both;
background: ${props => "url(" + props.image + ")"} no-repeat center center;
background-size: cover;
background: ${props => props.touch ? "" : "fixed"};
>div{
    position : absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 2;
    transition: all 0.4s;
    transform: translateX(-100%);
}
>div>h2{
        color: #fff;
        width: 100%;
        text-align: center;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
             @media (orientation: landscape){
                 padding: 0 10%;
             }
    }


@media (orientation: landscape) and (min-width: 1100px){
    resize: both;
    background: ${props => "url" + "(" + props.image + ")"} no-repeat center center fixed;
    background-size: cover;
}
`

const Points = styled.div`
    width: 100%;
    z-index: 3;
    margin: auto;
    height: 5vh;
    background-color: transparent;
    position: relative;
    bottom: 10vh;
    display: flex;
    align-items:center;
    justify-content: center;

    .alert-enter {
        opacity: 0;
        transform: scale(0.9);
      }
      .alert-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 2000ms, transform 2000ms;
      }
      .alert-exit {
        opacity: 1;
        transform: translateX(0);
      }
      .alert-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 1000ms, transform 1000ms;
      }

    >#point${props => props.slide}{
    background-color: ${PRIMARY};
    transform: scale(1.5);
}
    >.slide-point{
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        background-color: white;
        height: 5px;
        width: 20px;
        margin: 0 20px;
        border-radius: 20px;
    }
    >.chevron{
        display: none;
    }

    >a{position: absolute;
        bottom: 200%;
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        >button{
        position: absolute;
        bottom: 200%;
        
        @media (orientation: landscape){
            width: 20%;
        }
    }
}

    >.news-button{
        position: absolute;
        bottom: 500%;
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 400px){
            position: absolute;
            bottom: 1100%;
            width: 80vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        >button{
        position: absolute;
        border-color: white;
        @media (orientation: landscape){
            width: 60%;
        }
    }
}

    @media (orientation: landscape) and (min-width: 768px){
        >.chevron{
            position: absolute;
            display: inline-flex;
            bottom: 40.5vh;
        }
        >.chevron-left{
            right: 90%;
        }
        >.chevron-right{
            right: calc(10% - 58px);
        }

    >a{        
        position: absolute;
        bottom: 200%;
        width: 30vw;

        >button{
        padding-top: 12px;
        padding-bottom: 12px;
        width: 30vw !important;
        @media (orientation: landscape){
            width: 20%;
        }
    }}
    }
`



function SlideBanner() {
    const [slide, setSlide] = useState(0)
    const [buttonLink, setButtonLink] = useState("")
    const [touchStartX, setTouchStartX] = useState()
    const [touchEndX, setTouchEndX] = useState()

    /*     const transitionStyle = {
            transition: `height 300ms ease-in-out`,
            opacity: 1,
        }; */





    /*     useEffect(() => {
            const interval = setInterval(() => {
                console.log('This will run every second!');
                 nextNewsInfoCounter(); 
                getNewsInfo(); 
            }, 1000);
            
        }, []); */


    const getButtonLink = () => {
        switch (slide) {
            case (0): { return setButtonLink("aboutUs") }
            case (1): { return setButtonLink("events") }
            case (2): { return setButtonLink("blogs") }
            case (3): { return setButtonLink("demokratieWiki") }

        }
    }

    useEffect(() => {
        getButtonLink()
    }, [slide])

    const nextSlide = () => {
        if (slide >= 0 && slide < 3) {
            setSlide(previous => previous + 1)
        }
        else if (slide => 3) {
            setSlide(previous => 0)
        }

    }

    const previousSlide = () => {
        if (slide > 0 && slide <= 3) {
            setSlide(previous => previous - 1)
        }

        else if (slide <= 0) {
            setSlide(previous => 3)
        }
    }

    const goToSlide = (slideNumber) => {
        setSlide(previous => slideNumber)
    }

    function touchStart(event) {
        setTouchStartX(previous => event.changedTouches[0].screenX)
    }

    function touchEnd(event) {
        setTouchEndX(previous => event.changedTouches[0].screenX);
        handleGesture();
    }




    function handleGesture() {
        if (touchEndX <= touchStartX) {
            if (touchEnd == 0 || touchStart == 0) {
                setTouchStartX(0)
                setTouchEndX(0)
            }
            nextSlide()
        }

        if (touchEndX > touchStartX) {
            if (touchEnd == 0 || touchStart == 0) {
                setTouchStartX(0)
                setTouchEndX(0)
            }
            previousSlide()
        }

    }


    return (
        <>
            <HeroSection slide={slide} onTouchStart={(event) => touchStart(event)} onTouchEnd={(event) => touchEnd(event)} id="gesturedZone" >

                <Slide touch={isTouchDevice()} id="slide0" image={train}>
                    <div id="header0">
                        <BigText >{translate("logo")}</BigText>
                    </div>
                </Slide>

                <Slide touch={isTouchDevice()} id="slide1" image={flag}>
                    <div id="header1">
                        <BigText>{translate("events in your region")}</BigText>
                    </div>
                </Slide>

                <Slide touch={isTouchDevice()} id="slide2" image={telecabin}>
                    <div id="header2">
                        <BigText>{translate("democracy")} Blog</BigText>
                    </div>
                </Slide>



                <Slide touch={isTouchDevice()} id="slide3" image={city}>
                    <div id="header3">
                        <BigText>{translate("democracy")} Wiki</BigText>
                    </div>
                </Slide>


            </HeroSection>
            <Points slide={slide} id="points">
                {/* heere */}
                <CircleIcon onClick={() => previousSlide()} className="chevron-left chevron" color={PRIMARY}><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></CircleIcon>
                <CircleIcon onClick={() => nextSlide()} className="chevron-right chevron" color={PRIMARY}><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></CircleIcon>
                <BlogsProvider>
                    <EventsProvider>
                        <NewSlideBannerPop
                        ></NewSlideBannerPop>
                    </EventsProvider>
                </BlogsProvider>


                <Link to={`/${buttonLink}`}><Button style={{ marginTop: "30px" }} color="rgb(120, 35,40)">{translate("read more")}</Button></Link>
                <div className="slide-point" onClick={() => goToSlide(0)} id="point0"></div>
                <div className="slide-point" onClick={() => goToSlide(1)} id="point1"></div>
                <div className="slide-point" onClick={() => goToSlide(2)} id="point2"></div>
                <div className="slide-point" onClick={() => goToSlide(3)} id="point3"></div>
            </Points>
        </>
    )
}

export default SlideBanner
