import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import styled from 'styled-components'
import { storage } from '../../../firebase/config';
import uniqid from 'uniqid'
import PdfPlaceholder from "../../../Assets/Images/PDF.png"

const Container = styled.div`
width: 100%;
height: fit-content;
position: relative;

.input-file {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}


 

>.image-preview{
  transition:  0.3s;
  z-index:99999999999;
  background: #fff;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  >img{
    margin: auto;
    object-fit: contain;
    width: auto;
    height: 100%;
    border-radius: 5px;
  }
  position: absolute;
  bottom: 120%;
  border-radius: 10px;
  height: ${props => props.visible ? "20vh" : 0};
  overflow: hidden;
}
`
const SpinnerRotation = styled.div`
@keyframes roatateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


animation: roatateAnimation 1s linear infinite;


`









const MultipleImagesInput = ({ images, setImages, path }) => {
  //getting the node that represents the file input 
  //to avoit an error only if the function is present we perform the convertion
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)





  const deleteImage = (id) => {
    let list = [...images]
    list = list.filter(element => element.id !== id)
    setImages(previous => [...list])
  }




  const storeImageGetLink = (e, path) => {
    setLoading(true)
    const promises = []
    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        promises.push(handleUpload(e.target.files[i], path))
      }
    }

    Promise.all(promises)
      .then(res => {
        setLoading(false)
      })
      .catch(err => {
        setError(true)
        setLoading(false)
      })

  }


  const handleUpload = (file, path) => {
    const storageRef = storage.ref('something/' + file.name);
    return storageRef.put(file).then(res => res.ref.getDownloadURL()).then(url => {
      setImages(previous => [...previous, { image: url, id: uniqid() }])
    })
  }



  return (
    <ImagesContrainer>
      <div style={{ width: "fit-content" }}>
        <Container error={error} >
          <input onInput={(e) => storeImageGetLink(e)} class="imageUpload input-file" id="my-file" type="file" name="files" multiple ></input>
          <AddImageButton>
            {loading ?
              <SpinnerRotation><FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon></SpinnerRotation>
              :
              <p>+</p>}
          </AddImageButton>
        </Container>
      </div>
      {images.map((item, index) => <div
        key={item.id}><div
          onClick={() => { deleteImage(item.id) }}
          className="delete-image-hover"><FontAwesomeIcon
            color={"white"}
            icon={faTrash}></FontAwesomeIcon></div><img
              src={item.image.includes(".pdf") ? PdfPlaceholder : item.image}
            ></img></div>)}
    </ImagesContrainer>

  )
}

export default MultipleImagesInput





const ImagesContrainer = styled.div`
width:100%;
display:flex;
align-items: center;
margin-bottom: 2vh;
padding-bottom: 2vh;
overflow: scroll;
>div{
    position: relative;
    margin-right: 1vw;
    height: fit-content;

    .delete-image-hover{
        position: absolute;
        
        background: rgba(64, 106, 255, 0);
        opacity: 0;

            height: 10vh;
            width: 10vh;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in-out;
            z-index:999;

            cursor: pointer;
            >svg{
                height: 30%;
                width: 30%;
            }
            

        :hover{
            
            opacity: 1;
            background: rgba(255, 38, 38, 0.32);;
          }
           
    }
    >img{
    height: 10vh;
    width: 10vh;
    margin-bottom: -7px;
    object-fit: contain;
}}
`


const AddImageButton = styled.label`
height:10vh;
width:10vh;
display:flex;
background: rgba(126, 126, 126, 0.07);;
align-items:center;
justify-content:center;
cursor: pointer;
margin-right:1vw;
>p{
    font-size: 3vh;
    font-weight: 500;
}
`









