import React from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import EventUpload from '../../Pages/PrivatePages.js/EventUpload'
import MyUploads from '../../Pages/PrivatePages.js/MyUploads'
import BlogUpload from '../../Pages/PrivatePages.js/BlogUpload'
import OrganisationUpload from '../../Pages/PrivatePages.js/OrganisationUpload'
import PrivateRoute from '../GeneralUse/PrivateRoute'
import Loading from '../GeneralUse/Loading'

const UploadPrivateRouterComponent = ({match}) => {
    const {currentUserInfo} = useAuth()
    return (
        currentUserInfo?
        <div>
        <PrivateRoute path={match.url +"/uploadEvent"} component={EventUpload}></PrivateRoute>
        <PrivateRoute path={match.url +"/uploadBlogs"} component={BlogUpload}></PrivateRoute>
        <PrivateRoute path={match.url +"/uploadOrganisation"} component={OrganisationUpload}></PrivateRoute>
        <PrivateRoute path={match.url +"/myUploads"} component={MyUploads}></PrivateRoute>
        </div>
        :
        <Loading></Loading>
    )
}

export default UploadPrivateRouterComponent
