import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components'
import { PRIMARY } from '../../../Assets/Styles';
import { storage } from '../../../firebase/config';
import translate from '../../../i18n/messages/translate';
const { promisify } = require('util')

const sizeOf = require('image-size')

const Container = styled.div`
margin:auto;
width: 100%;
height: fit-content;
margin: auto !important;
position: relative;
.input-file-trigger {
  padding: 14px 45px;
  background: rgba(255, 0, 0, 0.25);
  color: #fff;
  font-size: 1em;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  border: ${props => props.error ? "2px solidrgb(120, 35,40)e21c21" : "none"};
  margin: auto;
  :hover{
    background: rgba(255, 0, 0, 0.45);;
  }
}
.input-file {
  position: absolute;
  top: 0; left: 0;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}


 

>.image-preview{
  transition:  0.3s;
  background: #fff;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  >img{
    margin: auto;
    object-fit: contain;
    width: auto;
    height: 100%;
    border-radius: 5px;
  }
  position: absolute;
  bottom: 120%;
  border-radius: 10px;
  height: ${props => props.visible ? "20vh" : 0};
  overflow: hidden;
}
`

const SpinnerRotation = styled.div`
@keyframes roatateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


animation: roatateAnimation 1s linear infinite;


`


// function byteCount(s) {
//   return encodeURI(s).split(/%..|./).length - 1;
// }

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
// function resizedataURL(datas) {
//   return new Promise(async function (resolve, reject) {

//     // We create an image to receive the Data URI
//     var img = document.createElement('img');
//     let newWidth = 0
//     let newHeight = 0

//     // When the event "onload" is triggered we can resize the image.
//     img.onload = function () {
//       // We create a canvas and get its context.
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       const newImage = document.createElement('img');
//       newImage.src = datas
//       newWidth = newImage.width
//       newHeight = newImage.height
//       console.log(newWidth*newHeight)
//      while(newWidth*newHeight>300000){
//       newWidth = newWidth*0.999
//       newHeight = newHeight*0.999
//      }
//      console.log(newWidth*newHeight)
 
//       // We set the dimensions at the wanted size.
     
//       canvas.width = newWidth ;
//       canvas.height = newHeight ;

//       // We resize the image with the canvas method drawImage();
//       ctx.drawImage(newImage, 0,0, newWidth, newHeight );

//       const dataURI = canvas.toDataURL();


//       // This is the return of the Promise
//       resolve(dataURI);
//     };

//     // We put the Data URI in the image's src attribute
//     img.src = datas;

//   })
// }// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);



export const storeImageGetLink = (fileInput, setImage) => {

  if (fileInput) {
    fileInput.addEventListener("change", (e) => {
      // get a reference to the file
      const file = e.target.files[0];

      // encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
      const storageRef = storage.ref('img/'+file.name);
        // log to console
        // logs data:<type>;base64,wL2dvYWwgbW9yZ...
        if (reader.result) {
          storageRef.put(file)
          .then(res=>res.ref.getDownloadURL()).then(url=>{
            setImage(url)
          })

        }
      };
      reader.readAsDataURL(file);
    });
  }
}





let fileInput = ""
const FileInput = ({ imageUrl, setImageUrl, error, setError }) => {
  //getting the node that represents the file input 
  //to avoit an error only if the function is present we perform the convertion
  const [hover, setHover] = useState(false)
  const [loading, setLoading] = useState(false)

  
  useEffect(() => {
    fileInput = document.getElementsByClassName("imageUpload")[0];
  }, [])

  const inputHnandler = () => {
    setLoading(true)
    storeImageGetLink(fileInput, setImageUrl)
    setError(false)
  }

  

  useEffect(()=>{
    if(imageUrl){
      setLoading(false)
    }
  },[imageUrl])

  return (
    <Container error={error} visible={hover}>
      <div className="image-preview"><img src={imageUrl}></img></div>
      <input onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onInput={() => inputHnandler()} class="imageUpload input-file" id="my-file" type="file" ></input>
      <label
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ background: imageUrl ? PRIMARY : "rgba(255, 0, 0, 0.25)" }}
        tabIndex="0" for="my-file" class="input-file-trigger">
        {loading?
        <SpinnerRotation><FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon></SpinnerRotation>
          :
          error ? translate("no file selected") : imageUrl ? translate("file selected"): translate("select image")}</label>
    </Container>
  )
}

export default FileInput
