import React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import Loading from '../Components/GeneralUse/Loading'
import PodcastComponent from '../Components/GeneralUse/PodcastComponent'
import { useLanguage } from '../Contexts/LanguageContext'
import { LOCALES } from '../i18n'
import { database } from '../firebase/config'
import translate from '../i18n/messages/translate';

const Container = styled.div`
display: flex;
flex-wrap: wrap;

`

const translateDinamically = (language, object) => {
    if (language === LOCALES.GERMAN) {
        return {
            name: object.nameDE,
            description: object.descriptionDE
        }
    }
    else if (language === LOCALES.FRENCH) {
        return {
            name: object.nameFR ? object.nameFR : object.nameDE,
            description: object.descriptionFR ? object.descriptionFR : object.descriptionDE
        }
    }
    else if (language === LOCALES.ROMANSH) {
        return {
            name: object.nameRO ? object.nameRO : object.nameDE,
            description: object.descriptionRO ? object.descriptionRO : object.descriptionDE
        }
    }
    else if (language === LOCALES.ITALIAN) {
        return {
            name: object.nameIT ? object.nameIT : object.nameDE,
            description: object.descriptionIT ? object.descriptionIT : object.descriptionDE
        }
    }

}
const NhgPodcasts = () => {
    const { language, setLanguage } = useLanguage()
    const [podcasts, setPodcasts] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const getAllEntries = () => {
        let documents = []
        database.collection("podcasts").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.data()) { documents.push({ ...doc.data(), id: doc.id }) }
                });
                setPodcasts(previous => documents)
                setLoading(prevoius => false)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            })
    }



    useEffect(() => {
        getAllEntries()
    }, [])

    console.log(podcasts)
    return (
        loading ?
            <Loading></Loading>
            :
            /* commented out until we have real podcasts */
                      /*<div>
                         <br></br>
                         <p>{translate("coming soon")}</p>
                     </div> */
             <Container>
                {podcasts.sort((b, a) => a.dateAdded > b.dateAdded ? 1 : -1).map((podcast, index) => {
                    return <PodcastComponent
                        key={index}
                        title={translateDinamically(language, podcast).name}
                        src={podcast.link}
                        img={podcast.img}
                        description={translateDinamically(language, podcast).description}></PodcastComponent>
                })}

            </Container> 
    )
}

export default NhgPodcasts
