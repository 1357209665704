import React, {useContext, useState, useEffect} from 'react'
import {auth, database, provider} from "../firebase/config"



const AuthContext = React.createContext()
export const useAuth=()=>{
        return useContext(AuthContext)
    }

export const AuthProvider = ({children}) => {

    //this is an object that represent our current user, it will be available throuhg all of our application using context
    const [currentUser, setCurrentUser] = useState({})
    const [currentUserInfo, setCurrentUserInfo] = useState({})
    const [loadingCurrentUserInfo, setLoadingCurrentUserInfo] = useState(true)
    const [loading, setLoading] = useState(true)

    function signUp(email, password){
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function logIn(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }

    function sendEmail () {
        auth.currentUser.sendEmailVerification()
  .then(() => {
  })
  .catch((err)=>{
      console.log(err)
  })

    }

    function signUpWithGoogle(){
    auth
    .signInWithPopup(provider)
    .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    var credential = result.credential;

    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    // ...
    }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    var email = error.email;
    var credential = error.credential;
    // ...
  });
    }

    
    
    function logOut(){
        return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        return currentUser.updateEmail(email)
    }

    function updatePassowrd(password){
        return currentUser.updatePassowrd(password)
    }


    

    useEffect(()=>{
        let unsubscribe=auth.onAuthStateChanged(user=>{
        setCurrentUser(previous=>user)
    })
    
    return unsubscribe
    
    }, [])


  



   
    useEffect(()=>{
        if(currentUser){setLoadingCurrentUserInfo(true)
        let userInfo={}
        if(currentUser.uid){database.collection("users").where("uid", "==", currentUser.uid)
        .get()
        .then((querySnapshot) => {   
            querySnapshot.forEach((doc) => {
                if (doc.data()){userInfo = {...doc.data(), id: doc.id}}
            });
            setCurrentUserInfo(previous=>userInfo)
            setLoadingCurrentUserInfo(false)
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        })}else{
            return
        }}
    
    },[currentUser])

    



    
    
    let value={
        currentUser,
        currentUserInfo,
        loadingCurrentUserInfo,
        signUp,
        signUpWithGoogle,
        logIn,
        logOut,
        loading,
        resetPassword,
        updateEmail,
        updatePassowrd,
        sendEmail
    }

    useEffect(()=>{
        if (currentUser) setLoading(false)
    }, [currentUser])
    
    return (
        <AuthContext.Provider value={value}>
        {children}
        </AuthContext.Provider>
    )
}