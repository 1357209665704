import React from 'react'
import styled from 'styled-components'
import EventCardPreview from '../Components/GeneralUse/Event/EventCardPreview'
import Loading from '../Components/GeneralUse/Loading'
var uniqid = require("uniqid")

const Container =  styled.div`
display: flex;
flex-wrap: wrap;
margin-top: 5vh;
`


const Events = ({loading, events}) => {

  return (
    loading?
    <Loading></Loading>
    :
    <Container>{events.sort((a, b) => a.date < b.date ? 1 : -1).map(element=>
      <EventCardPreview 
        key={uniqid()}
        id={element.id} 
        text={element.text} 
        startTime={element.startTime.toDate()} 
        endTime={element.endTime.toDate()} 
        date={element.date.toDate()} 
        image={element.image} 
        header={element.header} 
        location={element.location} 
        organisation={element.organisation}></EventCardPreview>)
  }
  </Container>
  )
}

export default Events

