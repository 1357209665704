import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { PRIMARY } from '../../../Assets/Styles';
import translate from '../../../i18n/messages/translate';


 const useStyles = makeStyles((theme) => ({
    root:{
        width: '80%',
        margin: "auto",

        ' > *': {
          margin: theme.spacing(1),
        },
        "& .MuiFilledInput-root":{
          borderRadius: 3,
          backgroundColor: "rgba(235, 38, 59, 0.04);",
        },

        "& .MuiFilledInput-root.Mui-focused .MuiFilledInput-notchedOutline":{
          borderColor: PRIMARY
        },

        "& .MuiFormLabel-root.Mui-focused":{
            color: PRIMARY,
        },

        "& .MuiFilledInput-underline:before":{
          borderBottom: "1px solid rgb(120, 35,40) !important"
        },
        "& .MuiFilledInput-underline:after":{
          borderBottom: "2px solid rgb(120, 35,40)"
      },
        "& .MuiInputLabel-filled":{
          zIndex: 0,
        },
        

      },

    
    }))

const InputMUI = ({value, setValue, label, type, error, setError, disabled, defaultValue, rows, rowsMax, className, ref}) => {
    
     function handleChange (e){
       e.preventDefault()
     if (setError){
       setError(false)
      }
     if (typeof(e.target.value)==undefined){
         return ""
     }else{
         setValue(e.target.value)
         }}

  
    const classes = useStyles();


    const nullError = () => {
      if(setError){setError(false)}else{return}
    }

    return (
        <>
            <TextField
            onFocus={()=>nullError()}
            type={type}
            inputRef={ref}
            className={className}
            defaultValue={defaultValue}
            error={error}
            value={value}
            disabled={disabled}
            onChange={(e)=>handleChange(e)} 
            className={classes.root} 
            id="outlined-basic" 
            multiline={rows}
            rows={rows}
            rowsMax={rowsMax}
            label={error?translate("please fill out the input") :label} 
            variant="filled" />
        </>
    )
}

export default InputMUI
