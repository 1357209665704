import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import Events from '../../Pages/Events'
import {useEvents} from "../../Contexts/EventsCotext"
import Loading from '../GeneralUse/Loading'
import FullEventComponent from '../GeneralUse/Event/FullEventComponent'



const EventsRouterComponent = () => {
  const {loading, events} = useEvents()
  const match = useRouteMatch()

  return (
        loading?
        <Loading></Loading>
        :
        <>
        {events.map(element=><Route key={element.id} path={match.url + "/"+element.id}>
                <FullEventComponent 
                key={element.id}
                  id={element.id} 
                  text={element.text} 
                  startTime={element.startTime.toDate()} 
                  endTime={element.endTime.toDate()} 
                  date={element.date.toDate()} 
                  image={element.image} 
                  header={element.header} 
                  location={element.location} 
                  organisation={element.organisation} 
                  author={element.author}></FullEventComponent>
            </Route>)}
        <Route exact path={match.url}>
          <Events events={events} loading={loading}></Events>
        </Route>
            
            </>
            
        
    )
}

export default EventsRouterComponent






