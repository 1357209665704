import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import styled from "styled-components"
import { useBlogs } from '../../Contexts/BlogContext'
import { useEvents } from "../../Contexts/EventsCotext"
import { Button, NewsInfoButton, BigText, BLUE, PRIMARY } from "../../Assets/Styles"
import { Link } from 'react-router-dom'
import Loading from '../GeneralUse/Loading'

const NewSlideBannerPopLoading = styled.div`
   .lds-circle > div{ background: ${BLUE};}
`

const SlideBannerPop = () => {
    const { blogs, blogsLoading } = useBlogs()
    const { eventsLoading, events } = useEvents()
    const [counter, setCounter] = useState(0);
    const [counterChanged, setCounterChanged] = useState(true)
    const [newsInfo, setNewsInfo] = useState()
    const [newsArray, setNewsArray] = useState([])

    useEffect(() => {
        console.log("something changed")
        console.log("blogsLoading, eventsLoading: ", blogsLoading, eventsLoading)
        console.log("blogs: ", blogs)
        console.log("events: ", events)
        if (blogsLoading != [] && eventsLoading != []) {

            console.log("setNew array: ", blogs.concat(events).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map(element => ({
                title: element.header,
                link: element.location ? "/events/" + element.id : "/blogs/" + element.id
            })))
            setNewsArray(blogs.concat(events).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map(element => ({
                title: element.header,
                link: element.location ? "/events/" + element.id : "/blogs/" + element.id
            })))
        }
    }, [blogsLoading, eventsLoading, blogs, events])

    useEffect(() => {
        setNewsInfo(newsArray[counter])
        console.log("newsInfo updated to: ", newsInfo)
    }, [newsArray, counter])

    useEffect(() => {
        const timeout2 = setTimeout(() => {
            setCounterChanged(false)
        }, 5000);
        return () => {
            clearTimeout(timeout2);
        };
    }, [counter]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            nextCounter(counter + 1);
            setCounterChanged(true)
            console.log("counter updated to: ", counter)
        }, 6000);
        return () => {
            clearTimeout(timeout);
        };
    }, [counter]);

    const lastIdx = 3;

    const nextCounter = () => {
        if (counter >= 0 && counter < lastIdx) {
            setCounter(previous => previous + 1)
        }
        else if (counter >= lastIdx) {
            setCounter(previous => 0)
        }
    }

    if (!blogs) {
        return null;
    }
    return (!newsInfo ?
        <></>
        :
        <>
            <CSSTransition
                in={counterChanged}
                timeout={3000}
                classNames="alert"
                unmountOnExit
            >
                <Link
                    /* style={transitionStyle} */
                    className="news-button"
                    to={newsInfo.link}>
                    <NewsInfoButton
                        style={{ marginTop: "30px" }}
                        color={BLUE}>{newsInfo.title}
                    </NewsInfoButton>
                </Link>
            </CSSTransition>
        </>)
}
export default SlideBannerPop