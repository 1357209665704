import React from 'react'
import styled from 'styled-components'
import parse from "html-react-parser"
const Container = styled.div`
>h1, h2, h3, h4, h5,p, >ul>li{
                    font-weight: 400;
                    margin-bottom: 10px;
                }
//text
    >h1, h2, h3{
        font-size: 1.8rem;
        line-height: 2rem;
    }
    >h4{
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
    >h5{
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
    >p, >ul>li{
        line-height: 1.5rem;
    }
    >p>a{
        font-weight: 600;
        text-decoration: underline;
    }
           
            width: 100%;
            >*>p{
                max-width: 100%;
            }
            
            >p>img{
                width: 100% !important;
                border-radius: 10px;
            }
@media (orientation: landscape) and (min-width: 768px){
        >.event-text{
            >.html-text{
                >h3, h4, h5{
                    font-weight: 400;
                    margin-bottom: 15px;
                }
        }
    }
    }
`
const QuillTextDisplay = ({text}) => {
    return (
        <Container className="text">
            {typeof text=="object"? text:parse(text)}
        </Container>
    )
}

export default QuillTextDisplay
