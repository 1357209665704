import { faCalendarAlt, faCheck, faChevronRight, faPenAlt, faTimes, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import CircleIcon from '../CircleIcon'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom'
import { convertDate } from '../../../Assets/UtilityFunctions'
import { bigShadow, PRIMARY } from '../../../Assets/Styles'
import translate from '../../../i18n/messages/translate'
import Banner from '../Banner'
import { deleteDocument } from '../../../firebase/DatabaseFunctions'
import { useHistory } from 'react-router'



const Container = styled.div`
opacity:${props=>props.delete?"0":"1"};
height:${props=>props.delete?"0":"auto"};
width: 100%;
text-align: left;
margin-bottom: 50px;
transition: all 0.5s;

>img{
    width: 100%;
}

.blogs-content{
    margin-top: -5px;
    padding: 5%;
    background: ${props=>props.color? props.color:PRIMARY};
    >.blogs-text{
        >h3{
            margin-bottom: 15px;
            color: #fff;
            font-size: 1.5rem;
        }
        >div{
            color: white !important;
            >*{
                color: white !important;
                font-size: 1rem;
                font-weight: 100;
                >*{
                    color: white !important;
                }
            }

            line-height: 1.2rem;
            height: 6rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
>.blogs-content>.container{
    padding: 15px 5px;
    display: flex;
    align-items: center;

.circle-icon{
    margin-right: 10px;
        transition: all 0.2s;
        cursor: pointer;
            :hover{
                box-shadow: ${bigShadow};
            }
            
        }

}
>div>div>.blogs-details{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    height: 100%;
            >.details{
                padding: 5px 0;
                margin-right: 30px;
                display: flex;
                align-items: center;
                >svg{
                    width: 17px;
                    height: 17px;
                    margin-right: 7px;
                    color: white;
                }
                
            }
        }
        

@media (orientation: landscape) and (min-width: 768px){
    width: 90%;
    margin-bottom: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    margin: auto;
    margin-bottom: 10%;


>.blogs-content>.container>.blogs-details{
    >.details{
        >svg{
            width: 21px !important;
            height: 21px !important;
            margin-right: 7px;
            color: #fff;
                } 
            }
        }
}

@media (orientation: portrait) and (min-width: 768px){
    width: 90%;
    margin-bottom: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    margin: auto;
    margin-bottom: 10%;


>.blogs-content>.container>.blogs-details{
    >.details{
        >svg{
            width: 21px !important;
            height: 21px !important;
            margin-right: 7px;
            color: white;
                } 
            }
        }
}

`

const Line = styled.div`
background-color: #fff;
width: 100%;
height: 1px;
margin: auto;
border-radius: 50px;
`

const BlogComponentPreview = ({id, image, header, text, author, dateTime, deleteButton}) => {
    const [deleteInfo, setDeleteInfo] = useState(false)
    const [editInfo, setEditInfo] = useState(false)
    const [deleteSuccesful, setDeleteSuccesful] = useState(false)
    const [deleteError, setDeleteError] = useState(false)
    const history = useHistory()



    const deleteBlog = ()=>{
        deleteDocument("blogs", id).then(()=>{
            setDeleteSuccesful(true)
            history.go(0)
        }).catch(()=>{
            setDeleteError(true)
        })
    }

    return (
        <>
        <Banner trigger={deleteError} setTrigger={setDeleteError}>
            <FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon>
        </Banner>
        <Container delete={deleteSuccesful} key={id}>
            <img src={image}></img>
            <div className="blogs-content">
                <div className="blogs-text">
                <h3>{header}</h3>
                <div>{text? parse(text):""}</div>
                </div>
                <div style={{justifyContent:deleteButton?"flex-end":"space-between"}} className="container">
                {deleteButton?
                        <></>
                        :
                        <div className="blogs-details">
                                <div className="details">
                                    <FontAwesomeIcon  icon={faUser}></FontAwesomeIcon><p style={{color: "white"}}>{author}</p>
                                </div>
                                <div className="details">
                                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon><p style={{color: "white"}}>{
                                         convertDate(dateTime.toDate()).basicDate
                                        }</p>
                                </div>
                        </div>}
                        {deleteButton?
                        <Link to={{
                            pathname: "/editBlog",
                            //here we pass to the components of the corresponding route already existing article parameters, in order to edit the, on the NewsUpload component
                            state: {
                              editText: text,
                              editId: id, 
                              editImage:image, 
                              editHeader:header, 
                              editText:text, 
                              editAuthor:author
                            },
                          }}>
                        <CircleIcon  color="#fff" iconColor={PRIMARY} setInfo={setEditInfo}  info={editInfo}  infoText={translate("edit")} className="circle-icon"   style={{justifySelf: "flex-end"}} >
                            <FontAwesomeIcon icon={faPenAlt}></FontAwesomeIcon>
                        </CircleIcon></Link>
                        :
                        <></>}
                        {deleteButton?
                        <CircleIcon onClick={()=>deleteBlog()} color="#fff" iconColor={PRIMARY} setInfo={setDeleteInfo}  info={deleteInfo} infoText={translate("delete")}  className="circle-icon" style={{justifySelf: "flex-end"}} >
                            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                        </CircleIcon>
                        :<></>}

                        <Link to={`/blogs/${id}`}>
                            <CircleIcon color="#fff" iconColor={PRIMARY} className="circle-icon" style={{justifySelf: "flex-end"}} >
                                <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                            </CircleIcon>
                        </Link>
                 </div>   
                    <Line></Line>
            </div>
        </Container>
        </>
    )
}

export default BlogComponentPreview
