import { faCalendarAlt, faChevronRight, faClock, faPenAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import CircleIcon from '../CircleIcon'
import { bigShadow, BLUE, PRIMARY } from '../../../Assets/Styles'
import parse from 'html-react-parser';
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { convertDate } from '../../../Assets/UtilityFunctions'
import { useState } from 'react'
import translate from '../../../i18n/messages/translate'
import { deleteDocument } from '../../../firebase/DatabaseFunctions'
import Banner from '../Banner'

const Container = styled.div`
margin: auto;
width: 95%;
height: fit-content;
box-shadow: ${bigShadow};
margin-bottom: 5vh;

>.event-image{
    transition: all 0.3s ease;
    width: 100%;
    height: 35vh;
    >img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
}
div>.event-text{
        position: relative;
        
        >span{
            background: linear-gradient(transparent 90%, #0D337B );            
            bottom: 0;
            display: block;
            z-index: 99;
            height: 30vh;
            width: 100%;
            position: absolute;
            height: 30vh;
            opacity: 1; 
            display:none;
        }
    }


@media (orientation: landscape) and (min-width: 768px){
    width: 30vw;
    height: 80vh;

    :hover{
    >.event-image{
    height: 0;
}
    >div{
        height: 80vh;
    }

    div>.event-text{
        position: relative;
        >div{
            height: 30vh;
            opacity: 1; 
        }
        >span{
            display: block;
            z-index: 99;
            height: 30vh;
            width: 100%;
            position: absolute;
            height: 30vh;
            opacity: 1; 
        }
    }
    
}
}
@media (orientation: landscape) and (max-height: 500px){
    height: fit-content;
    width: 40vw;
    >.event-image{
    height: 55vh;
}
}

@media (orientation: portrait) and (min-width: 500px){
    width: 45vw;
}


`

const TimeContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 40px;


>.date{
    display: flex;
    height: 3.9rem;
    >.date-day{
        font-size: 5rem;
        line-height: 3.9rem;
        color:white;
    }
    >.date-month{
        >p{
            color:white;
            font-size: 1.6rem;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}
>div{
    >svg{
        margin-top: 3px;
        height: 1.5rem !important;
        width: 1.5rem !important;
        margin-right: 10px;
        color: ${PRIMARY}
    }
}
>.event-time{
    display: flex;
    >div{
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
        >p{
        color:white;
        font-size: 1.8rem;
    }
        >p:first-child{
            margin-bottom: 2px;
        }
        >p:nth-child(2){
            margin-bottom: -1px;
        }
    }
}


@media (orientation: landscape) and (max-height: 500px){
    height: fit-content;
}
`

const ContentContainer = styled.div`
transition: all 0.3s ease;
padding: 7%;
height: fit-content;
background: ${BLUE};
position: relative;
min-height: fit-content;
padding-bottom: 90px;
height: fit-content;

>.event-text{
    transition: all 0.3s ease;

    >div{
        margin-top: 15px;
        transition: all 0.3s ease;
        height:0;
        overflow: hidden;
        opacity: 0.2;
        >*{
            color: white !important;
            text-align: left;
            color: white;
            >*{
                color: white !important;
            }
        }
    }
    >h3{
    color: white;
    font-size: 1.7rem;
    text-align: left;
    word-wrap: break-word;
}
}

>a{
    >.link-arrow{
    position: absolute;
    bottom: 15px;
    right: 3%;
}
>.delete-icon{
    position: absolute;
    bottom: 15px;
    right: 92px;
}
>.edit-icon{
    position: absolute;
    bottom: 15px;
    right: 175px;
}
}

@media (orientation: landscape) and (min-width: 768px){
    height: 47vh;
}

@media (orientation: landscape) and (max-height: 500px){
    height: fit-content;
}


`









const EventCardPreview = ({ image, header, startTime, endTime, text, id, location, edit, author, date }) => {
   
    var convertedDate = convertDate(date)
    var startDateVariable = convertDate(startTime)
    var endDateVariable = convertDate(endTime)
    const [deleteInfo, setDeleteInfo] = useState(false)
    const [editInfo, setEditInfo] = useState(false)
    const [deleteError, setDeleteError] = useState(false)
    const history = useHistory()

    const deleteEvent = () => {
        deleteDocument("events", id)
            .then(() => {
                history.go(0)
            })
            .catch(() => {
                setDeleteError(true)
            })
    }
    return (
        <>
            <Banner trigger={deleteError} setTrigger={setDeleteError}></Banner>
            <Container >
                <div className="event-image"><img src={image}></img></div>

                <ContentContainer >
                    <TimeContainer>
                        <div className="date">
                            <FontAwesomeIcon color="white" icon={faCalendarAlt}></FontAwesomeIcon>

                            <p className="date-day">{convertedDate.dateDayInt < 10 ? "0" + convertedDate.dateDayInt : convertedDate.dateDayInt}</p>
                            <div className="date-month">
                                <p>{convertedDate.month} </p>
                                <p>{convertedDate.year}</p> 
                            </div>
                        </div>
                        <div className="event-time">
                            <FontAwesomeIcon color="white" icon={faClock}></FontAwesomeIcon>
                            <div>
                                <p>{`${startDateVariable.startHour < 10 ? "0" + startDateVariable.startHour : startDateVariable.startHour}:${startDateVariable.startMinutes < 10 ? "0" + startDateVariable.startMinutes : startDateVariable.startMinutes}`}</p>
                                <p>{`${endDateVariable.startHour < 10 ? "0" + endDateVariable.startHour : endDateVariable.startHour}:${endDateVariable.startMinutes < 10 ? "0" + endDateVariable.startMinutes : endDateVariable.startMinutes}`}</p>
                            </div>
                        </div>
                    </TimeContainer>
                    <div className="event-text">
                        <h3>{header}</h3>
                        <div>{text ? parse(text) : "No text"}</div>
                        <span></span>
                    </div>
                    <div style={{ width: "fit-content", position: "absolute", alignContent: "space-between", display: "flex", justifyContent: "", bottom: "20px", right: "20px" }}>

                        {edit ?
                            <>
                                <Link style={{ marginLeft: "10px" }} to={{
                                    pathname: "/editEvent",
                                    //here we pass to the components of the corresponding route already existing article parameters, in order to edit the, on the NewsUpload component
                                    state: {
                                        editText: text,
                                        editHeader: header,
                                        editImage: image,
                                        editId: id,
                                        editEventLocation: location,
                                        editAuthor: author,
                                        editDate: date,
                                        editStartTime: startTime,
                                        editEndTime: endTime
                                    },
                                }}>
                                    <CircleIcon className="edit-icon" color={PRIMARY} setInfo={setEditInfo} info={editInfo} infoText={translate("edit")}><FontAwesomeIcon icon={faPenAlt}></FontAwesomeIcon></CircleIcon>
                                </Link>
                                <Link style={{ marginLeft: "10px" }} onClick={() => deleteEvent()}>
                                    <CircleIcon onClick={() => deleteEvent()} color={PRIMARY} iconColor="#fff" setInfo={setDeleteInfo} info={deleteInfo} infoText={translate("delete")} className="circle-icon" style={{ justifySelf: "flex-end" }} >
                                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                    </CircleIcon>
                                </Link>


                            </>
                            :
                            <></>}
                        <Link style={{ marginLeft: "10px" }} to={"/events" + "/" + id}>
                            <CircleIcon
                                className="link-arrow"
                                color={PRIMARY}><FontAwesomeIcon
                                    color={PRIMARY}
                                    icon={faChevronRight}>
                                </FontAwesomeIcon>
                            </CircleIcon>
                        </Link>
                    </div>

                </ContentContainer>
            </Container>
        </>
    )
}

export default EventCardPreview
