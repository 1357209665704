import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import translate from '../../../i18n/messages/translate'



const Container = styled.div`
height:25px;
width:25px;
border-radius:5px;
border:${props=>props.selected?"2px solid" +props.color:"2px solid #c4c4c4"};
display: flex;
justify-content: center;
align-items: center;
background: ${props=>props.selected?props.color:""};
>img{
    height: 80%;
    position: relative;
    right: 1px;
}`

const Check = ({selected, setSelected, color}) => {

    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Container color={color} onClick={()=>setSelected(previous=>!previous)} selected={selected}>
            {selected?<FontAwesomeIcon color="white" icon={faCheck}></FontAwesomeIcon>:null}
            
        </Container>
        <p style={{marginLeft:"15px"}}>{translate("president")}</p>
        </div>
    )
}

export default Check
