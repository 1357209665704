import { faEnvelope, faLocationArrow, faPen, faPhone, faTrashAlt, faUser, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { PRIMARY } from '../../Assets/Styles'
import CircleIcon from './CircleIcon'
import Avatar from 'react-avatar';
import translate from '../../i18n/messages/translate'
import { Link } from 'react-router-dom'
import { LinkedinIcon } from 'react-share'

/* before: width: 100%, then: 30vw */
const Container = styled.div`
height: fit-content;
width: 100%;
overflow-x: scroll;
>.face-name{
    padding: 20px;
    height: 40%;
    background: ${PRIMARY};
    display: flex;
    align-items: center;
>div>.sb-avatar__text{
    border-radius: 50%;
    height: 80px !important;
    width: 80px !important;
}
>div{
    height: 80px !important;
    width: 80px;
}
>div>span{
    height: 80px !important;
    width: 80px !important;
}
>img{
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
}
>p{
    color: white;
    font-size: 1.5rem;
    margin-left: 30px;
}
}

>.details{
    background: white;
    position: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 0;
    >div{
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
    }
    >div{
            font-weight: 700;
            color: #565656;
        
    >svg{
        margin-right: 20px;
        height: 1.2rem;
        width: 1.2rem;
    }
}
}

>.delete-edit{
    padding: 5%;
    padding-top: 0;
    background: white;
    display: flex;
    align-items: top;
    justify-content: flex-end;
    >div{
        margin-left: 25px;
    }
}


@media (min-width: 768px) and (orientation: landscape){
    width:25vw;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}
@media (min-width: 600px) and (orientation: portrait){
    width:40vw;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);}
`

const svg = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#565656" />
    <path fill-rule="evenodd" clipRule="evenodd" d="M14.7747 10.2839C14.7747 11.529 13.8267 12.5366 12.3442 12.5366C10.9194 12.5366 9.97132 11.529 10.0007 10.2839C9.97132 8.97828 10.9193 8 12.3726 8C13.8267 8 14.7463 8.97828 14.7747 10.2839ZM10.1199 28.8191V14.3162H14.6271V28.8181H10.1199V28.8191Z" fill="white" />
    <path fill-rule="evenodd" clipRule="evenodd" d="M18.2398 18.9446C18.2398 17.1356 18.1802 15.5935 18.1206 14.3181H22.0356L22.2437 16.305H22.3326C22.9258 15.3854 24.4084 13.9927 26.8106 13.9927C29.7757 13.9927 32 15.9501 32 20.219V28.821H27.4927V20.7838C27.4927 18.9143 26.8408 17.6399 25.2097 17.6399C23.9637 17.6399 23.2229 18.4999 22.9268 19.3297C22.8076 19.6267 22.7489 20.0412 22.7489 20.4574V28.821H18.2416V18.9446H18.2398Z" fill="white" />
</svg>


const TeamMemberCard = ({ name, email, position, edit, id, setEditId, setDeleteId, memberImage, phone, location, groupLink, surname, link }) => {
    const [editInfo, setEditInfo] = useState(false)
    const [deleteInfo, setDeleteInfo] = useState(false)

    return (
        <Container>
            <div className="face-name">
                {memberImage ? <img src={memberImage}></img> : <Avatar name={name} />}
                <p>{`${name +" "+  surname}`}</p>
            </div>
            <div className="details">
                {position ?
                    <div style={{ textAlign: "left" }}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                        {position}
                    </div> : null}
                {location ?
                    <div style={{ textAlign: "left" }}>
                        <FontAwesomeIcon icon={faLocationArrow}></FontAwesomeIcon>
                        {location ? location : translate("no location")}
                    </div> : null}
                {email ?
                    <div style={{ textAlign: "left" }}>
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                        {email ? email : translate("no email")}
                    </div> : null}
                {phone ?
                    <div style={{ textAlign: "left" }}> <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        {phone ? phone : "..."}
                    </div> : null}
                {link ?
                    <div style={{ textAlign: "left" }}> <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                 
                            <a style={{ color: "#000", borderBottom: "1px solid #565656" }} href={link}><div style={{ color: "#565656",}}>{link}</div></a>

                    </div> : null}
            </div>
            {
                edit ?
                    <div className="delete-edit">
                        <CircleIcon info={editInfo} setInfo={setEditInfo} infoText={translate("delete")} color={PRIMARY} onClick={() => setDeleteId(id)}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></CircleIcon>
                        <CircleIcon info={deleteInfo} setInfo={setDeleteInfo} infoText={translate("edit")} color={PRIMARY} onClick={() => setEditId(id)}><FontAwesomeIcon icon={faPen}></FontAwesomeIcon></CircleIcon>
                    </div>
                    :
                    <></>
            }
        </Container>
    )
}

export default TeamMemberCard
