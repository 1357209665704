import React, { useEffect, componentDidMount } from 'react'
import styled from 'styled-components'
import { BLUE } from '../../../Assets/Styles'
/* import jura from "../../../Assets/Images/CantonFlags/Jura.png"
 */
import { useState } from 'react'
import { fetchResourcesByCantonId, fetchResourcesByUid } from '../../../firebase/DatabaseFunctions'
import { useAuth } from '../../../Contexts/AuthContext'
import EventCardPreview from '../Event/EventCardPreview'
import uniqid from 'uniqid'
import TeamMemberCard from '../TeamMemberCard'
import parse from "html-react-parser"
import translate from '../../../i18n/messages/translate'
import { OrganisationSlideDown } from '../../../Pages/PrivatePages.js/OrganisationUpload'
import ImagesContainer from '../ImagesContainer'
import { useLanguage } from '../../../Contexts/LanguageContext'
import { LOCALES } from '../../../i18n';

const BiggerContainer = styled.div`
width:100%;
position:relative;
>img{
display: none;
    }
h2, a, p{
    color: ${props => props.whiteVersion ? "#565656" : "white"} !important;
}
@media (orientation: landscape) and (min-width: 768px){
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 15vw;
    margin-top: 0;
    >img{
        display: block;
position:absolute;
top:0;
left:0;

max-width:100%;
width: 100vw;
object-fit: contain;
    }
}`


const Image = styled.img`
    width:100%;
    `


const EventsContainer = styled.div`
display: grid !important;
grid-template-columns: 100%;
overflow-y: hidden;
overflow-x: scroll;
grid-gap:1vw;
p{
    color: white !important;
}

`

const Container = styled.div`
width: 100%;
background: ${BLUE};

height: fit-content;
position: relative;
    @media (orientation: landscape) and (min-width: 768px){
        width: 100%;
        margin: auto;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    }

`

const Header = styled.div`
position: relative;
height: fit-content;
width: 100%;
padding-left: 5vw;
padding-right: 5vw;
padding-top: 20vh;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-bottom: 5vh;
>img{
    height: 15vh;
    position: absolute;
    top:0;
}
>.organisation-text{
    >*{color: white;}
    >h2{
        text-align: left;
        font-size: 2.5rem;
        margin-bottom: 2vh;
    }
    >p{
        font-size: 1.1rem;
        line-height: 1.4rem;
        text-align: left;

    }
}


@media (orientation: landscape) and (min-width: 768px){
    height: fit-content;
    width: 100%;
    padding-top: 5vh;
    padding-bottom: 5vh;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    
    >img{
        width: 25%;
        position: static;
        height: auto;
    }

    >.organisation-text{
        width: 60%;
        margin-right: 0;
        >*{color: white;}
        >h2{
            text-align: left;
            font-size: 2.5rem;
        }
        >p{
            text-align: left;
            font-size: 1.1rem;
            line-height: 1.4rem;
        }
}
}
`


const InfoContainer = styled.div`
height:fit-content;
padding-left: 5vw;
padding-right: 5vw;
margin-bottom: 5vh;
padding-bottom: 5vh;
overflow: hidden;
>h2{
    text-align: left;
    font-size: 2rem;
    margin-bottom: 2vh;
    color: white;
}

>div{
     display: flex; 
    p{
        margin-right: 20px;
    text-align: left;
    color: white;
    }
    >a{
    text-align: left;
    color: white;
    }
}
`

const Table = styled.table`
color: white
`
const Td = styled.td`
/* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
`


const TeamMembersContainer = styled.div`
flex-wrap: wrap;
display: grid;
grid-template-columns: 100%;
overflow-y: hidden;
overflow-x: scroll;
grid-gap:5vw;
margin-bottom: 5vh;
padding-right: 8px;
padding-left: 8px;
@media (orientation: landscape) and (min-width: 768px){
    grid-template-columns: 50% 50%;
    grid-gap:3vw;
    padding-left: 5vw;
    padding-right: 1vw;
    padding-bottom: 3vh;
    padding-top: 3vh;
}
`
const CantonProfile = ({ infoObject }) => {
    console.log("create cantonProfile")
    const [selectedMenuItem, setSelectedMenuItem] = useState("")
    const [events, setEvents] = useState([])
    const [loadingEvents, setLoadingEvents] = useState(false)
    const fetchCantonEvents = () => {
        const docs = []
        if (infoObject.id) {
            fetchResourcesByCantonId("events", infoObject.id)
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data()) { docs.push({ ...doc.data(), id: doc.id }) }
                    });
                    setEvents([...docs])
                    setLoadingEvents(false)
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                })
        }
    }
    const { language, setLanguage } = useLanguage()

    useEffect(() => {
        console.log("useEffect")
        fetchCantonEvents()
        if (infoObject.id == "geneve") {
            setLanguage(LOCALES.FRENCH)
        }
    }, [])
    return (
        <BiggerContainer whiteVersion={infoObject.whiteVersion}>
            <img src={infoObject.backgroundImage}></img>

            <Container style={{ background: infoObject.whiteVersion ? "white" : "" }}>
                <Header>
                    <div className="organisation-text">
                        <h2>{infoObject.name}</h2>
                        {infoObject.description ? parse(infoObject.description) : null}
                    </div>
                    <img src={infoObject.flagImage}></img>
                </Header>
                <div >
                    {infoObject.hideContact ?
                        <p> {infoObject.infoText}</p> :
                        <InfoContainer>
                            <h2>{translate("contact")}</h2>
                            <div>
                                <Table>
                                    <tbody>
                                        {infoObject.email ? <tr>
                                            <Td>Email: </Td>
                                            <Td>{infoObject.email}</Td>
                                        </tr> : null}
                                        {infoObject.link ? <tr>
                                            <Td>Link: </Td>
                                            <Td><a href={infoObject.link}>{infoObject.link}</a></Td> {/* <a><p>{infoObject.link}</p></a>  */}
                                        </tr> : null}
                                        {infoObject.phoneNumber ? <tr>
                                            <Td>{translate("phone number")}:‎‎‎‎‎</Td>
                                            <Td>‎‎‎‎‎‎‎{infoObject.phoneNumber}</Td>
                                        </tr> : null}
                                        {infoObject.address ? <tr>
                                            <Td>{translate("address")}:</Td>
                                            <Td>{infoObject.address}</Td>
                                        </tr> : null}
                                    </tbody>
                                </Table>
                            </div>

                        </InfoContainer>}

                    <InfoContainer style={{ padding: 0 }}>
                        {events.length > 0 ?
                            <>
                                <h2 style={{ paddingLeft: "5vw" }}>{translate("events")}:</h2>
                                <EventsContainer style={{ paddingLeft: "5vw" }}>
                                    {[...events].sort((a, b) => a.date < b.date ? 1 : -1).map(element =>
                                        <EventCardPreview
                                            key={uniqid()}
                                            id={element.id}
                                            text={element.text}
                                            startTime={element.startTime.toDate()}
                                            endTime={element.endTime.toDate()}
                                            date={element.date.toDate()}
                                            image={element.image}
                                            header={element.header}
                                            location={element.location}
                                            organisation={element.organisation}></EventCardPreview>
                                    )}
                                </EventsContainer>

                            </>
                            :
                            null}
                    </InfoContainer>
                    {/* .sort((a, b) => a.order < b.order ? 1 : -1). */}
                    <InfoContainer className="info-container-team" style={{ padding: 0 }}>
                        {infoObject.members && infoObject.members.length > 0 ?
                            <>
                                <h2 style={{ paddingLeft: "5vw" }}>{translate("team")}:</h2>
                                <TeamMembersContainer className="team-members-container">
                                    {infoObject.members.sort((b, a) => a.order < b.order ? 1 : -1).map(element =>
                                        <TeamMemberCard
                                            className="team-member-card"
                                            memberImage={element.memberImage}
                                            key={element.id}
                                            email={element.email}
                                            name={element.name}
                                            surname={element.surname}
                                            id={element.id}
                                            link={element.link}
                                            phone={element.phone}
                                            position={element.position}></TeamMemberCard>)
                                    }
                                </TeamMembersContainer>
                            </>
                            :
                            null}
                    </InfoContainer>
                    <InfoContainer className="info-container-archive">
                        {infoObject.archiveItems && infoObject.archiveItems.length > 0 ?
                            <>
                                <h2 className="header" >{translate("archive")}:</h2>
                                <EventsContainer className="events-container">

                                    {[...infoObject.archiveItems.sort((a, b) => compareTwoDates(a.date, b.date) ? 1 : -1)].map(element => {
                                        console.log("year: ", element.date)
                                        return (
                                            <OrganisationSlideDown className="organisatin-slide-down" id={element.id} date={element.date} description={element.description} header={element.title}>
                                                <ImagesContainer className="images-container" images={element.images.map(element => element.image)}></ImagesContainer>
                                            </OrganisationSlideDown>)
                                    })}
                                </EventsContainer>

                            </>
                            :
                            null}
                    </InfoContainer>
                </div>
            </Container>

        </BiggerContainer>

    )
}

export default CantonProfile

function compareTwoDates(date1, date2) {
    console.log(dateConversion(date1))
    console.log(dateConversion(date2))
    var date1String = dateConversion(date1)
    var date2String = dateConversion(date2)
    if (date1String < date2String) { return true }
    else { return false }
}

function dateConversion(date) {
    var splits = date.split(" ")
    var yyyy = splits[3]
    var mm = monthMap[splits[2].substring(0, 3).toLowerCase()]
    var dd = dayMap(splits[1].split(".")[0])
    return yyyy + mm + dd
}

function dayMap(day) {
    if (day.length == 2) {
        return day
    }
    else {
        return "0" + day
    }
}

const monthMap = {
    "jan": "01",
    "feb": "02",
    "mär": "03",
    "apr": "04",
    "mai": "05",
    "jun": "06",
    "jul": "07",
    "aug": "08",
    "sep": "09",
    "okt": "10",
    "nov": "11",
    "dez": "12"
}