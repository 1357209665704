import React from 'react'
import styled from 'styled-components'
import { BLUE, Button, MediumText, PRIMARY } from '../../Assets/Styles'
import user from "../../Assets/Images/user.png"
import { useState } from 'react'
import { useEffect } from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import InputMUI from '../../Components/GeneralUse/Input/InputMUI'
import Loading from '../../Components/GeneralUse/Loading'
import { database } from '../../firebase/config'
import Banner from '../../Components/GeneralUse/Banner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import translate from '../../i18n/messages/translate'

const Wrapper = styled.div`
width:100vw;
height:100vh;
display:flex;
align-items:center;
justify-content:center;
`
const Container = styled.div`
height: fit-content;
width: 100%;
background: ${PRIMARY};
>.user-photo-header{
  padding:5%;
    
      >h3{
    margin-top: 20px;
    color: white;
    }
}

>.user-profile-input {
  background: white;
  width: 100%;
  height: 70vh;
  >.user-name-profile, .linked-in-profile{
    display: flex;
    flex-direction: column;
    >div{
margin: 5% auto;
    }
  }
}
@media (orientation: landscape) and (min-width: 768px){
  width: 70%;
  margin: auto;
    >.user-photo-header{  
      display: flex;
        >h3{
          margin-left: 2%;
        }
        >button{
          margin: auto;
          margin-right: 0;
      }
    }

  >.user-profile-input{
  background: white;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  >.user-name-profile{
    display: flex;
    flex-direction: row;
    >div{
      margin: 5%;
    }
  }
  .linked-in-profile{
  display: flex;
    flex-direction: row;
    >div{
      margin: 5%;
    }
  
}
}


}`


const UserPhotoUpload = styled.div`
width: 5rem;
height: 5rem;
height: fit-content;
margin: auto !important;
margin-left: 0;
border-radius: 50%;
position: relative;

.input-file-trigger {
    height: 5rem;
border-radius: 50% !important;
    width: 100%;
  display: block;
  background: #fff;
  color: #000;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  border-radius: 5px;

  >img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
  }
}

.input-file {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

@media (orientation: landscape) and (min-width: 768px){
  margin: 0 !important;
}
`

const convertImageToUrl = (fileInput, setImage) => {
  if (fileInput) {
    fileInput.addEventListener("change", (e) => {
      // get a reference to the file
      const file = e.target.files[0];

      // encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        // log to console
        // logs data:<type>;base64,wL2dvYWwgbW9yZ...
        if (reader.result) setImage(reader.result)
      };
      reader.readAsDataURL(file);
    });
  }
}


let fileInput = ""

const UserProfile = () => {
  const [fetchloading, setFetchLoading] = useState(true)
  const { currentUser ,currentUserInfo} = useAuth()
  const [userImageUrl, setUserImageUrl] = useState("")
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [linkedIn, setLinkedIn] = useState("")
  const [position, setPosition] = useState("")
  const [successful, setSuccesful] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [docId, setDocId] = useState("")
  const clearAll = () => {

    setLoading(false)
  }
  useEffect(() => {
    fileInput = document.getElementsByClassName("imageUpload")[0];
  }, [fetchloading])




  const fetchResourcesByUid = () => {
    const docs = []

    database.collection("users").where("uid", "==", currentUser.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data()) { docs.push({ ...doc.data(), id: doc.id }) }
        });
        setName(docs[0].name)
        setSurname(docs[0].surname)
        setLinkedIn(docs[0].linkedIn)
        setPosition(docs[0].position)
        setUserImageUrl(docs[0].userImageUrl)
        setDocId(docs[0].id)
        setFetchLoading(false)
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        clearAll()
        setFetchLoading(false)
      })

  }


  useEffect(() => {
    if(currentUser){if (currentUser.uid) fetchResourcesByUid()}
  }, [currentUser])


  const updateUser = () => {
    database.collection("users").doc(docId).update({ name, surname, linkedIn, position, userImageUrl: userImageUrl, uid: currentUser.uid }).then(
      () => {
        setSuccesful(true)
        setLoading(false)
      }
    )
      .catch((error) => {
        setError(error)
        setLoading(false)
      });
  }
  const handleSubmit = () => {
    updateUser()
  }


  console.log(currentUserInfo)

  return (
    fetchloading ?
      <Loading></Loading>
      :
      <>
        <Banner trigger={successful} setTrigger={setSuccesful}><MediumText>Succesfully Updated Profile</MediumText><FontAwesomeIcon color={BLUE} icon={faCheck}></FontAwesomeIcon></Banner>
        <Banner trigger={error} setTrigger={setError}><MediumText>Try Again, an error occured</MediumText><FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon></Banner>
        <Wrapper>
          <Container >
            <div className="user-photo-header">
              <UserPhotoUpload>
                <input onInput={() => convertImageToUrl(fileInput, setUserImageUrl)} className="imageUpload input-file" id="my-file" type="file" ></input>
                <label tabIndex="0" for="my-file" className="input-file-trigger"><img src={userImageUrl ? userImageUrl : user}></img></label>
              </UserPhotoUpload>
              <MediumText>{name ? name  : translate("name")}</MediumText>
              <MediumText>{surname ? surname : translate("surname")}</MediumText>

              <Button onClick={() => { handleSubmit() }} disabled={loading} color="white" textColor={PRIMARY}>{translate("save changes")}</Button>
            </div>
            <div className="user-profile-input">
              <div className="user-name-profile">
                <InputMUI value={name} setValue={setName} label={translate("name")}></InputMUI>
                <InputMUI value={surname} setValue={setSurname} label={translate("surname")}></InputMUI>
              </div>
              <div className="linked-in-profile">
                <InputMUI value={linkedIn} setValue={setLinkedIn} label="Linked In"></InputMUI>
                <InputMUI value={position} setValue={setPosition} label={translate("position")}></InputMUI>
              </div>
            </div>
          </Container>
        </Wrapper>
      </>
  )
}

export default UserProfile
