import React from 'react';

function CenteredText(props) {
    
    return (
        <div style={{display: "flex", height: "80vh", width: "100vw", padding: "2.5vh", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
            <p style={{marginBottom: "20px", textAlign: "center", color:"#565656"}}>{props.children}
            </p>
        </div>
    );
}

export default CenteredText;