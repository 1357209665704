import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
  } from '@material-ui/pickers';
import { PRIMARY } from '../../../Assets/Styles';



  const useStyles = makeStyles((theme) => ({
    root:{
        width: '80%',

        ' > *': {
          margin: theme.spacing(1),
        },
        "& .MuiIconButton-root":{
          color: PRIMARY
        },
        "& .MuiInput-underline:before":{
            borderBottom: "1px solid rgb(255 0 0 / 42%)"
        }
        
        

      },

    
    }))


const TimePicker = ({time, setTime, label}) => {
  const handleTimeChange = (date) => {
    setTime(date);
  };

  const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
      
        <KeyboardTimePicker
        className={classes.root} 
          margin="normal"
          id="time-picker"
          label={label}
          value={time}
          onChange={handleTimeChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
    )
}

export default TimePicker
