import React, {useContext, useState, useEffect} from 'react'
import { getAllDocuments } from '../firebase/DatabaseFunctions'



const BlogsContext = React.createContext()

export const useBlogs=()=>{
        return useContext(BlogsContext)
    }



export const BlogsProvider = ({children}) => {
    const [loading, setLoading] = useState(true)
    const [blogs, setBlogs]  = useState([])


    useEffect(()=>{
            getAllDocuments("blogs", setBlogs, setLoading)
    }, [])
    
    let value={
      loading, blogs
    }

    return (
        <BlogsContext.Provider value={value}>
        {children}
        </BlogsContext.Provider>
    )
}