import React from 'react'
import BlogComponentPreview from '../Components/GeneralUse/Blog/BlogComponentPreview'
import { SlicedContainer} from "../Assets/Styles"
import Loading from '../Components/GeneralUse/Loading'
import { sliceArray } from '../Assets/UtilityFunctions'


const Blogs = ({blogs, loading}) => {
    const {firstDiv, secondDiv} = sliceArray(blogs, BlogComponentPreview)


    return (
        loading?
        <Loading></Loading>
        :
        <SlicedContainer>
            <div>
                {firstDiv}
            </div>
            <div>
                {secondDiv}
            </div>
        </SlicedContainer>
        
    )
}

export default Blogs
