import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import user from "../../../Assets/Images/user.png"
import { PRIMARY } from '../../../Assets/Styles';

const UserPhotoUpload= styled.div`
width: 5rem;
height: 5rem;
height: fit-content;
margin: auto !important;
margin-left: 0;
border-radius: 50%;
position: relative;
margin-top: 70px;
>div {
  margin-top: 40px;
z-index:9999;
  height: 2rem;
  width: 2rem;
  position: absolute;
  top:-1rem;
  display:flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  background: black;
  color: white;
  transition: all .4s;
  border-radius: 5px;
  cursor: pointer;
}
.user-image-input-file-trigger {
  margin-top: 30px;
    height: 5rem;
  border-radius: 50% !important;
    width: 100%;
  display: block;
  background: #fff;
  color: #000;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  border-radius: 5px;
  >img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;

  }
}

.user-image-input-file {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

@media (orientation: landscape) and (min-width: 768px){
  margin: 0 !important;
}
`

const convertImageToUrl = (e, setImage) =>{
  console.log("converImageToUrl")
  if(!e.target.files[0]){
    console.log("no target")
    return
  }
        // get a reference to the file
        const file = e.target.files[0];
    
        // encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {

          if(reader.result)setImage(reader.result)
        };
        console.log("reader.readAsDataURL")
        reader.readAsDataURL(file);
     
  }
const UserImageInput = ({userImageUrl,setUserImageUrl}) => {

  
    return (
        <UserPhotoUpload >
                  <div onClick={()=>setUserImageUrl("")}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></div>
                  <input  onInput={(e)=>convertImageToUrl(e, setUserImageUrl)} class="user-image-imageUpload user-image-input-file" id="user-image-my-file" type="file" ></input>
                  <label tabIndex="0" for="user-image-my-file" class="user-image-input-file-trigger"><img src={userImageUrl? userImageUrl: user}></img></label>
                </UserPhotoUpload>
    )
}

export default UserImageInput
