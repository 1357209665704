import './App.css';
import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"
import Home from './Pages/Home';
import Header from './Components/UniqueComponents/Header';
import Footer from './Components/UniqueComponents/Footer';
import styled from 'styled-components';
import PartnerCards from './Pages/PartnerCards';
import Support from './Pages/Support';
import LogIn from './Pages/LogIn';
import NewsUpload from './Pages/PrivatePages.js/BlogUpload';
import PrivateRoute from './Components/GeneralUse/PrivateRoute';
import BlogRouterComponent from './Components/RouterComponents.js/BlogRouterComponent';
import EventsRouterComponent from './Components/RouterComponents.js/EventsRouterComponent';
import UploadRouterComponent from './Components/RouterComponents.js/UploadRouterComponent';
import RegionenRouterComponent from './Components/RouterComponents.js/RegionenRouterComponent';
import CantonProfile from "./Components/GeneralUse/Canton/CantonProfile"
import { EventsProvider } from './Contexts/EventsCotext';
import { BlogsProvider } from './Contexts/BlogContext';
import NotFound from './Pages/NotFound';
import Archiv from './Pages/Archiv';
import UserProfile from './Pages/PrivatePages.js/UserProfile';
import Kontakt from './Pages/Kontakt';
import AboutUs from './Pages/AboutUs';
import DemokratieWiki from './Pages/DemokratieWiki';
import { I18nProvider, LOCALES } from "./i18n/index"
import { useEffect, useState } from 'react';
import EventUpload from './Pages/PrivatePages.js/EventUpload';
import NhgPodcasts from './Pages/NhgPodcasts';
import ScrollToTop from './firebase/hooks/ScrollToTop';
import { useLanguage } from './Contexts/LanguageContext';

const Space = styled.div`
  height: 10vh;
  width: 100vw;
  position: static;
  background-color: transparent;
  
  @media (orientation: landscape) and (min-width: 768px){
    position: static;
    }
  @media (orientation: landscape) and (max-height: 500px){
      height: 20vh;
      }
  `

/* Added this class to make absolute redirects possible (needed in custom paths further down) */
class AbsoluteRedirect extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.location = this.props.to
  }
  render() {
    return null
  }
}



function App() {
  const { language, setLanguage } = useLanguage()
  const [cantons, setCantons] = useState([])
  return (

    <I18nProvider locale={language}>
      <ScrollToTop>
        <div className="App" style={{ background: "#f2f2f7" }} >
          <Header language={language} setLanguage={setLanguage}></Header>
          <div style={{ minHeight: "100vh" }}>

            <Space className="space"></Space>
            <Switch >



              <Route path="/events">
                <EventsProvider>
                  <EventsRouterComponent></EventsRouterComponent>
                </EventsProvider>
              </Route>

              <Route path="/blogs">
                <BlogsProvider>
                  <BlogRouterComponent></BlogRouterComponent>
                </BlogsProvider>
              </Route>

              <Route path="/region" >
                <RegionenRouterComponent></RegionenRouterComponent>
              </Route>



              <Route exact path="/" component={Home}></Route>
              <Route path="/organisations" component={PartnerCards}></Route>
              <Route path="/logIn" component={LogIn}></Route>
              <Route path="/upload" component={UploadRouterComponent}></Route>
              <Route path="/archiv" component={Archiv}></Route>
              <Route path="/kontakt" component={Kontakt}></Route>
              <Route path="/aboutUs" component={AboutUs}></Route>
              <Route path="/demokratieWiki" component={DemokratieWiki}></Route>
              <Route path="/partnerCards" component={PartnerCards}></Route>
              <Route path="/support" component={Support}></Route>
              <Route path="/nhgPodcasts" component={NhgPodcasts}></Route>

              {/* Custom Paths added to maintain /ag links */}
              <Route path="/de">
                <Redirect to="/" />
              </Route>
              <Route path="/ag">
                <Redirect to="/region/aargau" />
              </Route>
              <Route path="/bs">
                <Redirect to="/region/basel" />
              </Route>
              <Route path="/be">
                <Redirect to="/region/bern" />
              </Route>
              <Route path="/ge">
                console.log("ge")
                <Redirect to="/region/geneve" />
              </Route>
              <Route path="/sh">
                <Redirect to="/region/schaffhausen" />
              </Route>
              <Route path="/vd">
                <Redirect to="/region/vaud" />
              </Route>
              <Route path="/wt">
                <Redirect to="/region/winterthur" />
              </Route>
              <Route path="/zh">
                <Redirect to="/region/zuerich" />
              </Route>

              {/* Custom Paths added to maintain links on https://www.nhg-bern.ch/veranstaltungen */}
              <Route path="/top-redirect-1">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2FDie%20finsteren%20Phantastereien%20in%20der%20russischen%20Elite.pdf?alt=media&token=ac9a1436-17f1-4ba0-83a4-2edd43c8b42c" />
              </Route>
              <Route path="/images/Intern_Bern/NHG_Rückblick_2018-2019_def.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2FNHG_RuO%CC%8Ba%CC%A8ckblick_2018-2019_def.pdf?alt=media&token=cc3c8d53-39af-447a-900b-7985bdd63fa9" />
              </Route>
              <Route path="/$them/g_be_zyklus16_17digitalisierung.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus16_17digitalisierung.pdf?alt=media&token=7a1c9e48-c9ff-4421-b16c-a7b6427ecbb5" />
              </Route>
              <Route path="/$them/g_be_zyklus14_15nachdenken.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus14_15nachdenken.pdf?alt=media&token=27580ce5-f3b4-4126-94b0-f15f3a646ccc" />
              </Route>
              <Route path="/$them/g_be_zyklus13_14konkurrenz.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus13_14konkurrenz.pdf?alt=media&token=836ff74c-f4dc-43d7-8aec-4ecc78c0fa43" />
              </Route>
              <Route path="/$them/g_be_zyklus12_13souveraenitaet.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus12_13souveraenitaet.pdf?alt=media&token=780773d2-feb4-459b-96b1-efcd2784bb36" />
              </Route>
              <Route path="/$them/g_be_zyklus11_12parteien.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus11_12parteien.pdf?alt=media&token=5cc0eb16-33d0-4a1a-89bf-6265b1b9a97c" />
              </Route>
              <Route path="/$them/g_be_zyklus11_12parteien.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus11_12parteien.pdf?alt=media&token=5cc0eb16-33d0-4a1a-89bf-6265b1b9a97c" />
              </Route>
              <Route path="/$manif/g_be_veranstaltungsreihe2010_11zivilgesellschaft.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus10_11zivilges.pdf?alt=media&token=c9ab9625-f495-4ccf-8928-67905bdffa61" />
              </Route>
              <Route path="/$manif/g_be_veranstaltungsreihe2009_10aufbruch.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus09_10aufbruch.pdf?alt=media&token=0bc41528-511d-4adf-9278-add9109c2925" />
              </Route>
              <Route path="/$manif/g_be_veranstaltungsreihe2008_09.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus08_09.pdf?alt=media&token=323c179f-bab8-4f1d-b608-9619782b3579" />
              </Route>
              <Route path="/$manif/g_be_veranstaltungsreihe2007_08.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus07_08.pdf?alt=media&token=b2f2c3a9-ac5b-4740-8e33-2ebb6f0ad430" />
              </Route>
              <Route path="/$manif/g_be_veranstaltungsreihe2005_06.pdf">
                <AbsoluteRedirect to="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/Archiv%2F%24them%2Fg_be_zyklus07_08.pdf?alt=media&token=b2f2c3a9-ac5b-4740-8e33-2ebb6f0ad430" />
              </Route>


              <PrivateRoute path="/editBlog">
                <NewsUpload editedText={true}></NewsUpload>
              </PrivateRoute>
              <PrivateRoute path="/editEvent">
                <EventUpload editedText={true}></EventUpload>
              </PrivateRoute>
              <PrivateRoute path="/userProfile">
                <UserProfile></UserProfile>
              </PrivateRoute>



              <Route component={NotFound}></Route>

            </Switch>
          </div>
          {/* <Map></Map> */}
          <Footer></Footer>
        </div>
      </ScrollToTop>
    </I18nProvider>
  );
}

export default App;
