import { database, storage } from "./config";





//post a document to a database
const postDatabase = (collection, object) => {
    return database.collection(collection).doc().set(object)

}

const getImageUrl = (name, folder, array, resolve, number) => {
    const imagesRef = storage.ref(`Archiv/Fotos/${folder}/${name}.jpg`)
    imagesRef.getDownloadURL().then(function (url) {
        array.push({ src: url, number: name, folder: folder })
        if (array.length == number) { resolve(array) }
    }
    )
}




const getImagesUrl = (folder, number) => {
    const array = []
    const promise = new Promise((resolve, reject) => {
        for (let i = 1; i <= number; i++) {
            let string = "0" + i.toString()
            if (string.length < 3) {
                string = "0" + string
            }
            getImageUrl(string, folder, array, resolve, number)
        }
    })

    return promise


}


const fetchResourcesByUid = (collection, currentUser) => {
    return database.collection(collection).where("uid", "==", currentUser.uid)
        .get()
}

const fetchResourcesByCantonId = (collection, cantonId) => {
    return database.collection(collection).where("cantonId", "==", cantonId)
        .get()
}



const getAllDocuments = (collection, setState, setLoading) => {
    let documents = []
    database.collection(collection).orderBy("createdAt", "desc").get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data()) { documents.push({ ...doc.data(), id: doc.id }) }
            });
            setState(previous => documents)
            setLoading(previous => false)
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        })
}


const deleteDocument = (collection, document) => {
    return database.collection(collection).doc(document).delete()
}
export { deleteDocument, postDatabase, getAllDocuments, getImagesUrl, getImageUrl, fetchResourcesByUid, fetchResourcesByCantonId }