import React from 'react'
import { useState } from 'react'
import FileInput from '../../Components/GeneralUse/Input/FileInput'
import FormQuill from '../../Components/GeneralUse/Input/FormQuill'
import InputMUI from '../../Components/GeneralUse/Input/InputMUI'
import InputAutoCompleteMUI from "../../Components/GeneralUse/Input/Autocomplete"
import TeamMembersUpload from '../../Components/UniqueComponents/TeamMembersUpload'
import { bigShadow, BLUE, Button, Container, DARKGREY, LIGHTGREY, PRIMARY } from '../../Assets/Styles'
import { useAuth } from '../../Contexts/AuthContext'
import { useEffect } from 'react'
import { database, storage } from "../../firebase/config";
import Loading from '../../Components/GeneralUse/Loading'
import Banner from '../../Components/GeneralUse/Banner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import translate from '../../i18n/messages/translate'
import CantonProfile from '../../Components/GeneralUse/Canton/CantonProfile'
import DatePicker from '../../Components/GeneralUse/Input/DatePicker'
import MultipleImagesInput from '../../Components/GeneralUse/Input/MultipleImagesInput'
import ImagesContainer from '../../Components/GeneralUse/ImagesContainer'
import uniqid from 'uniqid'
import { basicValidation, convertDate } from '../../Assets/UtilityFunctions'
import { faCalendar, faComment } from '@fortawesome/free-solid-svg-icons'
import { Collapse } from '@material-ui/core'
import { MediumText } from '../../Assets/Styles'
import CenteredText from '../../Components/UniqueComponents/CenteredText'
import { BigText } from '../../Assets/Styles'

const PostButton = styled.button`
cursor: pointer;
width: 90vw;
height: 7vh;
background: ${PRIMARY};
border: none;
font-size: 1.4rem;
color: white;
box-shadow: ${bigShadow};
border-radius: 10px;
font-weight: 700;
margin-Bottom: 10vh;
transition: 0.2s;
margin-top:50px;
:hover{
     transform: scale(1.05)
 }

 @media (orientation: landscape) and (min-width: 768px){
    font-size: 2rem;
    width: 90vw;
    height: 7vh;
    margin-top:0px;
    margin-top: 5vh;

 }

`
const cantons = [
    "Zürich (ZH)",
    "Berne (BE)",
    "Luzern (LU)",
    "Uri (UR)",
    "Schwyz (SZ)",
    "Unterwalden (Obwalden (OW) / Nidwalden (NW))",
    "Glarus (GL)",
    "Zug (ZG)",
    "Freiburg (FR)",
    "Solothurn (SO)",
    "Basel (Basel Stadt (BS)",
    "Schaffhausen (SH)",
    "Appenzell Innerrhoden (AI))",
    "Sankt Gallen (SG)",
    "Graubünden (GR)",
    "Aargau (AG)",
    "Thurgau (TG)",
    "Ticino (TI)",
    "Vaud (VD)",
    "Valais (VS)",
    "Neuchâtel (NE)",
    "Genève (GE)",
    "Jura (JU)"
]

const ChooseColorContainer = styled.div`
width:20vw;
height: fit-content;
height: 50px;
display: flex;
align-items: center;
justify-content: space-around;
`

const ChooseColorOption = styled.div`
cursor: pointer;
width:7vw;
height: 50px !important;
height: fit-content;
border-radius: 222px;
background-color: ${BLUE};
box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
border: ${props => props.selected ? "2px solid red" : "none"};
`
const ChooseColor = ({ setColor, color }) => {
    return (
        <ChooseColorContainer>
            <ChooseColorOption selected={color === true} onClick={() => setColor(true)} style={{ background: "white" }}></ChooseColorOption>
            <ChooseColorOption selected={color === false} onClick={() => setColor(false)} style={{ background: BLUE }}></ChooseColorOption>
        </ChooseColorContainer>
    )
}

const OrganisationUpload = () => {
    const { currentUser, currentUserInfo, loadingCurrentUserInfo } = useAuth()

    const [selected, setSelected] = useState("organisation")
    const [header, setHeader] = useState("")

    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [organisation, setOrganisation] = useState("")
    const [whiteVersion, setWhiteVersion] = useState(false)
    const [link, setLink] = useState("")
    const [text, setText] = useState("")
    const [image, setImage] = useState(null)

    const [members, setMembers] = useState([])
    const [archiveItems, setArchiveItems] = useState([])


    const [docId, setDocId] = useState("")
    const [backgroundImage, setBackgroundImage] = useState("")
    const [backgroundImageError, setBackgroundImageError] = useState(false)
    const [flagImage, setFlagImage] = useState(false)

    const [post, setPost] = useState(false)

    const [succesful, setSuccesful] = useState(false)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [loadingPost, setLoadingPost] = useState(false)
    const [startHandleImages, setStartHandleImages] = useState(false)


    const [address, setAddress] = useState("")
    /*     
        Take these two props out
        const [ZIP, setZIP] =  useState("")
        const [town, setTown] =  useState("") */

    const bigObject = {
        name: organisation,
        email: email,
        phoneNumber: phoneNumber,
        link: link,
        description: text,
        link: link,
        header: header,
        members: members,
        address: address,
        /*       Take these two props out
                zip:ZIP,
                town:town, */
        id: currentUserInfo.cantonId,
        whiteVersion: whiteVersion,
        backgroundImage: backgroundImage,
        flagImage: flagImage,
        archiveItems: archiveItems,
        president: members.length > 0 ? members.filter(member => member.president == true)[0].name + " " +
            members.filter(member => member.president == true)[0].surname : null,
    }



    const updateObject = () => {

        /*         console.log("database.collection(cantonOrganisations): ", database.collection("cantonOrganisations"))
                    // delegate: n
                console.log("...doc(Id): ", database.collection("cantonOrganisations").doc(docId))
                    // delegate: t
                console.log("docId: ", docId) 
                    // docId: Winterthur-341897
                console.log("bigObject: ", bigObject)
         */
        setLoadingPost(true)
        database.collection("cantonOrganisations").doc(docId).set({ ...bigObject })
            .then(() => {
                setLoadingPost(false)
                setSuccesful(true)
                clearAll()

            })
            .catch((error) => {
                // The document probably doesn't exist.
                setLoadingPost(false)
                console.log(error)
            });
    }


    useEffect(() => {
        if (post) {
            updateObject()
        }

    }, [post])






    useEffect(() => {
        if (currentUserInfo.cantonId) {
            setLoadingFetch(true)

            let cantonInfo = {}
            if (currentUser.uid) {
                database.collection("cantonOrganisations").where("id", "==", currentUserInfo.cantonId)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {

                            /* console.log("loop through docs, doc: ", doc)
                            console.log("loop through docs, doc.data(): ", doc.data()) */
                            // doc = n {_firestore: t, _delegate: n}
                            // doc.data() = {link: 'https://www.nhg-winterthur.ch/', name: 'Winterthur', president: 'Katharina Teuscher', flagImage: 'https://nhg.ch/static/media/Winterthur.c6943c79.png', presidentWording: 'Präsidentin', …}
                            // doc.id = "Winterthur-341897"

                            if (doc.data()) { cantonInfo = { ...doc.data(), docId: doc.id } }

                        });
                        setMembers(cantonInfo.members || [])
                        setEmail(cantonInfo.email || "")
                        setPhoneNumber(cantonInfo.phoneNumber || "")
                        setLink(cantonInfo.link || "")
                        setText(cantonInfo.description || "")
                        setAddress(cantonInfo.address || "")
                        setOrganisation(cantonInfo.name || "")
                        setDocId(cantonInfo.docId)
                        setWhiteVersion(cantonInfo.whiteVersion || false)
                        setBackgroundImage(cantonInfo.backgroundImage || "")
                        setFlagImage(cantonInfo.flagImage || "")
                        setLoadingFetch(false)
                        setArchiveItems(cantonInfo.archiveItems || [])

                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    })
            } else {
                return
            }

        }
    }, [loadingCurrentUserInfo])


    const clearAll = () => {
        setText("")
    }
    return (
        loadingFetch || loadingCurrentUserInfo ?
            <Loading></Loading>
            :
            <>

                <Banner loading={loadingPost} trigger={succesful} setTrigger={setSuccesful}><FontAwesomeIcon color="#0587FF" icon={faCheck}></FontAwesomeIcon></Banner>
                <FormQuill noButton startHandleImages={startHandleImages} setStartHandleImages={setStartHandleImages} text={text} setPost={setPost} setText={setText} post={post} name={translate("upload organisation's profile")}>
                    <FileInput setError={setBackgroundImageError} imageUrl={backgroundImage} setImageUrl={setBackgroundImage}></FileInput>
                    <ChooseColor color={whiteVersion} setColor={setWhiteVersion}></ChooseColor>
                    <InputMUI value={organisation} setValue={setOrganisation} label={translate("organisation's Name")}></InputMUI>
                    <InputMUI value={phoneNumber} setValue={setPhoneNumber} label={translate("phone number")}></InputMUI>
                    <InputMUI value={address} setValue={setAddress} label={translate("address")}></InputMUI>
                    <InputMUI value={email} setValue={setEmail} label="Email"></InputMUI>
                    <InputMUI value={link} setValue={setLink} label="Link"></InputMUI>
                </FormQuill>
                <TeamMembersUpload setTeamMembersState={setMembers} teamMembersState={members}></TeamMembersUpload>
                <EditArchiv archiveItems={archiveItems} setArchiveItems={setArchiveItems}></EditArchiv>
                <PostButton onClick={() => setStartHandleImages(true)} disabled={loadingPost}>{translate("save changes")}</PostButton>
                <BigText>{translate("preview")}</BigText>
                <CantonProfile infoObject={bigObject}></CantonProfile>
            </>

    )
}

export default OrganisationUpload

const EditArchivContainer = styled.div`
    display: flex;
    align-items:center;
    justify-content:space-between;
    flex-direction: column;

    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: fit-content;
    height: fit-content;


    @media (orientation: landscape) and (min-width: 768px){
        flex-direction: row;
        height: 90vh;

    }
    `


const EditArchiveEntries = styled.div`
    height: 100%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items:center;
    width: 100%;
    box-shadow: ${bigShadow};
    >div{
        margin-bottom: 15px;
    }
    >h2{
        color: white;
        text-align: left;
        width: 100%;
        background-color: ${PRIMARY};
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 10%;
        margin-bottom: 5vh;
    }


    @media (orientation: landscape) and (min-width: 768px){
        width: 35%;

    }
    `
const ArchiveItemsPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    width: 100%;
    box-shadow: ${bigShadow};
    height: 100%;
    background-color: white;
    padding-left: 5vw;
    padding-right: 5vw;

    @media (orientation: landscape) and (min-width: 768px){
        width: 60%;
        overflow: scroll;
    }
    `

const EditArchiv = ({ archiveItems, setArchiveItems }) => {

    const [title, setTitle] = useState("")
    const [titleError, setTitleError] = useState(false)

    const [date, setDate] = useState(new Date())

    const [description, setDescription] = useState("")
    const [descriptionError, setDescriptionError] = useState(false)

    const [editedItemId, setEditedItemId] = useState(undefined)

    const [images, setImages] = useState([])

    const allStateAndErrors = [
        { value: title, setValue: setTitle, setError: setTitleError },
        /*  { value: description, setValue: setDescription, setError: setDescriptionError }, */
    ]


    const addItem = () => {
        if (editedItemId !== undefined) {
            const list = archiveItems
            list[editedItemId] = { title, date: convertDate(date).basicDate, description, images }
            setArchiveItems(previous => [...list])
            setEditedItemId(undefined)
            setTitle("")
            setDate(new Date())
            setDescription("")
            setImages(previous => [])
        } else {
            basicValidation(allStateAndErrors)
            /* before: if (title && description) */
            if (title) {
                setArchiveItems([...archiveItems, { title, date: convertDate(date).basicDate, description, images, id: uniqid() }])
                setTitle("")
                setDate(new Date())
                setDescription("")
                setImages(previous => [])
            }
        }
    }

    useEffect(() => {
        if (editedItemId !== undefined) {
            const item = archiveItems.find(item => item.id === editedItemId)
            setTitle(archiveItems[editedItemId].title)
            setDate(new Date())
            setDescription(archiveItems[editedItemId].description)
            setImages(archiveItems[editedItemId].images)
        }

    }, [editedItemId])




    return (
        <EditArchivContainer>
            <EditArchiveEntries>
                <h2>{translate("add archive item")}</h2>
                <InputMUI error={titleError} setError={setTitleError} label={translate("header")} value={title} setValue={setTitle}></InputMUI>
                <DatePicker label={translate("choose Date")} date={date} setDate={setDate} ></DatePicker>
                <InputMUI error={descriptionError} setError={setDescriptionError} label={translate("description")} rows={5} value={description} setValue={setDescription}></InputMUI>
                <div style={{ width: "80%" }}>
                    <MultipleImagesInput images={images} setImages={setImages} path={"experiment"}></MultipleImagesInput>
                </div>
                <Button bigger onClick={addItem}>{translate("add")}</Button>
            </EditArchiveEntries>
            <ArchiveItemsPreview>
                {archiveItems.length > 0 ?
                    archiveItems.map((element, index) => {
                        return (
                            <OrganisationSlideDown index={index} setSelectedItem={setEditedItemId} edit setArchiveItems={setArchiveItems} id={element.id} date={element.date} description={element.description} header={element.title}>
                                <ImagesContainer images={element.images.map(element => element.image)} imageDesc={"desc"}></ImagesContainer>
                            </OrganisationSlideDown>)
                    })
                    :
                    <CenteredText>{translate("You do not have any archive articles  added yet")}</CenteredText>}
            </ArchiveItemsPreview>
        </EditArchivContainer>
    )
}




const ContentContainer = styled.div`
width: 100%;
height: fit-content;
box-shadow: ${bigShadow};
margin-top: 30px;
background-color: white;
border-radius: 5px;
`

const Text = styled.div`
transition: all 0.3s;
overflow: hidden;
height: fit-content;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
padding:0 2.5vw;

:before {
    display: ${props => props.edit ? "none" : "block"};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 25px solid #FF707F;
    border-left: 25px solid transparent;
    width: 15%;
    border-top-right-radius: 5px;
    }   

    >div{
    position: absolute;
    top: 0;
    right: 0;
    border-top: 25px solid ${PRIMARY};
    border-left: 25px solid transparent;
    width: 10%;
    border-top-right-radius: 5px;

    
    }
>p{
    cursor: pointer;
    position: relative;
    z-index: 3;
    transition: 0.3s;
    font-size: 1.5rem;
}
    >h3{
        color: ${PRIMARY};
    z-index: 9;
    width: 70%;
    padding: 1rem 0;
    text-align: left;
    background-color: white;
    position: relative;
}
`


const AlignLeft = styled.div`
padding: 0 2.5vw;
padding-bottom: 5vh;

*{
    text-align: left;
}`



const IconAndText = styled.div`
margin:auto;
margin-left: 0;
display:flex;
align-items:flex-start;
justify-content:flex-start;
margin-bottom: 2vh;
>svg{
    height: 3vh;
    width: 3vh !important;
}
>div{
    margin-left: 10px;
    color:${DARKGREY} !important;
}


@media (orientation: landscape) and (min-width: 768px){
}
`



const NewButton = styled.button`
background-color: ${PRIMARY};
color: white;
border-radius: 55px;
padding: 15px 18px;
border: none;
cursor: pointer;
margin-left: 10px;
>p{
    color: white;
}
`


const ButtonsContainer = styled.span`
display: flex;
align-items: center;
justify-content: center;
`
const OrganisationSlideDown = ({ index, id, children, header, description, date, edit, setArchiveItems, setSelectedItem }) => {
    const [open, setOpen] = React.useState(false)

    const deleteItem = () => {
        setArchiveItems(previous => [...previous.filter(element => element.id !== id)])
    }


    return (
        <ContentContainer className="content-container">
            <Text edit={edit} open={open} className="slide-header">
                <MediumText className="slide-text" onClick={() => setOpen(previous => !previous)}>{header}</MediumText>
                {edit ? null : <div></div>}
                {edit ?
                    <ButtonsContainer style={{ display: "flex" }}>
                        <NewButton onClick={() => deleteItem()}>
                            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                        </NewButton>
                        <NewButton onClick={() => setSelectedItem(index)}>
                            <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
                        </NewButton>
                    </ButtonsContainer> : null}
            </Text>

            <Collapse in={open}>
                <AlignLeft >
                    <IconAndText style={{ alignItems: "center" }}>
                        <FontAwesomeIcon color={DARKGREY} icon={faCalendar}></FontAwesomeIcon><div style={{ color: DARKGREY }}>{date.toString()}</div>
                    </IconAndText>
                    {description ? <IconAndText>
                        <FontAwesomeIcon color={DARKGREY} icon={faComment}></FontAwesomeIcon><div style={{ color: DARKGREY }}>{description}</div>
                    </IconAndText> : null}
                    {children}

                </AlignLeft>
            </Collapse>
        </ContentContainer>
    )
}

export { OrganisationSlideDown }




