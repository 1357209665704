import React from 'react'
import Card from '../Components/GeneralUse/BigCard'
import LogoContainer from '../Components/UniqueComponents/LogoContainer'
import PartnerCards from './PartnerCards'
import videoPoster from "../Assets/Images/videoPoster.png"
import NewSlideBanner from '../Components/UniqueComponents/NewSlideBanner'
import translate from '../i18n/messages/translate'
import styled from 'styled-components'
import { Button } from '../Assets/Styles'
import { Link } from 'react-router-dom'
import AutoCompleteMap from '../Components/GeneralUse/Input/AutoCompleteMap'
import Cantons from '../Components/UniqueComponents/Cantons'

const CardsContainer = styled.div`
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
padding-bottom: 5vh;`

function Home() {
    //console.log("Home: ", process.env.REACT_APP_STRIPE_SECRET_KEY)
    return (
        <div style={{background: "#f2f2f7"}}>
        <NewSlideBanner></NewSlideBanner>
        <Card 
            src="https://video.wixstatic.com/video/ea9763_6195ab98b80e42d6ac23e4cc38a5fbfe/480p/mp4/file.mp4" 
            heading={translate("nhg switzerland")} 
            subHeading={translate("losung")} 
            video={true} 
            poster={videoPoster}>
        </Card>
        <Cantons limit={2}></Cantons>
        <LogoContainer></LogoContainer>
        <CardsContainer>
            <PartnerCards limit={3}></PartnerCards>
            <Link to="/partnerCards"><Button >{translate("all partners")}</Button></Link>
        </CardsContainer>
        <AutoCompleteMap></AutoCompleteMap>
        
        </div>
        
    )
}

export default Home
