/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { PRIMARY } from '../../../Assets/Styles';

const filter = createFilterOptions();

export default function InputAutocompleteMUI({disabled, options, label, value, setValue, error, setError, noOption}) {
const useStyles = makeStyles((theme) => ({
    root:{
      overflow: "hidden",
        width: '80% !important',
        borderRadius: "0",
        ' > *': {
          margin: theme.spacing(1),
        },

        "& .MuiOutlinedInput-root":{
          borderRadius: 3,
        },

        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
          borderColor: PRIMARY,
          borderRadius: "3px"

        },
        "& .MuiOutlinedInput-notchedOutline":{
            borderColor: error? "red":"rgba(0, 0, 0, 0.23)"
        },
        "& .MuiInput-underline:before":{
          borderBottom: "1px solid rgb(120, 35,40)"
        },
        "& .MuiFilledInput-root":{
          borderRadius: 3,
          backgroundColor: "rgba(235, 38, 59, 0.04);",
        },

        "& .MuiFilledInput-root.Mui-focused .MuiFilledInput-notchedOutline":{
          borderColor: PRIMARY
        },

        "& .MuiFormLabel-root.Mui-focused":{
            color: "rgb(120, 35,40)",
        },

        "& .MuiFilledInput-underline:before":{
          borderBottom: "1px solid rgb(120, 35,40) !important"
        },
        "& .MuiFilledInput-underline:after":{
          borderBottom: "2px solid rgb(120, 35,40)"
      },
        "& .MuiInputLabel-filled":{
          zIndex: 0,
        },

      },
    }))
  const classes = useStyles();
  return (
    <Autocomplete
    clearOnEscape
      disabled={disabled}
      className={classes.root}
      value={value}
      onChange={(event, newValue) => {
        event.preventDefault()
        if (setError){setError(false)}
        if (typeof newValue === 'string') {
          setValue(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue(newValue.inputValue);
        } else {
          setValue(newValue);
        }
      }}

      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '' && !noOption) {
          filtered.push(params.inputValue);
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return noOption? null : (option.inputValue);
        }
        // Regular option
        return option;
      }}
      renderOption={(option) => option}
      style={{ color: "black", width: 300 }}
      freeSolo={!noOption}
      renderInput={(params) => (
        <TextField variant="filled" id="outlined-basic" error={error} {...params} label={label} />
      )}
    />
  );
}