import { faCalendar, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { bigShadow, MediumText, PRIMARY } from '../../Assets/Styles'

const ContentContainer = styled.div`
overflow: scroll;
width: 100%;
height: fit-content;
box-shadow: ${bigShadow};
margin-top: 30px;
background-color: white;
border-radius: 5px;
`

const Text = styled.div`
transition: all 0.3s;
overflow: hidden;
height: fit-content;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
padding:0 2.5vw;

:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-top: 25px solid #FF707F;
    border-left: 25px solid transparent;
    width: 15%;
    border-top-right-radius: 5px;
    }   

    >div{
    position: absolute;
    top: 0;
    right: 0;
    border-top: 25px solid ${PRIMARY};
    border-left: 25px solid transparent;
    width: 10%;
    border-top-right-radius: 5px;

    
    }
>p{
    cursor: pointer;
    position: relative;
    z-index: 3;
    transition: 0.3s;
    font-size: 1.5rem;
}
    >h3{
        color: ${PRIMARY};
    z-index: 9;
    width: 70%;
    padding: 1rem 0;
    text-align: left;
    background-color: white;
    position: relative;
}
`


const AlignLeft = styled.div`
padding: 0 2.5vw;
padding-bottom: 5vh;

*{
    text-align: left;
}`




const SlideDown = ({children, header, description, date}) => {
    const [open, setOpen] = React.useState(false)
    return (
        <ContentContainer>
        <Text open={open} onClick={()=>setOpen(previous=>!previous)} className="slide-header">
            <MediumText>{header}</MediumText>
            <div></div>
        </Text>

        <Collapse in={open}>
           <AlignLeft >
   
            {children}
            </AlignLeft>
        </Collapse>
        </ContentContainer>
    )
}

export default SlideDown
