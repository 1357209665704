import React from 'react'
import { useState, useEffect } from 'react'
import {useSpring, animated} from "react-spring"
import styled from 'styled-components'
import { bigShadow, Flex } from '../../Assets/Styles'
import { storage } from '../../firebase/config'


const Container = styled.div`

>.animation{
  z-index: 999999999;
  position: fixed;
  background-color: rgba(255, 255, 255, .15);   
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left:0;
  overflow: hidden;
  scroll-behavior: unset;

  >button{
  opacity: 1;
}
>h1{
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  font-weight: 300;
}
>img{
box-shadow: ${bigShadow};
max-width:100%;
max-height:60%;
align-self: center;
justify-self: center;
@media (orientation: portrait){
  max-width:60%;
max-height:60%;
}
}
}

`

const ZoomImagesContainer = (props) => {
    const [showImage, setShowImage] = useState(false)
    const [src, setSrc] = useState("")

    const contentProps = useSpring({
        opacity: showImage ? 1 : 0,
        marginTop: showImage ? 0 : -1600
    });

    useEffect(()=>{
      const images = document.getElementsByTagName("img")
      for (let image of images){
          image.addEventListener("click", (e)=>{
            const imagesRef = storage.ref(`Archiv/Fotos/${props.folder}/${e.currentTarget.className}.jpg`)
            imagesRef.getDownloadURL().then(function(url) {setSrc(previous=>url)})
              setShowImage(previous=>!previous)
              })
      }
  },[props.finished])


    return (
      
        <Container>
          <animated.div className="animation" style={contentProps}>
            <h1 onClick={()=>{
              setShowImage(false)
              setSrc("")}}>×</h1>
            <img src={src} ></img>
          </animated.div>
            <Flex justify="space-between">
              {props.children}
            </Flex>
        </Container>
    )
}

export default ZoomImagesContainer
