import React from 'react'
import PartnerCard from '../Components/GeneralUse/PartnerCard'
import styled from 'styled-components'
import ch2048 from "../Assets/Images/OrganisationLogos/ch2048.svg"
import foraus from "../Assets/Images/OrganisationLogos/foraus.png"
import lia from "../Assets/Images/OrganisationLogos/liaRumantscha.png"
import muller from "../Assets/Images/OrganisationLogos/muller.png"
import forumHelveticum from "../Assets/Images/OrganisationLogos/forumHelveticum.png"
import campusDemocratie from "../Assets/Images/OrganisationLogos/campusDemocratie.png"
import SGA from "../Assets/Images/OrganisationLogos/SGA|ASPE.png"
import { BigText, PRIMARY } from '../Assets/Styles'
import maisonDe from "../Assets/Images/OrganisationLogos/maisonDe.png"
import swissComunity from "../Assets/Images/OrganisationLogos/swissComunity.png"
import sajv from "../Assets/Images/OrganisationLogos/sajv.png"
import chStiftung from "../Assets/Images/OrganisationLogos/chStiftung.png"
import stapferHaus from "../Assets/Images/OrganisationLogos/stapferHaus.png"
//import helvezin from "../Assets/Images/OrganisationLogos/helvezin.png"
import strategieDialog from "../Assets/Images/OrganisationLogos/strategieDialog.png"
import SSG from "../Assets/Images/OrganisationLogos/SSG.png"
import DSJ from "../Assets/Images/OrganisationLogos/DSJ.png"
import prohelvetia from "../Assets/Images/OrganisationLogos/prohelvetia.png"
import FJG from "../Assets/Images/OrganisationLogos/FJG.png"
import translate from '../i18n/messages/translate'
var uniqid = require('uniqid');


const OrganisationsContainer = styled.div`
display: grid;
height: fit-content;
width: 100vw;
grid-template-columns: 1fr;
justify-items: center;
margin-bottom: 40px;
grid-gap: 1rem;


@media (orientation: landscape) {
    padding: 2.5% !important;
    justify-content: stretch;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    grid-gap: 4rem;
}

@media (orientation: landscape) and (max-height: 500px){
    grid-template-columns:0.5fr;
    justify-content: center;
    >div>div>p{
        font-size: 0.8rem;
    }
}

@media (orientation: portrait) and (min-width: 768px){
    padding: 2.5% !important;
    grid-template-columns: 0.5fr 0.5fr ;
}
`

const Container = styled.div`
margin-top: 5vh;

>h2{
    margin-bottom: 1rem;
}   
`

const organisationsDetails = [
    {text:"Our purpose is Elaboration and launch of policy development projects and reform proposals in the interests of a globally competitive and responsible Switzerland.",
    image:ch2048,
    title:"Fondation CH2048"
}
]


const cards = [
    
/* <PartnerCard key={uniqid()}
text= "Unser Ziel ist die Erarbeitung und Lancierung von politischen Entwicklungsprojekten und Reformvorschlägen im Interesse einer global wettbewerbsfähigen und verantwortungsvollen Schweiz."//"Our purpose is Elaboration and launch of policy development projects and reform proposals in the interests of a globally competitive and responsible Switzerland." 
image={ch2048} 
title="Fondation CH2048"
link="https://www.ch2048.ch/"></PartnerCard>, */
<PartnerCard key={uniqid()}
text="Die Schweizerische Gemeinnützige Gesellschaft (SGG) wurde 1810 als Verein gegründet. Sie setzt sich für den sozialen Zusammenhalt der Gesellschaft in der Schweiz ein."//"Support your Swiss foreign policy think tank and enjoy the unique advantages and the access to a top-class foreign policy network – national and international!" 
image={SSG} 
title="SSG"
link="https://sgg-ssup.ch/ "></PartnerCard>,

<PartnerCard key={uniqid()}
text="Unterstützen Sie Ihren Think Tank der Schweizer Aussenpolitik und geniessen Sie die einzigartigen Vorteile und den Zugang zu einem hochkarätigen aussenpolitischen Netzwerk - national und international!"//"Support your Swiss foreign policy think tank and enjoy the unique advantages and the access to a top-class foreign policy network – national and international!" 
image={foraus} 
title="Foraus"
link="https://www.foraus.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Die Lia Rumantscha ist eine Organisation, die den Gebrauch und das Studium der rätoromanischen Sprache fördert. Sie wurde 1919 gegründet und hat ihren Sitz in Chur."//"Lia Rumantscha is an organization that promotes Romansh language usage and study. Established in 1919, its seat is in Chur." 
image={lia} 
title="Lia Rumantscha"
link="http://www.liarumantscha.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="NETWORK MÜLLERHAUS bietet professionelle Management- und Sekretariatslösungen für Organisationen und Verbände."//"NETWORK MÜLLERHAUS offers professional management and secretarial solutions for organizations and associations. From 2001 to 2018, the..." 
image={muller} 
title="Müllerhaus" 
link="https://muellerhaus.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Das Forum Helveticum, 1968 als Begegnungs- und Diskussionsforum gegründet, setzt sich für die kulturelle und sprachliche Verständigung zwischen den verschiedenen Regionen der Schweiz ein."//"Forum Helveticum, founded in 1968 as a forum for meeting and discussion, is committed to fostering cultural and linguistic understanding between the different regions in Switzerland..." 
image={forumHelveticum} 
title="Forum Helveticum"
link="https://forum-helveticum.ch/en/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Der 'Campus Demokratie' wurde von der 'Stiftung Dialog' in Zusammenarbeit mit der 'Neuen Helvetischen Gesellschaft' gegründet, um die politische Bildung und die politische Partizipation schweizweit zu fördern und damit die Demokratie zu stärken."//"The 'Campus Demokratie' was founded by the 'Stiftung Dialog' in cooperation with the 'Neue Helvetishe Geselschaft' to promote political education and political participation throughout Switzerland and thus strengthen democracy." 
image={campusDemocratie} 
title="Campus Demokratie"
link="https://campusdemokratie.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Die Schweizerische Gesellschaft für Aussenpolitik SGA-ASPE  ist eine überparteiliche und gemeinnützige Organisation, gegründet 1968. Ziel der SGA ist, das Interesse an Schweizer Aussenpolitik zu fördern..." 
image={SGA} 
title="SGA | ASPE" 
link="https://www.sga-aspe.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="La Maison de l'Europe Transjurassienne (MET) ist ein gemeinnütziger Verein mit Sitz in Neuchâtel, der seit mehreren Jahren in den verschiedenen Regionen des Jurabogens tätig ist."//"La Maison de l'Europe transjurassienne (MET) (MET) is a non-profit association based in Neuchâtel, which has been active for several years in the various regions of the Jura Arc."
image={maisonDe} 
title="Maison de l’Europe" 
link="https://maisondeleurope.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Swiss Community – Besser informierte, beratene und vernetzte Schweizerinnen und Schweizer im Ausland dank SwissCommunity, Ihrer Auslandschweizer-Organisation." 
image={swissComunity} 
title="Swiss Community" 
link="https://www.swisscommunity.org/en/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Gegründet in den 1930er Jahren, ist die Schweizerische Arbeitsgemeinschaft der Jugendverbände (SAJV) heute etabliert als Dachverband von mehr als 60 Jugendorganisationen in der Schweiz." 
image={sajv} 
title="SAJV | CSAJ" 
link="https://www.sajv.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Die ch Stiftung ist dem föderalistischen Staatsgedanken verpflichtet und setzt sich für die Verständigung zwischen den Sprachgemeinschaften und Kulturen ein." 
image={chStiftung} 
title="CH Stiftung" 
link="https://chstiftung.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Im Stapferhaus, direkt am Bahnhof Lenzburg, stehen die grossen Fragen der Gegenwart im Vordergrund: was unser Leben prägt, was das Land interessiert und was die Welt bewegt." 
image={stapferHaus} 
title="Stapfer Haus" 
link="https://stapferhaus.ch/en"></PartnerCard>,

//<PartnerCard key={uniqid()}
//text="Helvezin wird von ganz normalen Leuten gemacht. Wir alle finden die Schweiz toll. Sie ist ein Ort von hoher Lebensqualität. Aus unserer Sicht sind dafür Werte wie Konsens, Dialog & Respekt absolut zentral...." 
//image={helvezin} 
//title="Helvezin" 
//link="http://www.helvezin.ch/"></PartnerCard>, 

<PartnerCard key={uniqid()}
text="Die Stiftung StrategieDialog21 (SD21) setzt sich gesellschafts- und parteiübergreifend für eine offene, innovative, mutige und freiheitliche Schweiz ein." 
image={strategieDialog} 
title="Strategie Dialog 21" 
link="https://www.strategiedialog21.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Der Dachverband Schweizer Jugendparlamente DSJ ist das politisch neutrale Kompetenzzentrum für die politische Bildung und die politische Partizipation." 
image={DSJ} 
title="Dachverband Schweizer Jugendparlamente" 
link="https://www.dsj.ch/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Pro Helvetia
unterstützt und verbreitet Schweizer Kunst und Kultur. Durch unser globales Netzwerk erreichen wir die ganze Welt und regen die kulturelle Produktion an." 
image={prohelvetia} 
title="Pro Helvetia" 
link="https://prohelvetia.ch/en/"></PartnerCard>,

<PartnerCard key={uniqid()}
text="Der Verein Förderung junge Personen in der Gemeindepolitik will mehr junge Leute für ein politisches Amt in der Gemeinde begeistern. Er unterstützt und fördert Massnahmen." 
image={FJG} 
title="FJG" 
link="https://www.gemeindepolitik.ch/"></PartnerCard> 
]


const PartnerCards = ({limit}) => {
    const limitCards = cards.slice(0, limit)
    return (
        
        <Container>
            <BigText style={{color:PRIMARY}}>{translate("partner organisations")}</BigText>
            <OrganisationsContainer>
            {limit?
            limitCards
        :
        cards.map(element=>element)}
                
            </OrganisationsContainer>
            
        </Container>
        
    )
}

export default PartnerCards
