import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { bigShadow, PRIMARY } from '../../Assets/Styles'
import CircleIcon from './CircleIcon'
var uniqid = require('uniqid');

const Container= styled.div`
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    width: 90vw;
    border-radius: 5px;
    background: linear-gradient(to top, ${PRIMARY} 50%, white 50%);

    >img{
        border-radius:  5px 5px 0px 0px;
        height: 50%;
        width: 100%;
        object-fit: cover;
    }

    >div{
        display: flex;
        background: ${PRIMARY};
        margin-bottom:20px;
        border-radius: 0 0 5px 5px;
        flex-direction: column;
        padding:  5%;
        text-align: left;
        color: white;
        align-items: flex-start !important;
        justify-content: space-between !important;
        height: auto;
        >h3{
            color: white;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
        >p{
            color: white;
        }
        >a{
            align-self: flex-end;
            justify-self: flex-end;
        }
    }

    

    @media (orientation: landscape) and (max-height: 500px), (orientation: portrait) and (min-width: 768px){
        height: 26rem !important;
        width: 17rem !important;
    }

    @media (orientation: landscape) and (min-width: 768px){
        height: 30rem;
        width: fit-content !important;
        justify-self: stretch;
        >div{
            >p{
                line-height: 1.3rem;
                height: 6.5rem;
                overflow: hidden;
                color: white;
                
            }
            >a>div{
            
            transform: scale(0.9);
            transition: 0.3s;
        }
        }
    }

    @media (orientation: portrait) and (min-width: 768px){
        height: 30rem;
        width: 43vw !important;
        justify-content: center;
        >div{
            >p{
                line-height: 1.3rem;
                height: 6.5rem;
                overflow: hidden;
                color: white;
                
            }
            >a>div{
            position: absolute;
            bottom: 15px;
            right: 15px;
            transform: scale(0.9);
        }
        }

    }



    `
const PartnerCard = ({image, title, text, link}) => {
    
    return (
        <Container>
            <img src={image}></img>
            <div className="partner-card-description">
                <h3>{title}</h3>
                <p>{text}</p>
                <a href={link}><CircleIcon style={{alignSelf:"flex-end"}} color="white"><FontAwesomeIcon style={{alignSelf:"flex-end", color:PRIMARY}} icon={faChevronRight}></FontAwesomeIcon></CircleIcon></a>
            </div>
        </Container>
    )
}

export default PartnerCard
