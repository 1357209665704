import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '../../Assets/Styles';
const Container = styled.div`
@keyframes lds-circle {
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: ${PRIMARY};
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
`
function Loading() {
    
    return (
        <Container style={{ top: 0, display: "flex", height: "100vh", width: "100vw", padding: "2.5vh", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
    <div className="lds-circle"><div></div></div>
    </Container>
    );
}

export default Loading;