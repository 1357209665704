import de from "./de-DE"
import it from "./it-IT"
import ro from "./ro-CH"
import fr from "./fr-CA"


export default {
    ...de,
    ...it,
    ...ro,
    ...fr
}

