import { LOCALES } from "../locales"

export default {
    [LOCALES.FRENCH]: {

        //general - not done 
        "share": "Partager",
        "coming soon": "Bientôt disponible",
        "no email": "Pas d'email",
        "language": "Langue",
        "preview": "Aperçu",
        "already logged in": "Déjà connecté",
        "log in failed": "Échec de la connexion",
        "try again": "Essayez à nouveau",

        //orgaisation name
        "logo": "Nouvelle Société Helvétique",
        "switzerland": "Suisse",
        "nhg switzerland": "NSH Suisse",


        //buttons
        "read more": "Lire plus",
        "click here": "Cliquez ici",
        "file selected": "Fichier sélectionné",
        "no file selected": "Aucun fichier sélectionné",


        //menu
        "regions": "Régions",
        "events": "Evénements",
        "democracy": "Démocratie",
        "archive": "Actualité et archives",
        "Who are we": "Qui sommes-nous",
        "contact": "Contact",
        "upload": "Télécharger",
        "post": "Article",
        "my uploads": "Mes téléchargements",
        "upload organisation's profile": "Modifier le profil de l'organisation",
        "log out": "Déconnexion",
        "successfully logged out": "Déconnexion réussie",


        //footer - not done 
        "url copied": "URL copiée",
        /* "phone number": "Numéro de téléphone", */
        "phone number": "Téléphone",
        "impressum": "Mentions légales ",
        "datenschutz": "Protection des données",
        "AGB": "Conditions générales",
        "terms and services": "Infos",



        //log in
        "log in": "Se connecter",
        "password": "Mot de passe",


        //upload - not done 
        "team": "Équipe",
        "address": "Adresse",
        "add team member": "Ajouter un membre de l'équipe",
        "header": "Titre",
        "author's Name": "Nom de l'auteur",
        "organisation's Name": "Nom de l'organisation",
        "type your text": "Tapez votre texte",
        "select file": "Sélectionnez un fichier",
        "select image": "Sélectionnez une image",
        "choose Date": "Choisir la date",
        "start Time": "Heure de départ",
        "end Time": "Heure de fin",
        "location": "Lieu",
        "team Members": "Membres de l'équipe",
        "surname": "Nom de famille",
        "position": "Position",
        "name": "Prénom",
        "upload event": "Télécharger événement",
        "please fill out the input": "Veuillez remplir le formulaire",


        //organisation upload - not done
/*         "organisation upload" : "Modifica il profilo dell'organizzazione",
 */        "If you click on this button the team member will be deleted permanently": "Si vous cliquez sur ce bouton, le membre de l'équipe sera supprimé définitivement.",
        "description": "Description",
        "add archive item": "Ajouter un élément d'archive",
        "add team member": "Ajouter un membre de l'équipe",
        "You do not have any Team Members added yet": "Aucun membre de votre équipe n'a encore été ajouté.",
        "add": "Ajouter",
        "You do not have any archive articles  added yet": "Vous n'avez pas encore d'articles d'archives ajoutés",
        "edit": "Modifier",
        "delete": "Effacer",


        //blog upload
        "If you click this button you will be redirected to a page where you will be able to edit your article and repost it.": "Si vous cliquez sur ce bouton, vous serez redirigé vers une page où vous pourrez modifier votre article et le reposter.",
        "blog upload": "Télécharger article du blogue",
        "edit blog": "Modifier un article de blog",
        "If you click on this you will delete the news permannetly so be careful.": "Si vous cliquez sur ce bouton, vous supprimerez définitivement la nouvelle, alors faites attention.",


        //home
        "partner organisations": "Organisations Partenaires",
        "support NHG": "Soutenir la NSH",
        "support NHG text": "Vous souhaitez soutenir la NHG, par exemple en apportant une contribution financière ou en participant à un éventuel projet ? Ou vous souhaitez devenir membre ? Veuillez contacter notre responsable des membres et des finances, Franz Bieri, au ",
        "all partners": "Tous les partenaires",
        //slide component
        "events in your region": "Événements dans votre région",
        //card component
        "losung": "Nous sommes la plus ancienne organisation civique de Suisse. Nous sommes attachés à la démocratie et à la cohésion.",
        //logo container
        "logo container header": "Tout ce qu'il faut savoir sur la démocratie",
        "logo container subHeader": "Nous sommes engagés en faveur de la démocratie depuis 1914",
        //elements headings - not done 
        "our": "Notre",
        "price": "Prix",

        //subheadings - not done 
        "democracy podcast subheading": "Comment pouvons-nous améliorer la démocratie aujourd'hui ? Un podcast sur le changement pratique, avec des invités des institutions publiques et de la société civile.",
        "democracy wiki subheading": "Tous les termes relatifs à la démocratie. Ce que vous devez savoir, connaître et comprendre",
        "our archive subheading": "Retrouvez ici tous les documents produits par la NSH au cours de son histoire.",
        "democracy price subheading": "La NSH est la porteuse du Democracy Award Switzerland et le décerne depuis 2013. Les conditions de participation et les délais d'inscription se trouvent ici : demokratiepreis.ch.",
        "democracy blog subheading": "Le blog de la démocratie est le fil d'actualité de la NSH. Renseignez-vous sur les sujets les plus récents.",
        "campus democracy": "Le Campus de la démocratie est la plate-forme nationale pour l'éducation et la participation politiques. Pour en savoir plus, cliquez ici: campusdemocracy.ch",

        //democracy wiki dictionary
        "wiki-democracy": "La croyance en la liberté et l'égalité entre les personnes, ou un système de gouvernement fondé sur cette croyance, dans lequel le pouvoir est détenu soit par des représentants élus, soit directement par le peuple lui-même .",
        "hello": "Bounjour",
        "edit": "Modifier",


        //cantons - not done 
        "all regions": "Toutes les régions",
        "president": "Président*e",
        "presidentFemale": "Présidente",
        "presidentMale": "Président",
        "no website": "Pas de site web",
        "no location": "Pas d'emplacement",
        "visit website": "Visiter le site web",
        "Position": "Position",
        "Reihenfolge": "Ordre",


        //archiv - not done 
        "publications NHG": "Publications NSH",
        "nhg in the media": "NSH dans les médias",
        "grundsatzthemen": "Questions fondamentales",
        "fotogalerie": "Galerie de photos",
        "100 years nhg": "100 ans NSH",
        "250 years nhg": "250 ans NSH",
        "publications from 2007": "Publications de 2007",
        "delegiertenversammlung": "Assemblée des déléguées",
        "go to pdf": "Prospectus",

        //my account - not done
        "save changes": "Sauvegarder les modifications",


        //kontakt - not done
        "subject": "Sujet",
        "send message": "Envoyer le message",
        "content": "Contenu",

        //about us - not done
        "goals of nhg": "Objectifs de la NSH",
        "goals of nhg subheading": "La NSH vise à renforcer les institutions démocratiques et la  cohésion du pays en abordant avec vigilance les questions d'actualité et en contribuant à y répondre.",
        "first goal": "Elle défend le fonctionnement des institutions démocratiques.",
        "second goal": "Elle favorise la compréhension entre les différents groupes de population.",
        "third goal": "Elle promeut l'identité culturelle de la Suisse.",
        "fourth goal": "Elle prône la solidarité et l'ouverture au monde et soutient la Confédération dans l'accomplissement de ses obligations envers l'étranger imposées par la Constitution.",

        "herkunft": "Origine",
        "herkunft text": `La Nouvelle Société Helvétique (NSH) a été fondée en 1914, peu avant le début de la Première Guerre mondiale, à un moment où les divergences d'opinion entre la Suisse alémanique et la Suisse romande menaçaient la paix intérieure du pays. Elle se voyait comme le successeur de la Société helvétique qui, de 1761 à 1858, avait contribué au renforcement de la Confédération et à la formation de l'État fédéral de 1848.

Elle a fusionné avec les Rencontres Suisses (RS) pour former la société commune "Neue Helvetische Gesellschaft - Treffpunkt Schweiz" (Nouvelle Société Helvétique - Point de rencontre Suisse) le 1er janvier 2007. Les RS ont été fondées en 1945 dans le but de renforcer la cohésion du pays dans une période turbulente pour l'Europe. L'une des principales préoccupations de RS était la compréhension entre les partenaires sociaux.

L'assemblée ordinaire des délégués 2018 a décidé de raccourcir le nom en Nouvelle Société Helvétique.

Traduit avec www.DeepL.com/Translator (version gratuite)`,

        "vorstand": "Conseil d'administration",
        "president of groups": "Présidentes et présidents des groupes",
        "annual raport": "Rapports annuels",
        "sekretariat": "Secrétariat, comptabilité",
        "link herkunft": "'Société helvétique' dans le Dictionnaire historique de la Suisse"





    }
}