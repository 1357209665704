import React, { useState, useEffect } from 'react'
import InputMUI from '../../Components/GeneralUse/Input/InputMUI'
// import {Flex} from "../Assets/Styles/Flex"
import { useAuth } from '../../Contexts/AuthContext'
import {database} from "../../firebase/config"
import FormQuill from "../../Components/GeneralUse/Input/FormQuill"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Banner from '../../Components/GeneralUse/Banner'
import { useLocation } from 'react-router'
import FileInput from '../../Components/GeneralUse/Input/FileInput'
import { postDatabase } from '../../firebase/DatabaseFunctions'
import BlogComponent from "../../Components/GeneralUse/Blog/BlogComponent"
import { basicValidation } from '../../Assets/UtilityFunctions'
import { bigShadow, BigText, MediumText, PRIMARY } from '../../Assets/Styles'
import translate from '../../i18n/messages/translate'
import styled from 'styled-components'

const PostButton = styled.button`
width: 90vw;
height: 7vh;
background: ${PRIMARY};
border: none;
font-size: 1.4rem;
bottom: 5vh;
color: white;
box-shadow: ${bigShadow};
border-radius: 10px;
font-weight: 700;
margin-top: 10vh;
transition: 0.2s;
:hover{
     transform: scale(1.1)
 }

 @media (orientation: landscape) and (min-width: 768px){
    font-size: 2rem;
    width: 70vw;
    height: 7%;
 }
 @media (min-width: 600px) and (orientation: portrait){
    font-size: 2rem;
    width: 70vw;
    height: 7%;
    position: absolute;
    bottom: -15vh;
 }
`

const BlogUpload = () => {
    //using location we can get props from the link that sent us to this page
    const location = useLocation()
    const { editText, editHeader, editAuthor, editOrganisation, editImage, editId } = location.state||{editText: null,editHeader :null, editAuthor:null, editOrganisation:null, editImage:null, editId:null}

    const {currentUser, currentUserInfo} =useAuth()
    const [loading, setLoading] = useState(false)
    //almost each state variable is assigned firstly the edited version and if that is false the "", each variable has an error variable assigned
    const [header, setHeader] = useState(editHeader||"")
    const [headerError, setHeaderError] = useState(false)

    const [author, setAuthor] = useState(editAuthor||"")
    const [authorError, setAuthorError] = useState(false)

    const [organisation, setOrganisation] = useState(editOrganisation||"")
    const [organisationError, setOrganisationError] = useState(false)

    const [text, setText] = useState(editText||"")
    const [textError, setTextError] = useState(false)

    const dateTime = new Date()
    const [imageUrl, setImageUrl] = useState(editImage||"")
    const [imageUrlError, setImageUrlError] = useState(false)

    const [post, setPost] = useState(false)
    const [startHandleImages, setStartHandleImages] = useState(false)

    
    const [succesful, setSuccesful] = useState(false)
    const [succesfulEdit, setSuccesfulEdit] = useState(false)
    const [error, setError]  = useState(false)
    const article = {
        imageUrl, 
        header, 
        author, 
        organisation, 
        dateTime:new Date(), 
        text, uid: currentUser.uid, cantonId: currentUserInfo.cantonId, createdAt:new Date()}
    const allStateAndErrors = [
        {value: header, setValue: setHeader, setError: setHeaderError},
        {value: imageUrl, setValue: setImageUrl, setError: setImageUrlError},
        {value: author, setValue: setAuthor, setError: setAuthorError}
    ]

const submitArticle=()=>{
    postDatabase("blogs", article)
    .then(() => {
        setLoading(false)
        setSuccesful(true)
        clearAll()
    })
    .catch((error) => {
        setLoading(false)
        setError(true)
        clearAll()
        console.error("Error updating document: ", error);
    });
}

    const updateArticle=()=>{
        database.collection("blogs").doc(editId).update({
                imageUrl: imageUrl,
                header: header,
                author: author,
                organisation: organisation,
                dateTime: new Date(),
                text: text,
                visibility:"public"
        })
        .then(() => {
            setLoading(false)
            setSuccesfulEdit(true)
             clearAll()
             clearAll()

        })
        .catch((error) => {
            // The document probably doesn't exist.
            setLoading(false)

            setError(true)
            console.log(error)
        });
    }

    const clearAll = () =>{
        setHeader("")
        setAuthor("")
        setOrganisation("")
        setText("")
        setImageUrl("")
        setHeaderError(false)
        setAuthorError(false)
        setOrganisationError(false)
        setTextError(false)
        setImageUrlError(false)
    }

    //we post the news to the database only after receving confirmation that the images were processes to data:url (if post  is true)
    useEffect(() => {
    //if there is no location state, meaning the article is new, we post it to the database
    if(post&&!basicValidation(allStateAndErrors)){
        setLoading(true)

        if (!location.state){
           submitArticle()
        }

    //if there is a location state, meaning the article is not new, we update the existing document in the database
        else  {
            updateArticle()
        }
    }else{
        setPost(false)
        setStartHandleImages(false)
    }
        
}, [post])





    return (
        <>
        <Banner loading={loading} trigger={succesful} setTrigger={setSuccesful} ><FontAwesomeIcon color="#0587FF" icon={faCheck}></FontAwesomeIcon></Banner>
        <Banner loading={loading} trigger={succesfulEdit} setTrigger={setSuccesfulEdit} url="/upload/uploadBlogs"><FontAwesomeIcon color="#0587FF" icon={faCheck}></FontAwesomeIcon></Banner>
        <Banner trigger={error} setTrigger={setError} ><MediumText>Try Again, an error occured</MediumText><FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon></Banner>
        <FormQuill startHandleImages={startHandleImages} setStartHandleImages={setStartHandleImages} textError={textError} setTextError={setTextError} text={text} setPost={setPost} setText={setText} name={editText?translate("edit blog"):translate("blog upload")}>
            <FileInput error={imageUrlError} setError={setImageUrlError} imageUrl={imageUrl} setImageUrl={setImageUrl}></FileInput>
            <InputMUI error={headerError} setError={setHeaderError} value={header} setValue={setHeader} label={translate("header")}></InputMUI>
            <InputMUI error={authorError} setError={setAuthorError} value={author} setValue={setAuthor} label={translate("author's Name")}></InputMUI>
            {/* <InputMUI error={organisationError} setError={setOrganisationError} value={organisation} setValue={setOrganisation} label={translate("organisation's Name")}></InputMUI> */}
        </FormQuill>
        <BigText>{translate("preview")}</BigText>      

        {imageUrl||header||author||text?<BlogComponent
            image={imageUrl} 
            header={header}
            author={author}
            date={dateTime}
            text={text}></BlogComponent>:<BigText>...</BigText>}
        
        </>
                    
                
        
    )
}

export default BlogUpload
