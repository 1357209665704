import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import { useLayoutEffect } from 'react'
import styled from 'styled-components'
import { PRIMARY } from '../../Assets/Styles'
const VideoContainer = styled.div`
width: 100%;
height: 45%;
margin: 0;
display: flex;
justify-content: center;
align-items: center;
position: relative;
>video{
    object-fit: cover;
      width:100%;
      height:100%;
      .controls{
            opacity: 0;
      }
}
>#play-button{
position: absolute;
height: 5rem;
background: ${PRIMARY};
border-radius: 50%;
width: 5rem;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
pointer-events: all;
z-index: 344;
transition: opacity 0.3s ease;
opacity: ${props=>props.buttonVisisble? "1":"0"};
>svg{
    width: 40%;
    height: 40%;
    color: white;
    padding-left: 5%;
    &.pause-icon{
        padding-left: 0;
    }
}
}

@media (orientation: landscape) and (min-width: 768px){
    width: 50%;
    height: 100%;
}

@media (orientation: portrait) and (min-width: 768px){
    width: 50%;
    height: 100%;
    
}

@media (orientation: landscape) and (max-height: 500px){
    width: 50%;
    height: 100%;
    }
`
 
let videoPlayer = {}
const pauseIcon = <svg className="pause-icon" width="85" height="103" viewBox="0 0 85 103" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="103" fill="white"/>
<rect x="57" width="28" height="103" fill="white"/>
</svg>

const Video = (props) => {
    const [playButtonVisible, setPlayButtonVisible] = useState(true)
    const [play, setPlay] = useState(false)

    //function responding for pausing and starting th evideo using
    function playPauseMedia(media) {
        if(media.paused) {
          media.play();
          setPlay(true)
          setTimeout(()=>{setPlayButtonVisible(false)}, 1000)
        } else {
          media.pause();
          setPlay(false)
          setPlayButtonVisible(true)
        }
      }


      useLayoutEffect(()=>{
        videoPlayer = document.getElementsByClassName("video-player")[0]
    }, [])


    const onMouseLeave = () => {
        if(!videoPlayer.paused)setPlayButtonVisible(false)
    }

    const onMouseEnter = () =>{
        setPlayButtonVisible(true)
    }

    return (
        <VideoContainer buttonVisisble={playButtonVisible} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <div onClick={()=>playPauseMedia(videoPlayer)} id="play-button">
                    {play?pauseIcon:<FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>}</div>
                <video className="video-player" poster={props.poster}>
                    <source src={props.src} type="video/mp4"></source>
                    <source src={props.src} type="video/ogg"></source>
                </video>
            </VideoContainer>
    )
}

export default Video
