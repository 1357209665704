import React from 'react'
import styled from 'styled-components'
import SlideDown from '../Components/GeneralUse/SlideDown'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { bigShadow, MediumText, PRIMARY } from '../Assets/Styles'
import translate from '../i18n/messages/translate'
import CenteredText from '../Components/UniqueComponents/CenteredText'
import { Collapse } from '@material-ui/core'
import Loading from '../Components/GeneralUse/Loading'
import { useLanguage } from '../Contexts/LanguageContext'
import { LOCALES } from '../i18n'
import {database} from '../firebase/config'
const Container= styled.div`
padding: 5%;
display:flex;
flex-wrap: wrap;
justify-content: space-between;`


const translateDinamically = (language, object) => {
    if(language === LOCALES.GERMAN){
        return {
            name:object.nameDE,
            description:object.descriptionDE
        }
    }
    else if(language === LOCALES.FRENCH){
        return {
            name:object.nameFR,
            description:object.descriptionFR
        }
    }
    else if(language === LOCALES.ROMANSH){
        return {
            name:object.nameRO,
            description:object.descriptionRO
        }
    }
    else if(language === LOCALES.ITALIAN){
        return {
            name:object.nameIT,
            description:object.descriptionIT
        }
    }
   
}

const DemokratieWiki = () => {
    const [loading, setLoading] = useState(true)
    const [documents, setDocuments] = useState([])
    const {language, setLanguage} = useLanguage()

const getAllEntries = () => {
    let documents =[]
    database.collection("wiki").get()
    .then((querySnapshot) => {    
        querySnapshot.forEach((doc) => {
            if (doc.data()){documents.push({...doc.data(), id:doc.id})}
        });
        setDocuments(previous=>documents) 
        setLoading(prevoius=>false)
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    })}



    useEffect(()=>{
        getAllEntries("wiki", setDocuments, setLoading)
    },[])


    return (
        loading?
        <Loading></Loading>
        :
        <Container>
            
            {documents.map((document, index)=>{
                return (
                    <DictionarySlideDown key={index} header={translateDinamically(language, document).name}>
                        {translateDinamically(language, document).description}
                    </DictionarySlideDown>
                )
            })}
        </Container>
    )
}

export default DemokratieWiki





const DictionaryContainer = styled.div`
width: 100%;

@media (orientation: landscape) and (min-width: 768px){
    width: 30%;
    >div>.slide-content{
        width: 27%;
    }
}
`

const DictionaryContentContainer = styled.div`
background-color: white;
padding: 5%;
`

const Text = styled.div`
transition: all 0.3s;
overflow: hidden;
background-color: ${PRIMARY};
height: fit-content;
width: 100%;
margin-top: 30px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 1rem;
position: relative;



    >div{
    position: absolute;
    top: 0;
    right: 0;
    border-top: 25px solid ${PRIMARY};
    border-left: 25px solid transparent;
    width: 10%;
    border-top-right-radius: 5px;

    
    }
>p{
    cursor: pointer;
    position: relative;
    z-index: 3;
    transition: 0.3s;
    font-size: 1.5rem;
    transform: ${props=>props.open? "rotate(90deg)":"rotate(45deg)"};
}
    >h3{
    color: white;
    z-index: 9;
    width: 70%;
    padding: 1rem 0;
    text-align: left;
    background-color: ${PRIMARY};
    position: relative;
}
`



const DictionarySlideDown = ({header, children}) => {
    const [height, setHeight] = useState(0)
    const [open, setOpen] = useState(false)
    const container = useRef(0)


    useEffect(()=>{
        setHeight(container.current.clientHeight)
    }, [])


    return (
        <DictionaryContainer>
        <Text open={open} onClick={()=>setOpen(previous=>!previous)} className="slide-header">
            <MediumText>{header}</MediumText>
            {/* <p onClick={()=>setOpen(previous=>!previous)}>×</p> */}
        </Text>

        <Collapse in={open}>
            <DictionaryContentContainer  >
                <div ref={container} className="slide-content">
                {children}
                </div>
            </DictionaryContentContainer>
        </Collapse>
        </DictionaryContainer>
    )
}






