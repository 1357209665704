import React from 'react'
import styled from 'styled-components'
import image from "../../Assets/Images/PDF.png"
import { PRIMARY } from '../../Assets/Styles'

const Container = styled.div`
transition: 0.2s;
width: 90vw;
height: 18vh;
box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
   -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
background: rgb(242, 242, 247);
display: flex;
margin: auto;
padding: 1vh;
margin-bottom: 20px;
position: relative;


img{
    height: 100%;
    width: auto;
    margin-right: 20px;
}

>div{
    overflow: hidden;
    >p{
        text-align: left;
        margin-left:10px;
        word-wrap: break-word;
        overflow: hidden;
        color: black !important
    }
}

:hover{
    transform: scale(1.1);
}
@media (orientation: landscape) and (min-width: 768px){
width: 20vw;
height: 12vh;
}
@media (orientation: portrait) and (min-width: 768px){
    width: 27vw;
    height: 13vh;
}

@media (orientation: landscape) and (max-height: 500px){
    width: 33vw;
    height: 30vh;
}


`

const PDF = ({ link, name }) => {
    return (
        <a href={link}>
            <Container>
                <img src={image}></img>
                <div>
                    <p>{name}</p>
                </div>
            </Container>
        </a>
    )
}

export default PDF
