import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { database } from '../../firebase/config'
import {useAuth} from "../../Contexts/AuthContext"
import BlogComponentPreview from '../../Components/GeneralUse/Blog/BlogComponentPreview'
import { Container, SlicedContainer } from '../../Assets/Styles'
import EventCardPreview from '../../Components/GeneralUse/Event/EventCardPreview'
import Loading from '../../Components/GeneralUse/Loading'
import {sliceArray} from "../../Assets/UtilityFunctions"
import translate from '../../i18n/messages/translate'

const EventsContainer=styled.div`
display: flex;
flex-wrap: wrap;
width: 100%;
padding-top: 5%;
`

const MyUploads = () => {
    const [selected, setSelected] = useState("blogs")
    const {currentUser, currentUserInfo} = useAuth()
    const [blogs, setBlogs] = useState([])
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)
    const {firstDiv, secondDiv} = sliceArray(blogs, BlogComponentPreview, true)


    //fetching the resources posted by the user
    const fetchResources = (collection, setState)=>{
    const docs = []

    database.collection(collection).where("cantonId", "==", currentUserInfo.cantonId)
    .get()
    .then((querySnapshot) => {    
        querySnapshot.forEach((doc) => {
            if (doc.data()){docs.push({...doc.data(), id: doc.id})}
        });
        setState(docs)
        setLoading(false)
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    })
    }

    useEffect(()=>{
        if(currentUser.uid){
            fetchResources("blogs", setBlogs)
            fetchResources("events", setEvents)
        }
    }, [currentUserInfo])

    useEffect(()=>{
        console.log(events)
    },[events])

    
    return (
        loading?
        <Loading></Loading>
        :
        <div>
            <Container selected={selected} idOne="blogs" idTwo="events">
                <div onClick={()=>{setSelected("blogs")}} id="blogs"><h3>{translate("post")}</h3></div>
                <div onClick={()=>{setSelected("events")}} id="events"><h3>{translate("events")}</h3></div>
            </Container>
            {selected=="blogs"?
            <SlicedContainer>
                <div>{firstDiv}</div>
                <div>{secondDiv}</div>
            </SlicedContainer>
                :
            <EventsContainer>{events.map(element=>    
                <EventCardPreview 
                edit
                id = {element.id}
                    date={element.date.toDate()}
                    startTime={element.startTime.toDate()}
                    endTime={element.endTime.toDate()}
                    text = {element.text}
                    image={element.image} 
                    header={element.header} 
                    location={element.location} 
                    author={element.author}>
                </EventCardPreview>
        )}</EventsContainer>}
        </div>
    )
}

export default MyUploads
