import React, { useEffect, useState } from 'react'
import { Route, useRouteMatch } from 'react-router'
import Cantons from '../UniqueComponents/Cantons'
import CantonProfile from "../GeneralUse/Canton/CantonProfile"
import { database } from '../../firebase/config'
import Loading from '../GeneralUse/Loading'

/* Added this class to make absolute redirects possible (needed in custom paths further down) */
class AbsoluteRedirect extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        window.open(this.props.to, '_blank');
    }
    render() {
        return null
    }
}

const RegionenRouterComponent = () => {
    const [cantons, setCantons] = useState([])
    const [loading, setLoading] = useState(true)
    const match = useRouteMatch()


    useEffect(() => {
        let documents = []
        database.collection("cantonOrganisations")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.data()) { documents.push({ ...doc.data() }) }
                });
                setCantons(previous => documents)
                setLoading(prevoius => false)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            })
    }, [])

    return (

        loading ?
            <Loading></Loading>
            :
            <>
                {cantons.map(element =>
                    <Route path={match.url + "/" + element.id}>
                        <CantonProfile infoObject={element}></CantonProfile>
                    </Route>)}

                <Route exact path={match.url}>
                    <Cantons infoObject={cantons}></Cantons>
                </Route>

                {/* Custom Paths added to link some regions to their own websites */}
                <Route path="/region/bern">
                    <AbsoluteRedirect to="https://www.nhg-bern.ch/" />
                </Route>
                <Route path="/region/zuerich_">
                    <AbsoluteRedirect to="https://www.nhg-zuerich.ch/" />
                </Route>
                <Route path="/region/schaffhausen">
                    <AbsoluteRedirect to="https://www.nhg-sh.ch/" />
                </Route>
                <Route path="/region/winterthur">
                <AbsoluteRedirect to="https://www.nhg-winterthur.ch/" />
            </Route>

            </>
    )
}
export default RegionenRouterComponent

/* before: (match.url = /regionen)
                {cantons.map(element =>
                    <Route path={match.url + "/" + element.id}>
                        <CantonProfile infoObject={element}></CantonProfile>
                    </Route>)}

                <Route exact path={match.url}>
                    <Cantons infoObject={cantons}></Cantons>
                </Route> */