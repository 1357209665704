import { LOCALES } from "../locales"

export default {
    [LOCALES.ITALIAN]: {

        //general - not done 
        "share": "Condividere",
        "coming soon": "Presto in arrivo",
        "no email":"Nessuna e-mail",
        "language":"Lingua",
        "preview": "Anteprima",
        "already logged in" : "Già loggato",
        "log in failed" : "Accesso fallito",
        "try again": "Prova di nuovo",

        //orgaisation name
        "logo": "Nuova Società Helvetica",
        "switzerland": "Svizzera",
        "nhg switzerland": "NSE Svizzera",


        //buttons
        "read more": "Leggi di più",
        "click here": "Clicca qui",
        "file selected":"File selezionato",
        "no file selected":"Nessun file selezionato",


        //menu
        "regions": "Regioni",
        "events": "Eventi",
        "democracy": "Demokratie",
        "archive": "Notizie e archivio",
        "Who are we": "Chi siamo noi",
        "contact": "Contatta",
        "upload": "Caricare",
        "post": "Post",
        "my uploads": "I miei caricamenti",
        "upload organisation's profile": "Modifica il profilo dell'organizzazione",
        "log out": "Esci",
        "successfully logged out":"Logout effettuato con successo",


        //footer - not done 
        "url copied": "URL copiato",
        /* "phone number": "Numero di telefono", */
        "phone number": "Telefono",
        "impressum": "Informazione legale",
        "datenschutz": "Protezione dei dati ",
        "AGB": "Condizioni generali",
        "terms and services":"Infos",

        //log in
        "log in": "Accedi",
        "password": "Password",

        //upload - not done 
        "team":"Squadra",
        "address":"Indirizzo",
        "header": "Titulo",
        "author's Name": "Nome dell'autore / della autrice",
        "organisation's Name": "Nome dell'organizzazione",
        "type your text": "Inserisca il suo testo",
        "select file": "Selezionare un file",
        "select image": "Selezionare una immagine",
        "choose Date": "Scegliere la data",
        "start Time": "Ora di inizio",
        "end Time": "Ora di fine",
        "location": "Luogo",
        "team members": "Membri del team",
        "surname": "Cognome",
        "position": "Posizione",
        "name": "Nome",
        "upload event":"Caricare evento",
        "please fill out the input":"Si prega di compilare l'input ",


        //organisation upload - not done
        "If you click on this button the team member will be deleted permanently": "Se clicchi su questo pulsante il membro del team sarà cancellato permanentemente",
        "description":"Descrizione",
        "add team member":"Aggiungi un membro del team",
        "add":"Aggiungi",
        "add archive item":"Aggiungi un elemento dell'archivio",
        "You do not have any Team Members added yet":"Non hai ancora aggiunto nessun membro del team.",
        "You do not have any archive articles  added yet":"Non hai ancora aggiunto articoli d'archivio",
        "edit": "Modificare",
        "delete": "Spegnere",


        //news upload
        "If you click on this you will delete the article permannetly so be careful.": "Se cliccate su questo cancellerete la notizia in modo permanente, quindi fate attenzione.",
        //blog upload
        "If you click this button you will be redirected to a page where you will be able to edit your article and repost it.": "Se fai clic su questo pulsante sarai reindirizzato a una pagina dove potrai modificare il tuo articolo e ripubblicarlo.",
        "blog upload":"Carica l'articolo del blog",
        "edit blog":"Modifica l'articolo del blog",

        //home
        "partner organisations": "Organizzazioni Partner",
        "support NHG": "Sostenere la NSH",
        "support NHG text": "Vuoi sostenere NHG, per esempio con un contributo finanziario o partecipando a un eventuale progetto? O vuoi diventare un membro? Si prega di contattare il nostro responsabile per i membri e le finanze, Franz Bieri, a ",
        "all partners":"Tutti i partner",
        //slide component
        "events in your region": "Eventi nella tua regione",
        //card component
        "losung": "Siamo la più antica organizzazione civica della Svizzera. Siamo impegnati nella democrazia e nella coesione.",
        //logo container
        "logo container header": "Tutto quello che c'è da sapere sulla democrazia",
        "logo container subHeader": "Siamo impegnati nella democrazia dal 1914",
        //elements headings - not done 
        "our": "Nostro / nostra",
        "price": "Prezzo",
        



        //subheadings - not done 
        "democracy podcast subheading": "Come possiamo migliorare la democrazia oggi? Un podcast sul cambiamento pratico, con ospiti delle istituzioni pubbliche e della società civile.",
        "democracy wiki subheading": "Tutti i termini intorno alla democrazia. Quello che dovreste sapere, conoscere e capire.",
        "our archive subheading": "Trova qui tutti i documenti prodotti da NSE nella sua storia.",
        "democracy price subheading": "La NSE è la portatrice del Premio Democrazia Svizzera e lo assegna dal 2013. Le condizioni di partecipazione e i termini di iscrizione si trovano qui: demokratiepreis.ch",
        "democracy blog subheading": "Il Democracy Blog è il news feed di NSE. Leggete gli ultimi argomenti.",
        "campus democracy": "Il Democracy Campus è la piattaforma nazionale per l'educazione e la partecipazione politica. Scopri di più qui: campusdemokratie.ch",

        //democracy wiki dictionary
        "wiki-democracy": "La credenza nella libertà e nell'uguaglianza delle persone, o un sistema di governo basato su questa credenza, in cui il potere è esercitato o da rappresentanti eletti o direttamente dal popolo stesso.",
        "hello": "Ciao",
        "edit": "Modificare",

        //cantons - not done 
        "all regions":"Tutte le regioni",
        "president": "Presidente*ssa",
        "presidentFemale": "Presidentessa",
        "presidentMale": "Presidente",
        "no website": "Nessun sito web",
        "no location":"Nessuna postazione",
        "visit website":"Visita il sito web",
        "Position": "Posizione",
        "Reihenfolge": "Ordine",

        //archiv - not done 
        "publications NHG": "Pubblicazioni NSE",
        "nhg in the media": "NSE nei media",
        "grundsatzthemen": "Questioni fondamentali",
        "fotogalerie": "Galleria foto",
        "100 years nhg": "100 anni NSE",
        "250 years nhg": "250 anni NSE",
        "publications from 2007": "Pubblicazioni dal 2007",
        "delegiertenversammlung": "Assemblea dei delegati",
        "go to pdf": "Prospetto",
        

        //my account - not done
        "save changes": "Salvare le modifiche",

        //kontakt
        "subject": "Soggetto",
        "send message": "Inviare messaggio",
        "content": "Contenuto",

        //about us
        "goals of nhg": "Obiettivi del NSE",
        "goals of nhg subheading": "NSE mira a rafforzare le istituzioni democratiche e la coesione del paese, affrontando in modo vigile le questioni attuali e aiutando a dare una risposta.",
        "first goal": "Si batte per il funzionamento delle istituzioni democratiche.",
        "second goal": "Promuove la comprensione tra diversi gruppi di popolazione.",
        "third goal": "Promuove l'identità culturale della Svizzera.",
        "fourth goal": "Difende la solidarietà e l'apertura al mondo e sostiene la Confederazione nell'adempimento dei suoi obblighi verso l'estero imposti dalla Costituzione.",

        "herkunft": "Origine",
        "herkunft text": `La Nuova Società Elvetica (NSE) fu fondata nel 1914, poco prima dello scoppio della prima guerra mondiale, in un momento in cui le differenze di opinione tra la Svizzera tedesca e quella francese minacciavano la pace interna del paese. Si vedeva come il successore della Società Elvetica, che dal 1761 al 1858 aveva contribuito al rafforzamento della Confederazione e alla formazione dello stato federale del 1848.

Il 1° gennaio 2007 si è fusa con Rencontres Suisses (RS) per formare la società mista "Neue Helvetische Gesellschaft - Treffpunkt Schweiz" (Nuova Società Elvetica - Punto d'incontro Svizzera), fondata nel 1945 con lo scopo di rafforzare la coesione del paese in un periodo turbolento per l'Europa. Una delle principali preoccupazioni di RS era la comprensione tra le parti sociali.

L'assemblea ordinaria dei delegati del 2018 ha deciso di abbreviare il nome in Nuova Società Elvetica.

Tradotto con www.DeepL.com/Translator (versione gratuita)`,

        "vorstand": "Consiglio di amministrazione",
        "president of groups": "Presidenti dei gruppi",
        "annual raport": "Rapporti annuali",
        "sekretariat":"Segreteria, Contabilità",
        "link herkunft":"'Società Helvetica' nel Dizionario storico della Svizzera"





    }
}