import React, { useState } from 'react'
import styled from "styled-components"
import { faMapMarkerAlt, faCalendarAlt, faPlus, faChevronLeft, faPencilAlt, faArchive, faNewspaper, faPhone, faBook, faUser, faLanguage, faGlobeEurope, faCheck, faMicrophone, faUsers, faPhoneAlt, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Images/logo.jpeg"
import { BLUE, Button, MediumText, PRIMARY } from '../../Assets/Styles';
import CircleIcon from '../GeneralUse/CircleIcon';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from "../../Contexts/AuthContext"
import { LOCALES } from '../../i18n';
import translate from '../../i18n/messages/translate';
import Banner from '../GeneralUse/Banner';

var uniqid = require('uniqid');

const Container = styled.div`
z-index: 999;
height: 10vh;
width: 100vw;
position: fixed;
top: 0;
display: flex;
background-color: white;
justify-content: space-between;
align-items: center;
padding: 0 2.5vw;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

nav{
    display:none;
}

#bookmark{
  position: fixed;
  bottom: 30px;
  right: 30px;
}

>#buttonHolder{
  display: none;
}
@media (orientation: landscape) and (min-width: 768px){
  position: fixed;

    >nav{
        width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #eb263b;
        height: 100%;
        >#buttonHolder{
  display: flex;
  margin-right: 30px;
  >a{
    margin-left: 20px;
  }
}
        }
        >#buttonHolder{
  display: flex;
  margin-right: 30px;
}
        #bookmark{
  position: static;
}


  }
@media (orientation: landscape) and (max-height: 500px){
    height: 20vh;
    }

    @media (orientation: landscape) and (min-width: 1100px){
      position: fixed;


    >nav{
        width: fit-content;}}
`
const Logo = styled.img`
width: 7.5vh;
height: 7.5vh;
position: relative;

`
const LogoContainer = styled.div`
display: flex;
align-items: center;
width: fit-content;
>div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
>div>h1{
  color: #eb263b;
  font-weight: 400;
  font-size: 0.7rem;
  transform: translateX(10px);
}
`
const MenuBtn = styled.div`
  z-index: 3;
  cursor: pointer;
  transition: all 450ms ease-in-out;
  margin-bottom: -7px;
  margin-right: 20px;
  &.closer {
      transform: translateX(10px);
  }

  @media (orientation: landscape) and (min-width: 768px){
      display: none;
  }

`

const BtnLine = styled.div`
  width: 30px;
  height: 3px;
  margin: 0 0 7px 0;
  background-color: #eb263b;
  transition: all 450ms ease-in-out;
  border-radius: 100px;
  &.closer {
    &:nth-child(1) {
      transform: rotate(45deg) translateY(14px) ;
    }
    &:nth-child(2) {
        opacity: 0;

    }
    &:nth-child(3) {
        transform: rotate(-45deg) translateY(-14px);
    }
  }
  `

const MenuOverlay = styled.div`
  box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.15);
  z-index: 999;
  position: fixed;
  top: 10vh;
  right: 0;
  background-color: white;
  height: 90vh;
  width: 80vw;
  transform: translateX(100%);
  transition: all 300ms ease-in-out;
  overflow: scroll;
  @media (orientation: landscape) and (min-width: 768px){
      display: none;
  }
  @media (orientation: landscape) and (max-height: 500px){
    top: 20%;
    height: 80vh;
  }

  
  &.show {
    background-color: white;
    transform: translateX(0%); 
  }
  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >.back-button{
      >svg{
        position:absolute;
        left: 1rem;
      }
    
    background: rgba(235, 38, 59, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }

    a {
      padding: 1.2rem;
      width: 100%;
      z-index: 10;
      font-size: 1.4rem;
      text-decoration: none;
      text-align:left;
      color: #eb263b;
      cursor: pointer;
      transition: all 150ms ease-in-out;
      display: flex;
      align-items: center;
      >svg{
        margin-right: 15px;
        width: 30px !important;
        
      }
      &:hover {
        color: black;
      } 
    }
  }
  @media (orientation: landscape) and (min-width: 768px){
    width: 25vw;
    nav {
    padding: 3rem;
    padding-top: 30px;

}
  }

  @media (orientation: portrait) and (min-width: 768px){
    width: 45vw;
  }

  @media (orientation: landscape) and (max-height: 500px){
    width: 50vw;
    nav {
    padding: 2rem;
    padding-top: 30px;
}
  }
  `
const ButtonHolder = styled.div`
padding: 10px;
margin-top: 20px;
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;

>a{
  margin-left: inherit !important;
}
`
const Background = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    top: 10vh;
    right: 0;
    background-color: transparent;
    opacity: 1;
    //attentive at the property below
    pointer-events: none;
    transition: all 0.5s ease-out;

    @media (orientation: landscape) and (min-width: 768px){
      display: none;
  }
  @media (orientation: landscape) and (max-height: 500px){
    top: 20%;
  }
  

      &.black{
      background-color: #000;
      pointer-events: all;
      opacity: 0.5;}
`
const Line = styled.div`
background-color: rgba(235, 38, 59, 0.25);
width: 80%;
height: 3px;
margin: auto;
border-radius: 50px;
margin-top: 1.5rem;
`
const DesktopLink = styled.div`
position: relative;
margin-left: 30px;
color:${PRIMARY} !important;
padding: 10px 0;
line-height: 1rem;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
transition: border 0.1s ease-in-out;
border-bottom: ${props => props.redHighlight ? "4px solid " + PRIMARY : "none"} ;
:visited{
  color:${PRIMARY} !important;
}
>div{
  max-height: calc(7rem + 150px);
  overflow: scroll;
  width: 20vw;
  left: ${props => props.right ? "-15vw" : "0"};
  top: 100%;
  background-color: ${PRIMARY};
  padding: 15px 20px 0px 20px;
  border-radius: 5px;
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  transform: scale(0) translateY(-150px);
  >a{
    color:${PRIMARY};
    margin-bottom: 15px;
    color: white;
    text-align: left;
  }
}
>.opened{
  opacity: 1;
  transform: scale(1);
  >a{
    cursor: pointer;
  }

}
`
export const inverseState = (setState) => {
  setState(previous => !previous)
}
const cantonNames = ["Aargau", "Region Basel", "Bern", "Genève", "Schaffhausen", "Vaud", "Winterthur", "Zurich", "Dev"]
// const cantonLinks =  ["https://www.nhg.ch/ag", "https://www.facebook.com/nhg.regionbasel", "https://www.nhg-bern.ch/", "https://www.nhg.ch/ge", "https://www.nhg.ch/sh", "https://www.nhg.ch/vd", "https://www.nhg-winterthur.ch", "https://www.nhg-zuerich.ch"]
const cantonLinks = ["/region/aargau", "/region/basel" , "/region/bern", "/region/geneve", "/region/schaffhausen", "/region/vaud", "/region/winterthur", "/region/zuerich", "/region/555555"]

let cantonArray = []
for (let i = 0; i < 8; i++) {
  cantonArray.push(<Link key={uniqid()} to={cantonLinks[i]}>{cantonNames[i]}</Link>)
}
function Header({ language, setLanguage }) {

  const [languageOpen, setLanguageOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [nhgOpen, setNhgOpen] = useState(false)

  //desktop state
  const [regionen, setRegionen] = useState(false)
  const { currentUser, logOut, currentUserInfo } = useAuth()
  const [openLogInOptions, setOpenLogInOPtions] = useState(false)
  const [successfulLogOut, setSuccessfulLogOut] = useState(false)

  const history = useHistory()

  const closeAll = () => {
    setIsMenuOpen(false)
    setNhgOpen(false)
    setLanguageOpen(false)
    setOpenLogInOPtions(false)
  }

  const setIconLanguage = () => {
    switch (language) {
      case (LOCALES.GERMAN): return "DE"
      case (LOCALES.FRENCH): return "FR"
      case (LOCALES.ITALIAN): return "IT"
      case (LOCALES.ROMANSH): return "RO"

    }
  }

  const handlePhoneMenuButton = () => {
    if (isMenuOpen) closeAll()
    else inverseState(setIsMenuOpen)
  }

  //function that handles log out
  const handleLogOut = async () => {
    try {
      await logOut()
      history.push("/")
      setSuccessfulLogOut(true)
      closeAll()
    } catch {
      console.log("Failed to Log out")
    }
  }

  const handleLanguage = (language) => {
    setLanguage(language)
    closeAll()
  }

  const location = useLocation().pathname
  return (
    <>

      <Container loggedIn={true}>
        <Banner trigger={successfulLogOut} setTrigger={setSuccessfulLogOut}>
          <MediumText>{translate("successfully logged out")}</MediumText>
          <FontAwesomeIcon color={BLUE} icon={faCheck}></FontAwesomeIcon>
        </Banner>
        <Link to="/"> <LogoContainer>
          <Logo src={logo}></Logo>
          <div>
            <h1>Neue <b>Helvetische</b> Gesellschaft</h1>
            <h1>Nouvelle <b>Société</b> Helvétique</h1>
            <h1>Nuova <b>Società</b> Helvetica</h1>
            <h1>Nova <b>Societad</b> Helvetica</h1>
          </div>
        </LogoContainer>
        </Link>

        <nav>
          <DesktopLink redHighlight={location.includes("blogs") || location.includes("archiv") || location.includes("aboutUs") || location.includes("kontakt")} onMouseLeave={() => setNhgOpen(false)} onMouseEnter={() => setNhgOpen(true)} >
            <Link style={{ color: PRIMARY }} to="/">NHG {translate("switzerland")}</Link>

            <div className={nhgOpen ? "opened" : ""}>
              <Link to="/blogs">{translate("democracy")} Blog</Link>
              <Link to="/demokratieWiki">{translate("democracy")} Wiki</Link>
              <Link to="/nhgPodcasts">NHG Podcasts</Link>
              <Link to="/archiv">{translate("archive")}</Link>
              <Link to="/aboutUs">{translate("Who are we")}</Link>
              <Link to="/kontakt">{translate("contact")}</Link>
              <Link to="/partnerCards">{translate("partner organisations")}</Link>
              <Link to="/support">{translate("support NHG")}</Link>
            </div>
          </DesktopLink>

          <DesktopLink redHighlight={location.includes("region")} onMouseLeave={() => setRegionen(false)} onMouseEnter={() => setRegionen(true)} >
            <Link style={{ color: PRIMARY }} to="/region">{translate("regions")}</Link>
            <div className={regionen ? "opened" : ""}>
              {cantonArray}
            </div>
          </DesktopLink>


          <DesktopLink redHighlight={location.includes("events")}>
            <Link style={{ color: PRIMARY }} to="/events">{translate("events")}</Link>
          </DesktopLink>

          <div id="buttonHolder">
            <DesktopLink right={true} redHighlight={location.includes("openUpload")} onMouseLeave={() => setLanguageOpen(false)} onMouseEnter={() => setLanguageOpen(true)} >
              <a><CircleIcon color={PRIMARY}><p>{setIconLanguage()}</p></CircleIcon></a>
              <div className={languageOpen ? "opened" : ""}>
                <a onClick={() => setLanguage(LOCALES.GERMAN)}>Deutsch</a>
                <a onClick={() => setLanguage(LOCALES.FRENCH)}>Français</a>
                <a onClick={() => setLanguage(LOCALES.ITALIAN)}>Italiano</a>
                <a onClick={() => setLanguage(LOCALES.ROMANSH)}>Rumantsch</a>
              </div>
            </DesktopLink>
            {currentUser ?
              <DesktopLink right={true} redHighlight={location.includes("openUpload")} onMouseLeave={() => setOpenLogInOPtions(false)} onMouseEnter={() => setOpenLogInOPtions(true)} >
                <Link to="/userProfile">
                  <CircleIcon color={PRIMARY}>
                    <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                  </CircleIcon>
                </Link>


                <div className={openLogInOptions ? "opened" : ""}>
                  <Link to="/upload/uploadEvent">{translate("upload")} {translate("events")}</Link>
                  <Link to="/upload/uploadBlogs">{translate("upload")} Blog {translate("post")}</Link>
                  {currentUserInfo.role == "canton-editor" ? <Link to="/upload/uploadOrganisation">{translate("upload organisation's profile")}</Link> : null}
                  {currentUserInfo.cantonId ? <Link to="/upload/myUploads">{translate("my uploads")}</Link> : null}
                  <a onClick={() => handleLogOut()} >{translate("log out")}</a>
                </div>
              </DesktopLink>
              :
              <DesktopLink right={true} onMouseLeave={() => setOpenLogInOPtions(false)} onMouseEnter={() => setOpenLogInOPtions(true)}>
                <Link to="/logIn">
                  <CircleIcon color={PRIMARY}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></CircleIcon>
                </Link>
                <div className={openLogInOptions ? "opened" : ""}>
                  <Link to="logIn">{translate("log in")}</Link>
                </div>
              </DesktopLink>}
          </div>


        </nav>

        <Background isMenuOpen={isMenuOpen} className={isMenuOpen ? "black" : ""}></Background>

        {/* MenuButton */}
        <MenuBtn className={isMenuOpen ? "closer" : ""} onClick={() => handlePhoneMenuButton()}>
          <BtnLine className={isMenuOpen ? "closer" : null} />
          <BtnLine className={isMenuOpen ? "closer" : null} />
          <BtnLine className={isMenuOpen ? "closer" : null} />
        </MenuBtn>

        {/* The primary phone menu */}
        <MenuOverlay className={isMenuOpen ? "show" : null}>
          <nav>
            <a></a>
            <a onClick={() => inverseState(setLanguageOpen)} >
              <FontAwesomeIcon icon={faGlobeEurope} />{translate("language")}
            </a>
            <a onClick={() => inverseState(setNhgOpen)} >
              <FontAwesomeIcon icon={faPlus} />NHG {translate("switzerland")}
            </a>

            <Link to="/region" onClick={() => inverseState(setIsMenuOpen)} >
              <FontAwesomeIcon icon={faMapMarkerAlt} />{translate("regions")}
            </Link>

            <Link to="/events" onClick={() => inverseState(setIsMenuOpen)} >
              <FontAwesomeIcon icon={faCalendarAlt} />
              {translate("events")}
            </Link>
            {currentUser ? <a onClick={() => inverseState(setOpenLogInOPtions)} >
              <FontAwesomeIcon icon={faUser} />
              User
            </a> : null}
          </nav>

          <Line></Line>

          <ButtonHolder >
            {currentUser ?
              <a><Button onClick={() => handleLogOut()} textColor={PRIMARY} borderColor={PRIMARY} color="#FFFFFF">{translate("log out")}</Button></a>
              :
              <Link onClick={closeAll} to="/logIn"><Button to="/logIn" textColor={PRIMARY} borderColor={PRIMARY} color="#FFFFFF">Log In</Button></Link>
            }
          </ButtonHolder>

        </MenuOverlay>


        {/* NHG Links */}
        <MenuOverlay className={nhgOpen ? "show" : ""}>
          <nav>
            <Link to="/" className="back-button" onClick={() => setNhgOpen(false)}><FontAwesomeIcon icon={faChevronLeft} />Menu</Link>
            <Link to="/blogs" onClick={() => closeAll()} ><FontAwesomeIcon icon={faPencilAlt} />{translate("democracy")} Blog</Link>
            <Link to="/demokratieWiki" onClick={() => closeAll()}><FontAwesomeIcon icon={faBook} />{translate("democracy")} Wiki</Link>
            <Link to="/nhgPodcasts" onClick={() => closeAll()}><FontAwesomeIcon icon={faMicrophone} />NHG Podcasts</Link>
            <Link to="/aboutUs" onClick={() => closeAll()}><FontAwesomeIcon icon={faUsers} />{translate("Who are we")}</Link>
            <Link to="/archiv" onClick={() => closeAll()} ><FontAwesomeIcon icon={faArchive} />{translate("archive")}</Link>
            <Link to="/kontakt" onClick={() => closeAll()}><FontAwesomeIcon icon={faPhoneAlt} />{translate("contact")}</Link>
            <Link to="/partnerCards" onClick={() => closeAll()}><FontAwesomeIcon icon={faHandshake} />{translate("partner organisations")}</Link>
            <Link to="/support" onClick={() => closeAll()}><FontAwesomeIcon icon={faHandshake} />{translate("support NHG")}</Link>
          </nav>
        </MenuOverlay>

        {/* User Options */}
        <MenuOverlay className={openLogInOptions ? "show" : ""}>
          <nav>
            <Link onClick={() => closeAll()} to="/" className="back-button" onClick={() => setOpenLogInOPtions(false)}><FontAwesomeIcon icon={faChevronLeft} />Menu</Link>
            <Link onClick={() => closeAll()} to="/upload/uploadEvent">{translate("upload")} {translate("events")}</Link>
            <Link onClick={() => closeAll()} to="/upload/uploadBlogs">{translate("upload")} Blog {translate("post")}</Link>
            {currentUserInfo.role == "canton-editor" ? <Link onClick={() => closeAll()} to="/upload/uploadOrganisation">{translate("upload organisation's profile")}</Link> : null}
            <Link onClick={() => closeAll()} to="/upload/myUploads">{translate("my uploads")}</Link>
            <a onClick={() => handleLogOut()} >{translate("log out")}</a>
          </nav>
        </MenuOverlay>

        {/*Language */}
        <MenuOverlay className={languageOpen ? "show" : ""}>
          <nav>
            <Link to="/" className="back-button" onClick={() => setLanguageOpen(false)}><FontAwesomeIcon icon={faChevronLeft} />Menu</Link>
            <Link to="/" onClick={() => handleLanguage(LOCALES.GERMAN)}>Deutsch</Link>
            <Link to="/" onClick={() => handleLanguage(LOCALES.FRENCH)} >Français</Link>
            <Link to="/" onClick={() => handleLanguage(LOCALES.ITALIAN)}>Italiano</Link>
            <Link to="/" onClick={() => handleLanguage(LOCALES.ROMANSH)}>Rumantsch</Link>
          </nav>
        </MenuOverlay>
      </Container>
    </>

  )
}

export default Header



