import React, { useState} from 'react'
import styled from "styled-components"
import {useAuth} from "../Contexts/AuthContext"
import { Link, useHistory} from "react-router-dom"
import InputMUI from '../Components/GeneralUse/Input/InputMUI'
// import {Flex} from "../Assets/Styles/Flex"
import CenteredText from "../Components/UniqueComponents/CenteredText"
import { BigText, BLUE, Button, MediumText, PRIMARY } from '../Assets/Styles'
import translate from '../i18n/messages/translate'
import Banner from '../Components/GeneralUse/Banner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimes } from '@fortawesome/free-solid-svg-icons'

export const Flex= styled.div`
width: 100vw;
    @media (min-width: 768px){
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:max-content;
    height: 100vh;
    background: linear-gradient(135deg, rgba(235, 38, 59, 0.3) 50%, white 50%);
    }
`


export const Form = styled.form`
    background: white;
    height: 100vh;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    >*{
        margin-top: 30px;
    }

    >input{
        width: 90%;
    }
    >button{
        margin-top: 15px;
    }

    >a{
        text-decoration: underline;
        color: black;
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    @media (min-width: 768px){
        position: relative;
        width: 50%;
        max-width: 60%;
        padding-top: 7%;
        height: fit-content;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;    
        border-radius: 5px;
        padding-bottom: 4%;


        >h2{
            font-size: 2.5rem;
            margin: 0;
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            color: black;
            height: 20%;
            width: 100%;
            padding-left: 10%;
            background: ${PRIMARY};
            color: white;
            border-radius: 5px 5px 0 0;
            }
      }

    `

export const P = styled.p`
    text-align: center;
    color: black;
    margin-top: 10px;
    `

export const InputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    >div{
        margin-top: 5%;
    }
    `
    

const LogIn = () => {
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const {logIn, signUpWithGoogle, currentUser} = useAuth()
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [succesful, setSuccesful] = useState(false)
    const history = useHistory()

    async function handleSubmit(e){
        e.preventDefault()
        if (email.length<1){
            setEmailError(true)
            return
        }

        if (password.length<1){
            setPasswordError(true)
            return
        }

        /* if (password.includes('@') == false){
            setEmailError(true)
            return
        } */
        try{
        setLoading(true)
        setError("")
        await logIn(email, password)
        setSuccesful(true)
        history.push("/")
            }catch{
        setError(true)}
        setLoading(false)
    }
    return (
        currentUser?
            <CenteredText>{translate("already logged in")}</CenteredText>
            :
        <>
        <Banner trigger={error} setTrigger={setError}> 
            <MediumText>{translate("log in failed")}<br></br>{translate("try again")}</MediumText>
        <FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon>
        </Banner>
        
        <Flex>
            <Form onSubmit={(e)=>handleSubmit(e)}>
                <BigText>{translate("log in")}</BigText>

                <InputContainer>
                    <InputMUI setError={setEmailError} error = {emailError} type="email" label={emailError?"Please type a valid email adress":"Email"}  value={email} setValue={setEmail}></InputMUI>
                    <InputMUI type="password" setError={setPasswordError} error={passwordError} type="password" label={passwordError?"Password should be at least 4 characters long":translate("password")} value={password} setValue={setPassword}></InputMUI>
                </InputContainer>

                <Button  bigger={true}  color={PRIMARY}  disabled={loading} type="submit">{translate("log in")}</Button>
                {/* <Link to="/forgotPassword">Forgot Password?</Link><P>You don’t have an account? <Link style={{textDecoration: "underline", color:"#565656"}} to="/signUp">Sign Up</Link></P> */}
            </Form>
            
        </Flex>
        </>
    )
}

export default LogIn
