import { faChevronRight, faEnvelopeSquare, faLink, faLocationArrow, faPhoneSquare, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BLUE, PRIMARY } from '../../../Assets/Styles'
import translate from '../../../i18n/messages/translate'
import CircleIcon from '../CircleIcon'


const Container = styled.div`
width: 90%;
position: relative;
height: fit-content;
margin: auto;
background: ${BLUE};
padding: 5%;
padding-top: 0;
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
margin-bottom: 60px;
>.canton-header{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    bottom: 45px;
    padding: 3%;
    margin-bottom: -45px;
    img{
        height: 140px;
        position: relative;
        top: 0;}
    >h2{
        max-width: 100%;
        word-wrap: break-word;
        color: #fff;
        font-size: 2rem;
        margin-top: 2rem;
    }
}


>.canton-details{
    padding: 5%;
    >.canton-icon-text{
        display: flex;
        align-items: center;
        font-weight: 600;
        text-align: left;
        padding: 10px 0;
            >*{
                color: white;
            }
            >p{
                font-size: 1rem;
                margin-left: 20px;
            }
            >svg{
                height: 1.4rem;
                width: 1.4rem;
            }
    }
}

>.canton-arrow{
    >a>div{
        margin: auto;
        margin-right: 0;
        >svg{
            color: ${PRIMARY}
            }
    }
}


@media (orientation: landscape) and (min-width: 768px){
    width: 44vw;
    padding: 2%;
    padding-top: 0;
    >.canton-header{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: 60px;
        padding: 5%;
        padding-right: 0;
        position: relative;
        margin-bottom: -100px;
        img{
            height: 15vw;
            position: relative;
            top: 0;
        }
        >h2{
            width: 70%;
            text-align: left;
            margin-top: 0;
            color: #fff;
            font-size: 3rem;
        }
}
    >.canton-details{
        width: 80%;
        padding: 0 3%;
        
    }

    >.canton-arrow{
        position: relative;
}
}

@media (orientation: portrait) and (min-width: 768px){
    width: 60vw;
    padding: 2%;
    padding-top: 3%;
    height: auto;
    >.canton-header{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        bottom: 6vh;
        padding: 5%;
        padding-right: 0;
        position: relative;
        margin-bottom: -100px;
        img{
            height: 15vw;
            position: relative;
            top: 0;
        }
        >h2{
            width: 70%;
            text-align: left;
            margin-top: 0;
            color: #fff;
            font-size: 2.5rem;
        }
}
    >.canton-details{
        width: 80%;
        padding: 0 3%;
        
    }

    >.canton-arrow{
        position: relative;
}
}
`
const CantonProfilePreview = ({ name, flagImage, address, link, president, presidentWording, cantonId, cantonUrl, infoObject }) => {

    return (
        <Container >
            <div className="canton-header">
                <h2>{name}</h2>
                <img src={flagImage}></img>
            </div>
            <div className="canton-details">
                {president ? <div className="canton-icon-text">
                    <FontAwesomeIcon icon={faUserCircle}></FontAwesomeIcon>
                    <p>{president}</p>
                </div> :  <div className="canton-icon-text">
                    <FontAwesomeIcon icon={faUserCircle} style={{color: "rgb(21, 52, 118)"}}></FontAwesomeIcon>
                    <p>{president}</p>
                </div>}

                {/* COMMENTED OUT FOR THE MOMENT
                <div className="canton-icon-text">
                    <FontAwesomeIcon icon={faLocationArrow}></FontAwesomeIcon>
                    <p>{address || translate("no location")}</p>
                </div> */}

                {/* COMMENTED OUT FOR THE MOMENT 
                <div className="canton-icon-text">
                    <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>

                    {link?
                        <p>
                            <a style={{ color: "#fff", borderBottom: "1px solid #fff" }} href={link}>{translate("visit website")}</a></p> :
                        <p>
                            <Link style={{ color: "#fff", borderBottom: "1px solid #fff" }} to="/regionen">{translate("no website")}</Link>
                        </p>}

                </div> */}

                {/* <div className="canton-icon-text">
                    <FontAwesomeIcon icon={faEnvelopeSquare}></FontAwesomeIcon>
                    <p>Diese Vetranstaltung findet im Generationhaus Bern statt</p>
                </div> */}

            </div>
            <div className="canton-arrow">
                <Link
                    to={`/region/${cantonId}`}
                >
                    <CircleIcon color="white"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></CircleIcon>
                </Link>
            </div>
        </Container>
    )
}

export default CantonProfilePreview
