export const sliceArray = (array, Element, deleteButton) => {
    const firstDiv = []
    const secondDiv = []

    for (let i in array){
        if (i%2==0){
            firstDiv.push(<Element id={array[i].id} deleteButton={deleteButton}  author={array[i].author} dateTime={array[i].dateTime } image={array[i].imageUrl} header={array[i].header} text={array[i].text}></Element>)
        }
        else {
            secondDiv.push(<Element id={array[i].id} deleteButton={deleteButton} author={array[i].author} dateTime={array[i].dateTime } image={array[i].imageUrl} header={array[i].header} text={array[i].text}></Element>)
        }
    }
    return {firstDiv, secondDiv}
}



export const convertDate = (date) =>{
    const options = { 
         weekday: 'short', 
        year: 'numeric', month: 'short', day: 'numeric' };

   if(date){ 
    const dateDayInt = date.getDate()
    const startHour = date.getHours()
    const startMinutes = date.getMinutes()
    const mostBasicDate = date.toLocaleString()
    const basicDate = date.toLocaleString('de-DE', options)
    const dateString = date.toLocaleString('de-DE', { timeZone: 'CET' })
    const month = new Intl.DateTimeFormat('de-DE', { month: 'short'}).format(date)
    const year = new Intl.DateTimeFormat('de-DE', { year: 'numeric'}).format(date)
    
    const dateDayString = new Intl.DateTimeFormat('de-DE', { weekday: 'short'}).format(date)
    return({basicDate,dateString, dateDayInt, month, year, startHour, startMinutes, dateDayString, mostBasicDate})}
    else return {basicDate:"...", dateString:"...", dateDayInt:"...", month:"...", year:"...", startHour:"...", startMinutes:"...", dateDayString:"...", mostBasicDate:"..."}
}

export function importAllImagesFolder(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  

export const basicValidation = (values) => {
    let errors =0
    for (let i = 0; i<values.length; i++){
        if (values[i].value==""){
            errors++
            values[i].setError(previous=>true)
        }
        else {
            values[i].setError(previous=>false)
        }
    }
    return errors;
    }


export const  capitalizeFirstLetter = (string)=> {
    return  string.charAt(0).toUpperCase() + string.slice(1)
      }
      

export const isTouchDevice = () => {
        return window.matchMedia("(pointer: coarse)").matches
      }

export const getBrowser = function() {        
        try {
            var e;
            var f = e.width;
        } catch(e) {
            var err = e.toString();
    
            if(err.indexOf("not an object") !== -1) {
                return "safari";
            } else if(err.indexOf("Cannot read") !== -1) {
                return "chrome";
            } else if(err.indexOf("e is undefined") !== -1) {
                return "firefox";
            } else if(err.indexOf("Unable to get property 'width' of undefined or null reference") !== -1) {
                if(!(false || !!document.documentMode) && !!window.StyleMedia) {
                    return "edge";
                } else {
                    return "IE";
                }
            } else if(err.indexOf("cannot convert e into object") !== -1) {
                return "opera";
            } else {
                return undefined;
            }
        }
    };