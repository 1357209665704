import React from 'react'
import { faCalendarAlt, faClock, faLocationArrow, faMap, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import ShareComponent from '../../GeneralUse/ShareComponent'
import missingImage from "../../../Assets/Images/missingImage.png"
import { BLUE, PRIMARY } from '../../../Assets/Styles'
import { convertDate } from "../../../Assets/UtilityFunctions"
import QuillTextDisplay from '../QuillTextDisplay'
import translate from '../../../i18n/messages/translate'

const Container = styled.div`
height: fit-content;
margin: auto;
display: flex;
flex-direction: column;
background: white;
margin-bottom: 30px;

>img{
    object-fit: cover ;
}


.header-date-container{
margin-bottom: 7vh;
display: flex;
flex-direction: column;
margin-top: -50px; 
}


>.event-text{
    text-align: left;
    padding: 0 7%;
        >.event-details{
            margin-bottom: 50px;
            >div{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 15px;
                >div{
                    display:flex;
                    align-items: center;
                    margin-bottom: 15px;
                    >svg{
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                    color: #565656;
                }}
            }
        }
        
    }


    @media (orientation: landscape) and (min-width: 768px){
        width: 70%;
        box-shadow: 0px 10px 20px rgb(0 0 0 / 25%);
        margin-top: 5%;
       
        .header-date-container{
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            width: 100%;
            height: 20vh;
       
    }

    >.share-component{
            padding: 3% 7%;
        }
    }

    
    
`

const TimeContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
width: 90%;
min-height:100%;
background: ${PRIMARY};
padding: 5%;
margin:auto;
>.date{
    display: flex;
    height: 3.9rem;
    >.date-day{
        font-size: 5rem;
        line-height: 3.9rem;
        color:white;
    }
    >.date-month{
        >p{
            color:white;
            font-size: 1.6rem;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}
>div{
    >svg{
        margin-top: 3px;
        height: 1.5rem !important;
        width: 1.5rem !important;
        margin-right: 10px;
        color: white;
    }
}
>.event-time{
    display: flex;
    >div{
        display: flex;
        flex-direction: column;
        align-items: space-between;
        justify-content: space-between;
        >p{
        color:white;
        font-size: 1.8rem;
    }
        >p:first-child{
            margin-bottom: 2px;
        }
        >p:nth-child(2){
            margin-bottom: -1px;
        }
    }
}
@media (orientation: landscape) and (min-width: 768px){
    width: fit-content;
    margin:0;
    height: 100%;

}
@media (orientation: landscape) and (max-height: 500px){
    height: fit-content;
}
`

const HeaderContainer = styled.div`
background: ${BLUE};
            width: 90%;
            margin: auto;
            text-align: left;
            color: white;
            padding: 0 7%;
            min-height: 15vh;
            height: fit-content;
            display: flex;
            align-items: center;
            padding: 5%;
                >h2{
                    word-wrap: break-word;
                    margin: auto;
                    color: white;
                    max-width: 100%;
                    font-size: 2.1rem;
                }
    @media (orientation: landscape) and (min-width: 768px){
        background: ${BLUE};
        height: 100%;
    }

`

const defaultText = <></>

const FullEventComponent = ({ image, location, header, startTime, endTime, text, id, author, date }) => {
    const convertedEndTime = convertDate(endTime)
    const convertedStartTime = convertDate(startTime)
    const convertedDate = convertDate(date)
    console.log("here: ",convertedEndTime)
    console.log("here: ",convertedStartTime)
    return (
        <>
            <Container>
                <img src={image || missingImage}></img>
                <div className="header-date-container">
                    <HeaderContainer >
                        <h2>{"This is a header This is a header This is a header" && header}</h2>
                    </HeaderContainer>
                    <TimeContainer className="event-date">
                        <div className="date">
                            <FontAwesomeIcon color="white" icon={faCalendarAlt}></FontAwesomeIcon>
                            <p className="date-day">{convertedDate.dateDayInt}</p>
                            <div className="date-month">
                                <p>{convertedDate.month} </p>
                                <p>{convertedDate.year}</p>
                            </div>
                        </div>
                        <div className="event-time">
                            <FontAwesomeIcon color="white" icon={faClock}></FontAwesomeIcon>
                            <div>
                                <p>{`${convertedStartTime.startHour}:${convertedStartTime.startMinutes == 0? "00":convertedStartTime.startMinutes}`}</p>
                                <p>{`${convertedEndTime.startHour}:${convertedEndTime.startMinutes == 0? "00": convertedEndTime.startMinutes}`}</p>
                            </div>
                        </div>
                    </TimeContainer>
                </div>
                <div className="event-text">
                    <div className="event-details">
                        <div className="event-author-location">
                            <div>
                                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><p>{author || translate("author's Name")}</p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faLocationArrow}></FontAwesomeIcon><p>{location || "No Location"}</p>
                            </div>
                        </div>

                    </div>
                    <QuillTextDisplay text={text || defaultText}></QuillTextDisplay>
                </div>
                <ShareComponent header={header}></ShareComponent>

            </Container>
        </>
    )
}

export default FullEventComponent

