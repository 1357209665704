import ReactQuill  from 'react-quill'; 
import React, {useEffect, useMemo, useState} from 'react'
import 'react-quill/dist/quill.snow.css'; 
import { useRef } from 'react';
import { PRIMARY } from '../../Assets/Styles';
import translate from '../../i18n/messages/translate';
const getOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
        return "portrait"
     }
     
     if (window.matchMedia("(orientation: landscape)").matches) {

        return "landscape"
     }
}

let formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'code'
  ]

  const phoneContainer = [
    [{'header': [3, 4, false]}],
    ['bold', 'underline', 'blockquote'],
    [{color: []}, {background: []}],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link', 'image'],
    ['clean']
]

const desktopContainer = [
    [{'header': [3, 4, false]}],

    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{color: []}, {background: []}],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
]

let container
const Quilll = ({text, setText, startHandleImages, setFinishImages}) => {


    const quill = useRef()
    const handleChange = (html) =>{
        setText(html)  
    }

    
   const orientation  = getOrientation()
   const width  = window.screen.width
useEffect(()=>{
  container = document.getElementsByClassName("ql-editor")[0]
},[])


    const handleImages = () =>{

    if (container) {
        let images = container.querySelectorAll("img")
        for (let image of images ){
              if(image){
         fetch(image.src).then(response=>response.blob()).then(data=>{
          let reader = new FileReader();
          reader.readAsDataURL(data)
          reader.onloadend = function() {
              var base64data = reader.result;                
              image.src=base64data
          }
          })
      }}
      setFinishImages(true)

}}
   

  
useEffect(()=>{
    if(startHandleImages){handleImages()}
}, [startHandleImages])

    const modules = useMemo(()=>({
        toolbar: {
            toolbarOptions:[{ 'size': ['small', false, 'large', 'huge'] }],
            container: width>768 &&orientation.includes("landscape")?desktopContainer:phoneContainer,
            handlers: {
                // handlers object will be merged with default handlers object
                'link': function(value) {
                  if (value) {
                    var href = prompt('Enter the URL');
                    this.quill.format('link', href);
                  } else {
                    this.quill.format('link', false);
                  }
                }
                
              }
        
        
        },
    }), [])
    
    
      

     

    return (

        <div>
            <ReactQuill 
            placeholder={"..."}
            placeholderColor={PRIMARY}
            ref={quill}
            theme="snow" 
            value={text}
            modules={modules}
            formats={formats}
            onChange={handleChange.bind(this)}/>
        </div>
    )
}

export default Quilll
