import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { BLUE } from '../../Assets/Styles'

const SuccesContainer= styled.div`
z-index: 888999984848484;
position: fixed;
height: 100vh;
width: 100vw;
top:0;
left:0;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(255, 255, 255, 0.15);  
transition: 0.3s;
backdrop-filter: blur(4px);
transform: ${props=>props.trigger? "translateY(0)":"translateY(-210vh)"};

`
const Succes = styled.div`
z-index: 999;
width: fit-content;
max-width: 80vw;
padding: 5%;
background: #fff;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
display: flex;
transition: 0.2s; 
position: relative;
flex-direction: column;
:hover{
    transform: scale(1.2);
}
>h3{
    color: ${BLUE};
    margin-bottom: 2vh;
}
>svg{
    margin: auto;
    height: 10vh;
    width: 10vh !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
>.loading_animation{
    animation: rotation 2s infinite linear;
    animation: rotation 2s infinite linear;

}
@media (orientation: landscape) and (min-width: 768px){
    >h3{
    margin-bottom: 5vh;
}
    >svg{
    margin: auto;
    height: 10vh;
    width: 10vh !important;
}
}


/* transform: translateY(-55vh); */
`


const Banner = ({children, url, trigger, setTrigger, loading}) => {
    const history = useHistory()


    const handleClick = () => {
        setTrigger(false)
        url?history.push(url):window.location.reload()
    }

    
    return (
        
        <SuccesContainer className="Banner" trigger={loading?loading:trigger}><Succes onClick={handleClick}>
            {loading?
            <FontAwesomeIcon color="#7F7f7f" className="loading_animation" icon={faSpinner}></FontAwesomeIcon>
            :
            children}
            </Succes></SuccesContainer>

    )
}

export default Banner
