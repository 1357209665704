import styled  from 'styled-components'
import React, { useState } from 'react'
import CenteredText from "../../../Components/UniqueComponents/CenteredText"
import { bigShadow, Button, PRIMARY } from '../../../Assets/Styles'
import { useAuth } from '../../../Contexts/AuthContext'
import Quilll from '../../../Components/UniqueComponents/Quill'
import translate from '../../../i18n/messages/translate'

const Form = styled.form`
    background: white;
    height: fit-content;
    padding: 15vh 0 10vh 0;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 50px;
    >*{
        margin-top: 30px;
    }

    >input{
        width: 90%;
    }
    >button{
        margin-top: 15px;
    }

    >a{
        text-decoration: underline;
        color: black;
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    >h2{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            color: black;
            height: 14%;
            width: 100%;
            background: ${PRIMARY};
            color: white;
            }

    @media (orientation: landscape) and (min-width: 768px){
        width: 35%;
        padding-top: 10%;
        height: 100vh;
        box-shadow: ${bigShadow};

        >h2{
            height: 10%;
            }
      }

    `

const InputContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    >div{
        margin-top: 5%;
    }


 .input-file-trigger {
     width: 80%;
  display: block;
  padding: 14px 45px;
  background: rgba(255, 0, 0, 0.25);
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
  border-radius: 5px;
  :hover{
    background: rgba(255, 0, 0, 0.45);;
  }
}
.input-file {
  position: absolute;
  top: 0; left: 0;
  width: 80%;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
>img{
    border-radius: 20px;
    width: 80%;
    margin:auto;
    margin-bottom: 20px;
    height: 15%;
    object-fit: cover;
}
    `
const Container = styled.div`
 width: 100%;
 display: flex;
 padding:5%;
 padding-bottom: 1%;

 >div>{

     >.quill{
         height: 100% !important;
     }
 }

 @media (orientation: portrait){
     flex-direction: column;
 }
 @media (orientation: landscape) and (min-width: 768px){
    >div>{
        width: 65vw !important;
        >.quill{
            height: 100% !important;
        }
}
 }

 `

const PostButton = styled.button`
cursor: pointer;
width: 90vw;
height: 7vh;
background: ${PRIMARY};
border: none;
font-size: 1.4rem;
color: white;
box-shadow: ${bigShadow};
border-radius: 10px;
font-weight: 700;
margin-Bottom: 10vh;
transition: 0.2s;
margin-top:50px;
:hover{
     transform: scale(1.05)
 }

 @media (orientation: landscape) and (min-width: 768px){
    font-size: 2rem;
    width: 90vw;
    height: 7vh;
    margin-top:0px;

 }

`

const FormQuill = (props) => {
    const {currentUser} =useAuth()
    




    return (
        !currentUser?
            <CenteredText>This page is destinated only to authorized users</CenteredText>
            :
            <>
        <Container className="henlo">
        
            <Form >
                <h2>{props.name}</h2>

                <InputContainer>
                    {props.children}
                </InputContainer>

               
            </Form>
            
           <Quilll error={props.textError} setError={props.setTextError} text={props.text} setFinishImages={props.setPost} startHandleImages={props.startHandleImages} setText={props.setText}></Quilll>
        </Container>
       {props.noButton?null: <PostButton onClick={()=>props.setStartHandleImages(true)} disabled={props.loading}>{translate("upload")}</PostButton>}
        </>
        
    )
}

export default FormQuill
