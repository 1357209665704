import React from 'react'
import styled from "styled-components"
import Video from './Video';
import videoPoster from "../../Assets/Images/videoPoster.png"
import { BigText, BLUE, Button, PRIMARY } from '../../Assets/Styles';
import translate from '../../i18n/messages/translate';
import { Link } from 'react-router-dom';

const Container = styled.div`
height: fit-content;
width: 100%;
margin-bottom: 40px;
min-height: fit-content;

>img{
    width: 100%;
    height: 45%;
    object-fit: cover;
    margin: 0;
}

@media (orientation: landscape) and (min-width: 768px){
    width: 95%;
    height: 40vw;
    margin: auto;
    margin-bottom: 72px !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    margin-bottom: 0px;
    >img{
    width: 50%;
    height: 100%;
    }
}

@media (orientation: portrait) and (min-width: 768px){
    width: 95vw;
    height: 35vh;
    margin: auto;
    margin-bottom: 72px !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    margin-bottom: 0px;
    >img{
    width: 50%;
    height: 100%;
    }
    >div{
    width: 50%;
    height: 100%;
    }
}

@media (orientation: landscape) and (max-height: 500px){
display: flex;
margin-bottom: 0px;
height: 90vh;
    >img{
    width: 50%;
    height: 100%;
    }
    >div{
        margin-top: 0px;
    height: 100%;
    background-color:white;
    position: relative;
    flex-direction: column;
    padding: 5%;
    }
}

@media (orientation: landscape) and (max-height: 500px){
    height: fit-content;
}
`

const ContentContainer = styled.div`
    background-color: ${PRIMARY} !important;
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    height: fit-content;
    background-color:white;
    min-height: 35vh;
    position: relative;
    padding: 11%;
    >h2{
        color: white;
        margin-bottom: 10px;
    }
    >p{
        color: white;
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 39px;
    }
    >a>button{
        margin: 0;
    }
    

//for desktop layout
@media (orientation: landscape) and (min-width: 768px){
    background-color: ${PRIMARY};
    display: flex;    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    height: 100%;
    padding: 2%;
    width: 50%;
    
    >p{
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 20px;
    }

}

@media (orientation: portrait) and (min-width: 768px){
    margin-top: 0px;
    height: 100%;
    position: relative;
    min-height: 0;
    >h2{
        font-size: 3rem;
        color: white;
        margin-bottom: 10px;
    }
    

    >div{
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: auto;
}
}
@media (orientation: landscape) and (max-height: 500px){
    width: 50%;

}


`


function Card(props) {

    return (
        <Container reverse={props.reverse}>
            {props.video ?
                <Video src={props.src} poster={videoPoster}></Video>
                :
                <img src={props.image}></img>}
            <ContentContainer reverse={props.reverse} >
                <BigText>{props.heading}</BigText>
                <p>{props.subHeading}</p>
                <Link to="/aboutUs"><Button color={BLUE} textColor="#fff">{translate("read more")}</Button></Link>
            </ContentContainer>
        </Container>


    )
}

export default Card
