import React from 'react'
import styled from 'styled-components'
import { bigShadow } from '../../Assets/Styles'

const Container  = styled.div`
height: 60px;
width: 60px;
border-radius: 50%;
background-color: ${props=>props.color};
display: flex;
position: relative;
cursor: pointer;
align-items:center;
justify-content: center;

>svg{
    color: ${props=>props.iconColor? props.iconColor: "white"};
    margin: auto;
    height: 40% !important;
    width: 40% !important;
}
>p{
    color: white;
    cursor: pointer;
}
>div{
  width: 15vw;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  right: -2.9vw;
  height: fit-content;
  bottom: 80px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  transform: scale(0) translateY(-150px);
  color: white !important;
  z-index: 999;

  >p{
      color: black;
      cursor: pointer;

  }
}
>.opened{
  opacity: 1;
  transform: scale(1);
  font-size: 1.1rem;
}
transition: 0.3s;

@media (orientation: landscape) and (min-width: 768px){
    :hover{
        box-shadow: ${bigShadow};
    }
}`

function CircleIcon(props) {
    return (
        props.setInfo?
        <Container 
        onMouseEnter={()=>props.setInfo(true)} 
        onMouseLeave={()=>props.setInfo(false)} 
        onClick={props.onClick} 
        iconColor={props.iconColor}
        className={props.className} 
        id={props.id} color={props.color}>
            {props.children}
           <div className={props.info? "opened": ""}><p>{props.infoText}</p></div>
        </Container>
        :
        <Container  
            onClick={props.onClick} 
            className={props.className} 
            id={props.id} 
            color={props.color}
            iconColor={props.iconColor}>
            {props.children}
           
        </Container>
    )
}

export default CircleIcon
