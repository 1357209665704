import styled from "styled-components"

export const PRIMARY = "rgb(120, 35,40)"
export const BLUE = "#0D337B"
export const bigShadow="0px 10px 20px rgba(0, 0, 0, 0.25)"
export const LIGHTGREY = "#f5f5f5"
export const DARKGREY = "#A2A2A2"

export const NewsInfoButton = styled.button`
    width: ${props=>props.bigger? "80%":"auto"};
    height: ${props=>props.bigger? "52px":"auto"};
    position: relative;
    background: ${props=>props.color?props.color:PRIMARY};
    color: ${props=>props.textColor? props.textColor:"white"};
    border-color: white;
    padding: 7px 30px;
    font-weight: 400 !important;
    border: 2px solid ${props=>props.borderColor? props.borderColor: props.color};
    font-size: ${props=>props.bigger? "1.5rem": "1rem"};
    border-radius: 5px;
    outline: none;
    font-weight: bold !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    margin: auto;
    box-shadow: ${props=>props.shadow?bigShadow:null};
    :hover{
            transform: scale(1.1);
        }
    >svg{
        margin-right: 10px;
    }

    @media (min-width: 768px){
        font-size: 1.4rem;
        padding: 10px 30px;
        :hover{
            transform: scale(1.1);
        }
      }
`

export const Button = styled.button`
    width: ${props=>props.bigger? "80%":"auto"};
    height: ${props=>props.bigger? "52px":"auto"};
    position: relative;
    background: ${props=>props.color?props.color:PRIMARY};
    color: ${props=>props.textColor? props.textColor:"white"};
    padding: 7px 30px;
    font-weight: 400 !important;
    border: 2px solid ${props=>props.borderColor? props.borderColor: props.color};
    font-size: ${props=>props.bigger? "1.5rem": "1rem"};
    border-radius: 5px;
    outline: none;
    font-weight: bold !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
    margin: auto;
    box-shadow: ${props=>props.shadow?bigShadow:null};
    :hover{
            transform: scale(1.1);
        }
    >svg{
        margin-right: 10px;
    }

    @media (min-width: 768px){
        font-size: 1.4rem;
        padding: 10px 30px;
        :hover{
            transform: scale(1.1);
        }
      }
`



export const SlicedContainer = styled.div`
margin: auto;
margin-top: 3%;

@media (orientation: landscape) and (min-width: 768px){
    width: 70%;
    padding: 1rem;
    display: flex;
    >div{
    display: inline-block;
    width: 50%;

}
}

@media (orientation: portrait) and (min-width: 768px){
    width: 90%;
    padding: 1rem;
    display: flex;
    >div{
    display: inline-block;
    width: 50%;

}
}


`

export const Container = styled.div`
height: 7vh;
width: 100%;
display: flex;
background: ${props=>props.background? props.background:"white"};
>div{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;
    background-color: white;

    >h3{
        font-size: 1.3rem;
        color: ${PRIMARY};
        font-weight: 500;
    }
}
>#${props=>props.idOne}{
    border-bottom: ${props=>props.selected===props.idOne? "5px solid rgb(120, 35,40)":"inherit"};
}
>#${props=>props.idTwo}{
    border-bottom: ${props=>props.selected===props.idTwo? "5px solid rgb(120, 35,40)":"inherit"};
}


@media (orientation: landscape) and (min-width: 768px){
height: 10vh;
>div{

    >h3{
        font-size: 3vh;
    }
}
}
@media (orientation: portrait) and (max-height: 500px){
    height: 7vh;
}
@media (orientation: portrait) and (min-width: 768px) and (max-width: 1100px){
    height: 10vh;
}

`

export const Flex = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: ${props=>props.column? "column": "row"};
justify-content: ${props=>props.justify? props.justify: "center"};
align-items: center;
flex-wrap: wrap;
`
//Fonts 
export const BigText = styled.h2`
font-size: 2.5rem;


//tablets
@media (orientation: landscape) and (min-width: 768px){
    font-size: 4rem;
}
//portrait tablets
@media (orientation: portrait) and (min-width: 768px){
    font-size: 5rem;
}
@media (orientation: landscape) and (max-height: 500px){
    font-size: 3rem;
}
`

export const MediumText = styled.h3`
font-size: 1.8rem;
//tablets
@media (orientation: landscape) and (min-width: 1100px){
    font-size: 3rem;
}

//desktops
@media (orientation: landscape) and (min-width: 768px){
    font-size: 2.4rem;
}

@media (orientation: portrait) and (min-height: 1000px){
    font-size: 2.1rem;
}

`

