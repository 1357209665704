import React from 'react'
import { useLayoutEffect } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { getBrowser } from '../../Assets/UtilityFunctions'

const Container = styled.div`
width: min(600px, 80%);
height:fit-content;
background:"black";
min-height: 40vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-image: radial-gradient(#000000 0.5px, #e21e21 0.5px);
background-size: 10px 10px;
padding: 7%;
margin:auto;
margin-top:50px;
position: relative;
>img{
    width: 400px;   
    margin-bottom: 20px;
}
>h4{
    font-size: 2rem;
    color: white;
    margin-bottom: 30px;
    height: 120px;
}
>p{
    height: 200px;
    overflow-y: auto !important;
    margin-bottom: 30px;
    color:"white" !important;
    padding-right: 20px
}
>figure{
    position: ${props => props.browser == "safari" ? "relative" : "absolute"};
    bottom:${props => props.browser == "safari" ? "0px" : "-30px"}
}


@media (orientation: landscape) and (min-width: 768px){
min-height: fit-content;
width: 40vw;
>img{
    width: 300px;
}
>figure{
    position: relative;
    bottom:0px;
}
}
@media (orientation: landscape) and (min-width: 1300px){
min-height: fit-content;
width: 30vw;
padding: 1.5rem;
justify-content: space-around;

>img{
    width: 300px;
}
>figure{
    position: relative;
    bottom:0px;
}
}


@media (orientation: landscape) and (max-height: 500px){
/* height:60vw; */
width: 45vw;
>img{
    width: 200px;
}
}
@media (orientation: landscape) and (min-width: 1033px){
    >h4{
        height: 80px;
    }
    }
`



const PodcastComponent = ({ title, src, img, description }) => {


    return (
        <>
            <Container browser={getBrowser()}>
                <img src={img}></img>
                <h4>{title}</h4>
                <p style={{ color: "white" }}>{description}</p>
                <figure >
                    <audio
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            background: "white", borderRadius: 999, maxWidth: "100%", transform: getBrowser() == "safari" ? "scale(1.1)" : ""
                        }}
                        controls
                        controlsList="nodownload noplaybackrate"
                        src={src}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </figure>
            </Container>

        </>
    )
}

export default PodcastComponent



