import { faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import ShareComponent from '../ShareComponent'
import missingImage from "../../../Assets/Images/missingImage.png"
import QuillTextDisplay from '../QuillTextDisplay'
import { PRIMARY } from '../../../Assets/Styles'

const Container = styled.div`
height: fit-content;
margin: auto;
display: flex;
flex-direction: column;
background: white;
margin-bottom: 30px;

>img{
    object-fit: contain ;
}


    >.news-header{
        background: ${PRIMARY};
        width: 90%;
        margin: auto;
        margin-top: -50px;
        margin-bottom: 50px;
        text-align: left;
        color: white;
        padding: 0 7%;
        min-height: 20vh;
        display: flex;
        align-items: center;
        >h2{
            margin: auto;
            color: white;
            font-size: 2.1rem;
        }
        }

    >.news-text{
        text-align: left;
        padding: 0 7%;
        >.news-details{
            margin-bottom: 50px;
            >div{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                >svg{
                    width: 25px;
                    height: 25px;
                    margin-right: 20px;
                    color: #565656;
                }
            }
        }
        >.html-text{
            >h3, h4, h5,p{
                    font-weight: 400;
                    margin-bottom: 10px;
                }
            >h3{
                font-size: 1.8rem;
                line-height: 2rem;
            }
            >h4{
                font-size: 1.6rem;
                line-height: 1.8rem;
            }
            >h5{
                font-size: 1.4rem;
                line-height: 1.6rem;
            }
            >p{}
            width: 100%;
            >*>p{
                max-width: 100%;
            }
            >p>img{
                width: 100% !important;
            }
        }
    }


    @media (orientation: landscape) and (min-width: 768px){
        width: 70%;
        box-shadow: 0px 10px 20px rgb(0 0 0 / 25%);
        margin-top: 5%;
        >.news-text{
            >.html-text{
                >h3, h4, h5{
                    font-weight: 400;
                    margin-bottom: 15px;
                }
        }
    }
        >.news-header{
            width: 80%;
        >h2{
            font-size: 2.5rem;
        }
        padding: 0 7%;
        }
        >.share-component{
            padding: 3% 7%;
        }

    }
`
const defaultText = <>
    </>



const BlogComponent = ({ image, header, author, text, date, id}) => {
  
    return (
        <>
        <Container>
            <img src={image||missingImage}></img>
            <div className="news-header">
                <h2>{"This is a header"&&header}</h2>
            </div>
            <div className="news-text">
                <div className="news-details">
                    <div>
                        <FontAwesomeIcon icon={faUser}></FontAwesomeIcon><p>{"Authors Name"&&author}</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon><p>{`${date}`}</p>
                    </div>
                </div>
                <QuillTextDisplay text={text||defaultText}></QuillTextDisplay>
            </div>
            <ShareComponent header={header}></ShareComponent>

        </Container>
        </>
    )
}

export default BlogComponent
