import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { StepLabel } from '@material-ui/core';
import { PRIMARY } from '../../../Assets/Styles';



  const useStyles = makeStyles((theme) => ({
    root:{
        width: '80%',

        ' > *': {
          margin: theme.spacing(1),
        },
        "& .MuiIconButton-root":{
          color: PRIMARY
        },
        "& .MuiInput-underline:before":{
            borderBottom: "1px solid rgb(255 0 0 / 42%)"
        }
        
        

      },

    
    }))


const DatePicker = ({date, setDate, label}) => {
  const handleDateChange = (date) => {
    setDate(date);
  };

  const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
        className={classes.root} 
          margin="normal"
          id="date-picker-dialog"
          label={label}
          format="MM/dd/yyyy"
          value={date}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
      </Grid>
    </MuiPickersUtilsProvider>
    )
}

export default DatePicker
