import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import CircleIcon from './CircleIcon'
import {FacebookShareButton,  LinkedinShareButton, EmailShareButton} from 'react-share';
import { PRIMARY } from '../../Assets/Styles'
import translate from '../../i18n/messages/translate'
import { useRef } from 'react'

const Container =styled.div`
width: 100%;
display: flex;
justify-content: space-between;
padding: 5% 7%;
@media (orientation: landscape) and (min-width: 768px){
    width: fit-content;
    padding: 3% 0%;
    >button{
        margin-right: 2vw;
        cursor: pointer;
    }
};

@media (orientation: portrait) and (min-width: 768px){
    width: 50%;
};`
function copyToClipboard(text, some) {
        var inputc = document.body.appendChild(document.createElement("input"));
        inputc.value = window.location.href;
        inputc.focus();
        inputc.select();
        document.execCommand('copy');
        inputc.parentNode.removeChild(inputc);
        alert(
            some)
        }

const ShareComponent = ({header}) => {
    const location = window.location.href
    const paragraphRef = useRef()
    let some = ""

    useEffect(()=>{
        some = paragraphRef.current.textContent
    })
    

    
        
    return (
        
        <Container className="share-component">
           {/*  <EmailShareButton
            subject={header}
            body={`${header}: ${location}`}>
            <CircleIcon color={PRIMARY}>
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
            </CircleIcon>
            </EmailShareButton>


            <LinkedinShareButton
            url={location}
            title={header}
            source="NHG.ch">
                <CircleIcon color={PRIMARY}>
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.77475 2.28389C4.77475 3.52898 3.82671 4.5366 2.34415 4.5366C0.919355 4.5366 -0.0286772 3.52898 0.000662254 2.28389C-0.0286772 0.978283 0.919332 0 2.37255 0C3.82669 0 4.74633 0.978283 4.77475 2.28389ZM0.119858 20.8191V6.31621H4.62712V20.8181H0.119858V20.8191Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.23977 10.9446C8.23977 9.13564 8.18017 7.59349 8.12057 6.31814H12.0356L12.2437 8.30497H12.3326C12.9258 7.38537 14.4084 5.99266 16.8106 5.99266C19.7757 5.99266 22 7.95015 22 12.219V20.821H17.4927V12.7838C17.4927 10.9143 16.8408 9.63991 15.2097 9.63991C13.9637 9.63991 13.2229 10.4999 12.9268 11.3297C12.8076 11.6267 12.7489 12.0412 12.7489 12.4574V20.821H8.24159V10.9446H8.23977Z" fill="white"/>
                    </svg>
                </CircleIcon>
            </LinkedinShareButton>


            <FacebookShareButton
            url={location}
            quote={header}>
                <CircleIcon color={PRIMARY}>
                    <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.315 3.9578C9.6917 3.83314 8.84983 3.74 8.32037 3.74C6.8867 3.74 6.79358 4.36333 6.79358 5.36066V7.13607H10.3774L10.065 10.8137H6.79358V22H2.30632V10.8137H0L0 7.13607H2.30632V4.86127C2.30632 1.74533 3.77079 0 7.44771 0C8.72517 0 9.66017 0.187001 10.8753 0.436334L10.315 3.9578Z" fill="white"/>
                    </svg>
                </CircleIcon>
            </FacebookShareButton> */}
            <p style={{display:"none"}} ref={paragraphRef}>{translate("URL kopiert")}</p>
            <CircleIcon onClick={()=>copyToClipboard(location, some)} className="link-copy" color={PRIMARY}><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></CircleIcon>
        </Container>
    )
}

export default ShareComponent
