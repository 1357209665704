import React from 'react';
import styled from 'styled-components';
import notFound from "../Assets/Images/notFound.png"
import { Flex } from '../Assets/Styles';
import CenteredText from '../Components/UniqueComponents/CenteredText';


const IMG = styled.img`
height: 50%;
`
const Container = styled.div`
height: 80vh;
width: 100vw;`
function NotFound(props) {
    return (
        <Container>
            <Flex>
                <IMG src={notFound}></IMG>
            </Flex>
        </Container>
    );
}

export default NotFound;