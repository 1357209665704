import React, { useState } from 'react'
import styled from "styled-components"
import { Link } from "react-router-dom"
import ShareComponent from '../GeneralUse/ShareComponent'
import translate from '../../i18n/messages/translate'

const Container = styled.div`
padding: 5% 5%;
display: flex;
flex-direction: column;
justify-content: center;
background: rgb(242, 242, 247);
align-items: flex-start;
padding-bottom: 20%;
>.second{
      height: 200px;
      >.secondHead{
        pointer-events: none;
        opacity: 1;
        height: 30px;  
  }
  }
  



@media (orientation: landscape){
    padding: 2.5% 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f2f2f7;
    align-items: flex-start;
    padding-bottom: 8%;

    >div>div>h4{
        display: none !important;
    }


    >div{
        overflow: visible !important;
        height: fit-content !important;
        border-top: none;
        margin-top: 0;
    }

    >div>p{
        padding-left: 0px;
    }
    >div{
        p:first-child{
            margin-bottom: 25px;
        }
    }
  }

  @media (orientation: landscape) and (max-height: 500px){
    padding: 5% 5%;
display: flex;
flex-direction: column;
justify-content: center;
background: rgb(242, 242, 247);
align-items: flex-start;
padding-bottom: 20%;
>.second{
      height: 170px;
      >.secondHead{
        pointer-events: none;
        opacity: 1;
        height: 30px;  
  }
  }
    }
`



const Second = styled.div`
transition: all 0.4s ease;
height: 30px;
width: 100%;
height: 40px;
border-top: 1px  solid;
border-color: rgba(86, 86, 86, 0.5);
width: 100%;
margin-top: 15px;
padding-top: 10px;
text-align: left;

>div{
        h4{
            cursor: pointer;
            overflow: hidden;
            color: #565656;
            transform: rotate(45deg);
            font-weight: 600;
            margin-bottom: 4px;
            transition: all 0.4s ease;
            margin-right: 10px;
            word-wrap: break-word;
        }
        >.rotate{
            transform: rotate(-90deg);
            margin-right: 12px;
            margin-top: 2px;
        }
    }
p:first-child{
        font-size: 1.5rem;
        text-overflow: ellipsis;
        width: 80%;
        word-wrap: break-word;
        padding-left: 0px;
        margin-bottom: 10px;
        font-weight: bold;
        opacity: 1;
        height: fit-content;
        pointer-events: none;
}

@media (orientation: landscape){
    text-align: left;
    max-width: 25vw;
}

@media (orientation: landscape) and (max-height: 500px){
    height: 170px;
    text-align: left;
}
`

const Head = styled.p`
transition: all 0.4s ease;
opacity: 0;
height: 0px;
padding-left: 15px;
margin-top: 5px;
cursor: pointer;


@media (orientation: landscape){
      opacity: 1;
      height: 30px;  
      pointer-events: auto;
}


>a{
        text-decoration: "none";
        color: inherit !important;
    }
`
function Footer() {
    const [account, setAccount] = useState(false)
    const [values, setValues] = useState(false)
    const [about, setAbout] = useState(false)
    const [nhg, setNhg] = useState(false)


    return (
        <Container>
            <Second className={nhg ? "second" : ""}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Head >{translate("nhg switzerland")}</Head>
                    <h4 onClick={() => { setNhg(previous => !previous) }} className={nhg ? "rotate" : ""}>×</h4>
                </div>
                <Head className={nhg ? "secondHead" : ""}><Link to="/blogs">Blogs</Link></Head>
                <Head className={nhg ? "secondHead" : ""}><Link to="/events">{translate("events")}</Link></Head>
                <Head className={nhg ? "secondHead" : ""}><Link to="/archiv">{translate("archive")}</Link></Head>
                <Head className={nhg ? "secondHead" : ""}><Link to="/aboutUs">{translate("Who are we")}</Link></Head>

            </Second>
            <Second className={account ? "second" : ""}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Head>{translate("contact")}</Head>
                    <h4 onClick={() => { setAccount(previous => !previous) }} className={account ? "rotate" : ""}>×</h4>
                </div>
                <Head className={account ? "secondHead" : ""}><a >{translate("phone number")}:<br></br><p style={{ lineHeight: "25px" }}>+41 79 648 34 84</p></a></Head>
                <Head className={account ? "secondHead" : ""}><Link style={{ lineHeight: "50px" }} to="">Email: info@nhg.ch</Link></Head>
                <Head style={{ marginTop: "20px" }} className={account ? "secondHead" : ""}>
                    <a >Esther Hulliger</a><br />
                    <a >NHG Schweiz</a><br />
                    <a href="">Mühlestrasse 24</a><br />
                    <a href="">3123 Belp/BE</a><br />
                </Head>
            </Second>
            <Second className={values ? "second" : ""}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Head>{translate("terms and services")}</Head>
                    <h4 onClick={() => { setValues(previous => !previous) }} className={values ? "rotate" : ""}>×</h4>
                </div>
                <Head className={values ? "secondHead" : ""}><a href="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/TermsOfService%2FImpressum.pdf?alt=media&token=0545edeb-e0d6-40c9-b20f-829a3db4c8e1">
                    {translate("impressum")}</a></Head>
                <Head className={values ? "secondHead" : ""}><a href="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/TermsOfService%2FDatenschutz.pdf?alt=media&token=05ff4099-0de4-4028-8df1-312af1dc0d88">
                    {translate("datenschutz")}</a></Head>
                <Head className={values ? "secondHead" : ""}><a href="https://firebasestorage.googleapis.com/v0/b/nhg-development-a9acc.appspot.com/o/TermsOfService%2FAGB.pdf?alt=media&token=dde366fd-0b33-4085-ba8e-ba944251ed51">
                    {translate("AGB")}</a></Head>
            </Second>


            <Second style={{ height: "fit-content" }} className={about ? "second" : ""}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Head >{translate("share")}</Head>
                </div>
                <ShareComponent header="NHG Schweiz"></ShareComponent>
            </Second>


        </Container>
    )
}

export default Footer

