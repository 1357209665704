import React from 'react'
import { Route, useRouteMatch } from 'react-router'
import { useBlogs } from '../../Contexts/BlogContext'
import Blogs from '../../Pages/Blogs'
import Loading from '../GeneralUse/Loading'
import BlogComponent from '../GeneralUse/Blog/BlogComponent'

const BlogRouterComponent = () => {
    const {blogs, loading} = useBlogs()
    const match = useRouteMatch()

    if (!blogs){
        return null;
    }

    return (
        loading?
        <Loading></Loading>
        :
        <>
        {blogs.map(element=>
            <Route path={match.url + "/"+element.id}>
                <BlogComponent 
                    id={element.id} 
                    author={element.author} 
                    date={element.dateTime.toDate().toLocaleString()} 
                    header={element.header} 
                    image={element.imageUrl} 
                    text={element.text}>
                </BlogComponent>
            </Route>)}

            <Route exact path={match.url}>
                <Blogs 
                    blogs={blogs} 
                    loading={loading}></Blogs>
            </Route>
        </>
    )
}

export default BlogRouterComponent
