import firebase from 'firebase/app'
import "firebase/auth"
//import storage
import "firebase/storage"
//import stabase
import "firebase/firestore"
const Timestamp = firebase.firestore.Timestamp;


// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAvAeG2U3eqBl8eAhVkOr-SybS-SsT0sGM",
    authDomain: "nhg-development-a9acc.firebaseapp.com",
    projectId: "nhg-development-a9acc",
    storageBucket: "nhg-development-a9acc.appspot.com",
    messagingSenderId: "573560337062",
    appId: "1:573560337062:web:f21ec145c03406660efa67"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth()

  //initialize storage
  const storage=firebase.storage()
// const ref = (file)=> { return ()=>storage.ref(storage, file)}

  //initialize database
  const database = firebase.firestore()
   
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;
  var provider = new firebase.auth.GoogleAuthProvider();
 
  export {auth, storage, database, timestamp, provider, Timestamp}