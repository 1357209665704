import React from 'react'
import {Route} from "react-router-dom"
import {useAuth} from "../../Contexts/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import CenteredText from '../UniqueComponents/CenteredText'
import Loading from './Loading';




const PrivateRoute = ({component: Component, ...rest}) => {

        let {currentUserInfo, loading} = useAuth()

      

    return (

    loading?
        <Loading></Loading>
    :
        <Route {...rest} render={props=>{
                return currentUserInfo? 
                <Component {...props}></Component>
                :
                <CenteredText><FontAwesomeIcon style={{height: "100px", width: "auto"}} icon={faUser}></FontAwesomeIcon><h2>In order to use this page you have to be authorized</h2></CenteredText>
                
            }}>
        </Route>
    )
}

export default PrivateRoute
