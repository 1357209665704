import React, { useState, useEffect, useRef } from 'react'
import InputMUI from '../../Components/GeneralUse/Input/InputMUI'
import { useAuth } from '../../Contexts/AuthContext'
import DatePicker from "../../Components/GeneralUse/Input/DatePicker"
import FormQuill from "../../Components/GeneralUse/Input/FormQuill"
import FileInput from '../../Components/GeneralUse/Input/FileInput'
import { postDatabase } from '../../firebase/DatabaseFunctions'
import TimePicker from '../../Components/GeneralUse/Input/TimePicker'
import Banner from '../../Components/GeneralUse/Banner'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FullEventComponent from '../../Components/GeneralUse/Event/FullEventComponent'
import { basicValidation } from '../../Assets/UtilityFunctions'
import { useLocation } from 'react-router'
import { database, Timestamp } from '../../firebase/config'
import { BigText, MediumText, PRIMARY } from '../../Assets/Styles'
import translate from '../../i18n/messages/translate'



const EventUpload = () => {
    //using location we can get props from the link that sent us to this page
    const location = useLocation()
    const { editText, editHeader, editImage, editId, editEventLocation, editAuthor, editDate, editStartTime, editEndTime } = location.state || { editText: null, editHeader: null, editAuthor: null, editOrganisation: null, editImage: null, editId: null, editDate: null, editStartTime: null, editEndTime: null }

    const { currentUser, currentUserInfo } = useAuth()

    const [header, setHeader] = useState(editHeader || "")
    const [headerError, setHeaderError] = useState(false)
    const headerRef = useRef()


    const [date, setDate] = useState(editDate || new Date())
    const [startTime, setStartTime] = useState(editStartTime || new Date())
    const [endTime, setEndTime] = useState(editEndTime || new Date())

    const [eventLocation, setEventLocation] = useState(editEventLocation || "")
    const [loactionError, setLocationError] = useState(false)
    const locationRef = useRef()


    const [text, setText] = useState(editText || "")
    const [textError, setTextError] = useState(false)

    const [imageUrl, setImageUrl] = useState(editImage || "")
    const [imageUrlError, setImageUrlError] = useState(false)
    const imageRef = useRef()

    const [author, setAuthor] = useState(editAuthor || "")
    const [authorError, setAuthorError] = useState("")

    const [post, setPost] = useState(false)
    const [succesful, setSuccesful] = useState(false)
    const [postError, setPostError] = useState(false)
    const [postLoading, setPostLoading] = useState(false)
    const [startHandleImages, setStartHandleImages] = useState(false)


    const allStateAndErrors = [
        { value: header, setValue: setHeader, setError: setHeaderError },
        { value: imageUrl, setValue: setImageUrl, setError: setImageUrlError },
        { value: eventLocation, setValue: setEventLocation, setError: setLocationError },
        { value: author, setValue: setAuthor, setError: setAuthorError },
    ]




    const clearAll = () => {
        setHeader("")
        setText("")
        setDate(new Date())
        setEndTime(new Date())
        setStartTime(new Date())
        setEventLocation("")
        setImageUrl("")
        setHeaderError(false)
        setTextError(false)
        setLocationError(false)
        setImageUrlError(false)
    }
    const updateArticle = () => {
        setPostLoading(true)
        database.collection("events").doc(editId).update({
            image: imageUrl,
            header: header,
            date: date,
            startTime: startTime,
            endTime: endTime,
            location: eventLocation,
            text: text,
            uid: currentUser.uid,
            cantonId: currentUserInfo.cantonId,
            author: author
        })
            .then(() => {
                setPostLoading(false)
                setSuccesful(true)
                clearAll()
            })
            .catch((error) => {
                setPostLoading(false)
                setPostError(true)
                clearAll()

                console.error("Error updating document: ", error);
            });
    }
    const submitArticle = () => {
        const postObject = {
            image: imageUrl,
            header: header,
            date: date,
            startTime: startTime,
            endTime: endTime,
            location: eventLocation,
            text: text,
            uid: currentUser.uid,
            cantonId: currentUserInfo.cantonId,
            visibility: "public",
            author: author,
            createdAt: new Date()
        }
        setPostLoading(true)
        postDatabase("events", postObject)
            .then(() => {
                setPostLoading(false)
                setSuccesful(true)
                clearAll()
            })
            .catch((error) => {
                setPostLoading(false)
                setPostError(true)
                clearAll()
                console.error("Error updating document: ", error);
            });

    }


    //we post the news to the database only after receving confirmation that the images were processes to data:url (if post  is true)
    useEffect(() => {

        if (post && !basicValidation(allStateAndErrors)) {
            setPostLoading(true)
            //if there is no location state, meaning the article is new, we post it to the database

            if (!location.state) {
                submitArticle()
            }
            //if there is a location state, meaning the article is not new, we update the existing document in the database
            else {
                updateArticle()
            }
        } else {
            setPost(false)
            setStartHandleImages(false)
        }
    }, [post])

    console.log(date)

    return (

        <>

            <Banner loading={postLoading} trigger={succesful} setTrigger={setSuccesful} url={location.state ? "upload/myUploads" : null}><FontAwesomeIcon color="#0587FF" icon={faCheck}></FontAwesomeIcon></Banner>
            <Banner trigger={postError} setTrigger={setPostError}><MediumText>Try Again, an error occured</MediumText><FontAwesomeIcon color={PRIMARY} icon={faTimes}></FontAwesomeIcon></Banner>
            <FormQuill startHandleImages={startHandleImages} setStartHandleImages={setStartHandleImages} textError={textError} setTextError={setTextError} loading={postLoading} text={text} setPost={setPost} setText={setText} name={translate("upload event")}>
                <FileInput error={imageUrlError} setError={setImageUrlError} imageUrl={imageUrl} setImageUrl={setImageUrl}></FileInput>
                <InputMUI ref={headerRef} error={headerError} setError={setHeaderError} value={header} setValue={setHeader} label={translate("header")}></InputMUI>
                <InputMUI error={authorError} setError={setAuthorError} value={author} setValue={setAuthor} label={translate("author's Name")}></InputMUI>
                <DatePicker date={date} setDate={setDate} label={translate("choose Date")}></DatePicker>
                <TimePicker time={startTime} setTime={setStartTime} label={translate("start Time")}></TimePicker>
                <TimePicker time={endTime} setTime={setEndTime} label={translate("end Time")}></TimePicker>
                <InputMUI ref={locationRef} error={loactionError} setError={setLocationError} value={eventLocation} setValue={setEventLocation} label={translate("location")}></InputMUI>
            </FormQuill>
            <BigText>{translate("preview")}</BigText>
            {header || eventLocation || author || text || imageUrl ? <FullEventComponent text={text} startTime={startTime} endTime={endTime} date={date} image={imageUrl} header={header} location={eventLocation} author={author}></FullEventComponent> : <BigText>...</BigText>}

        </>



    )
}

export default EventUpload
