import React from 'react'
import styled from "styled-components"
import {faMapMarkerAlt, faGlobeEurope, faSearch, faHandHoldingUsd, faQuoteLeft, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { MediumText, PRIMARY } from '../../Assets/Styles';
import { Link } from 'react-router-dom';
import translate from '../../i18n/messages/translate';


const BigContainer= styled.div`
background-color:${PRIMARY};
width: 100%;
`
const Container = styled.div`
height: fit-content;
width: 100%;
display: grid;
margin: auto;
grid-template-columns: 100%;
grid-template-rows: 0.143fr 0.143fr 0.143fr 0.143fr 0.143fr 0.143fr 0.143fr ;
padding: 1%;
min-height: fit-content;
>#logo-container-text{
    >h3{
        font-size: 2rem;
        color: white;
    }
    >p{
        margin-top: 12px;
        font-size: 1.1rem;
    }
}
>div{
    color: white;
    padding: 10%;
    
    a>h4{
        color: white;
        font-size: 1.6rem;
        margin-bottom: 20px;
    }
    >p{
        color: white;
    }
    >a{
        color: white;
    }
    >a>svg{
        height: 50px;
        width: 50px !important;
        margin-bottom: 20px;
    }
}

@media (orientation: landscape) and (min-width: 768px){
grid-template-columns: 33% 33% 33%;
margin: auto;
width: 95%;
margin: auto;
margin-bottom: 72px !important;
grid-template-rows: 50% 50%;
grid-template-columns: 0.33fr 0.33fr 0.33fr;
height: fit-content;
position: relative;
padding: 4%;
min-height: fit-content;
>div{
    
    >a{

        transition: 0.3s;
        :hover{
            transform: scale(1.2);
        }
    }
}

>#logo-container-text{
    padding: 0;
    width: 100%;
    height: 22vh;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >*{
        color: white;
    }
    >p{
        font-size: 1.3rem;
        margin-top: 0.6em;
    }
}
}

@media (orientation: landscape) and (max-height: 500px){
height: fit-content !important;
padding: 0;

>#logo-container-text{
    display: none;
}
>div{
    >h4{
        font-size: 1.2rem;
}
}
}

@media (orientation: portrait) and (min-width: 768px){
grid-template-columns: 33% 33% 33%;
margin: auto;
width: 95%;
margin: auto;
margin-bottom: 72px !important;
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
grid-template-rows: 0.33fr 0.33fr 0.33fr;
grid-template-columns: 0.5fr 0.5fr;
height: 80vh;
position: relative;
height: fit-content;

>#logo-container-text{
    padding:  0 10%;
    width: 100%;
    height: 22vh;
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >*{
        color: white;
    }
    >p{
        font-size: 1.3rem;
        margin-top: 0.6em;
    }
}
}
`


const LogoContainer = () => {
    return (
        <BigContainer >
            <div  id="logo-container-text">
            <MediumText style={{color:"white", paddingTop:"5vh"}}>{translate("logo container header")}</MediumText>
            <p style={{color:"white"}}>{translate("logo container subHeader")}</p>
        </div>
        <Container>
        

            <div>
                <Link to="/nhgPodcasts">
                    <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>
                    <h4>{translate("democracy")}<br></br>Podcast</h4>
                </Link>
                
                <p>{translate("democracy podcast subheading")}</p>
            </div>

            <div>
                <Link to="/demokratieWiki">
                    <FontAwesomeIcon icon={faGlobeEurope}></FontAwesomeIcon>
                    <h4>{translate("democracy")}<br></br>Wiki</h4>
                </Link>
                
                <p>{translate("democracy wiki subheading")}</p>
            </div>

            <div>
                <Link to="/archiv">
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    <h4>{translate("our")}<br></br>{translate("archive")}</h4>
                </Link>
                
                <p>{translate("our archive subheading")}</p>
            </div>

            <div>
                <a href="https://demokratiepreis.ch/">
                    <FontAwesomeIcon icon={faHandHoldingUsd}></FontAwesomeIcon>
                
                <h4>Demokratie<br></br>Preis</h4>
                </a>
                <p>{translate("democracy price subheading")}</p>
            </div>

            <div>
                <Link to="/blogs">
                    <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                    <h4>Demokratie<br></br>Blog</h4>
                </Link>
                <p>{translate("democracy blog subheading")}</p>
            </div>
            <div>
                <a href="https://campusdemokratie.ch/">
                    <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                
                <h4>Campus<br></br>Demokratie</h4>
                </a>
                <p>{translate("campus democracy")}</p>
            </div>
        </Container>
        </BigContainer>
    )
}

export default LogoContainer
