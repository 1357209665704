import React, {useContext, useState, useEffect} from 'react'
import { getAllDocuments } from '../firebase/DatabaseFunctions'



const EventsContext = React.createContext()
export const useEvents=()=>{
        return useContext(EventsContext)
    }



export const EventsProvider = ({children}) => {
    const [loading, setLoading] = useState(true)
    const [events, setEvents]  = useState([])


    useEffect(()=>{
            getAllDocuments("events", setEvents, setLoading)
    }, [])

    useEffect(()=>{
    },[events])
    
    let value={
      loading, events
    }

    
    
    return (
        <EventsContext.Provider value={value}>
        {children}
        </EventsContext.Provider>
    )
}